<template>
  <div class="customer-rules-wrapper p-3">
    <rule
      v-for="rule in rules"
      :rule="rule"
      :key="rule.id"
      isFuture
    />
    <div v-if="!rules.length && !loading">
      Não há regras futuras.
    </div>
    <!-- <fields-checklist v-if="!rules.length" :form=customer /> -->
  </div>
</template>

<script>
import TextInfo from '@/components/TextInfo.vue';
import Rule from '@/components/Rule.vue';
import { getNotApplicableRules, getFutureRules } from '@/services/customers';
import FieldsChecklist from './FieldsChecklist.vue';
import moment from 'moment';

export default {
  name: 'FutureRules',
  components: { TextInfo, Rule, FieldsChecklist },


  mounted() {
    this.getFutureRules();
  },
  data() {
    return {
      rules: [],
      customer: null,
      calculation: null,
      loading: false,
    }
  },
  methods: {
    async getFutureRules() {
      this.loading = true;

      const { rules, customer, calculation } = await getFutureRules(this.$route.params.calculationId);
      this.rules = rules;
      this.customer = customer;
      this.calculation = calculation;

      this.loading = false;

      // const { rules, calculation, customer } = await getNotApplicableRules(this.$route.params.calculationId);
      // const publicServiceIngressDate = moment(calculation.publicServiceIngressDate);
      // const notApplicableRules = rules.filter((rule) => {
      //   return publicServiceIngressDate.year() <= rule.ruleYear;
      // });
      // this.rules = notApplicableRules.filter((rule) => {
      //   if (rule.ruleYear === 2019) {
      //     const necessaryAge = rule.decreasedNecessaryAge || rule.necessaryAge || 0;
      //     if (this.currentAge(rule) < necessaryAge) {
      //       return moment(rule?.customer?.birthDate).add(necessaryAge, 'years').format('DD/MM/YYYY');
      //     }
      //   }
      //   return false
      // });
      // this.customer = customer;
      // this.calculation = calculation;
    },
    currentAge(rule) {
      return this.lawDate(rule).diff(moment(rule.customer?.birthDate), 'years');
    },
    lawDate(rule) {
      return rule.ruleYear === 2019 ? moment() : moment(rule.ruleLimitDate);
    },
  }
}
</script>

<style scoped>
</style>
