
<template>
  <div class="bg-primary text-center">
    <form class="form-signin" @submit.prevent="resetPassword">
      <img class="mb-5" src="../assets/logo.webp" alt="">
      <h4 class="mb-3 text-white" v-if="!passwordReset">Atualize a sua senha!</h4>

      <h3 class="mb-3 text-white" v-if="passwordReset">
        A sua senha foi alterada com sucesso
      </h3>

      <label for="email" class="sr-only" v-if="!passwordReset">Email (login)</label>
      <label for="inputPassword" class="sr-only" v-if="!passwordReset">Senha</label>
      <input type="password" id="inputPassword" v-model="password" class="form-control" placeholder="Sua nova senha" required="" v-if="!passwordReset">
      <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" placeholder="Confirme sua senha" required="" v-if="!passwordReset">
      <button class="btn btn-lg btn-secondary btn-block mt-3" type="submit" v-if="!passwordReset">Enviar</button>


      <div class="mt-3 mb-3">
        <p>
          <router-link to="/login" class="text-white"> Voltar</router-link>
        </p>
      </div>

      <p class="mt-5 mb-3">
        <Copyright class="text-center" />
      </p>
    </form>
  </div>
</template>

<script>
import Copyright from '@/components/Copyright.vue';
import { resetPassword } from '@/services/auth';

export default {
  name: 'ResetPassword',
  data() {
    return {
      password: "",
      confirmPassword: "",
      token: "",
      passwordReset: false
    };
  },
  created() {
    this.token = this.$route.params.token;
  },
  components: { Copyright },
  methods: {
    resetPassword: function () {

      if (this.password !== this.confirmPassword) {
        this.$toast.open({
          message: 'As senhas não conferem',
          type: 'error',
          position: 'top-right',
        });
        return;
      }

      if (this.password.length < 6) {
        this.$toast.open({
          message: 'A senha deve ter no mínimo 6 caracteres',
          type: 'error',
          position: 'top-right',
        });
        return;
      }

      resetPassword({
        password: this.password,
        confirmPassword: this.confirmPassword,
        token: this.token
      }).then(() => {
        this.passwordReset = true;
      }).catch((error) => {
        this.$toast.open({
          message: error?.response?.data?.errorMessage,
          type: 'error',
          position: 'top-right',
        });
      });
    }
  }
}
</script>

<style>
.form-signin {
  width: 100%;
  max-width: 390px;
  padding: 15px;
  margin: auto;
}
</style>
