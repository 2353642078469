<template>
  <div class="list-container">
    <div v-if="unfilledData">
      <p class="text-primary text-big">Complete os dados abaixo para calcular as regras elegíveis:</p>
      <ul class="list">
        <template v-for="field in fields">
          <li
            class="list-item"
            :class="{ 'is-checked': isSet(field.name) }"
            :key="field.name"
          >
            <div class="list-item-check">
              <i class="icon ion-checkmark-round"></i>
            </div>
            <div class="list-item-title">
              <span class="list-item-title-strikethrough"></span>
              {{field.description}}
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div v-else class="p-3 text-center text-gray-600">
      Não há regras aplicáveis para a data de hoje
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldsChecklist',
  props: {
    form: {
      type: Object,
    }
  },

  data() {
    return {
      fields: [{
        name: 'gender',
        description: 'Gênero',
      },{
        name: 'publicServiceTime',
        description: 'Tempo de serviço público',
      }, {
        name: 'teachingTime',
        description: 'Tempo de magistério',
      }, {
        name: 'carreerTime',
        description: 'Tempo de Carreira',
      }, {
        name: 'positionTime',
        description: 'Tempo na posição',
      }, {
        name: 'contributionTime',
        description: 'Tempo de contribuição',
      }, {
        name: 'publicServiceIngressDate',
        description: 'Data de ingresso no serviço Público',
      }],
    }
  },

  computed: {
    unfilledData() {
      return this.fields.find((field) => !this.isSet(field.name));
    },
  },

  methods: {
    isSet(fieldName) {
      return (
        this.form
        && (
          this.isSimpleSet(fieldName)
          || this.isComplexFieldSet(fieldName)
        )
      );
    },

    isSimpleSet(fieldName) {
      const { form } = this;
      const value = form[fieldName];
      return value !== null && value !== undefined;
    },

    isComplexFieldSet(fieldName) {
      if (fieldName === 'teachingTime') {
        return this.isSet('teachingTimeDays') &&
               this.isSet('teachingTimeMonths') &&
               this.isSet('teachingTimeYears');
      }

      if (fieldName === 'contributionTime') {
        return this.isSet('contributionTimeDays') &&
          this.isSet('contributionTimeMonths') &&
          this.isSet('contributionTimeYears');
      }
      return false;
    }
  }
}
</script>

<style scoped>
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  i.ion-navicon {
    position: absolute;
    top: -2px;
    right: 10px;
    font-size: 37px;
    color: #444;
  }

  .list-container {
    margin: auto;
  }

  .list-item {
    padding: 16px 0px 18px 25px;
    border-bottom: 1px dotted #efefef;
    cursor: pointer;
  }

  .list-item.is-last {
    border: none;
  }

  .list-item.is-checked .list-item-title {
    /* color: #b5bcc3; */
    color: #369AE2;
  }

  .list-item.is-checked .ion-checkmark-round {
    color: #369AE2;
  }

  .list-item-title {
    display: inline-block;
    position: relative;
    color: #b5bcc3;
  }

  .list-item-title-strikethrough {
    display: none;
    position: absolute;
    top: 10px;
    width: 100%;
    height: 2px;
    background-color: #444;
  }

  .list-item-check {
    float: left;
    margin-right: 10px;
  }

  i.ion-checkmark-round {
    -webkit-transition: color 250ms ease;
    position: relative;
    top: -2px;
    left: -7px;
    font-size: 20px;
    color: #e9e9e9;
  }
</style>
