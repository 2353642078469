import axios from 'axios';

const state = {
  workRecords: [],
};

const getters = {
  workRecords: state => state.workRecords,
};

const actions = {
  loadWorkRecords: async ({ commit }, calculationId) => {
    const response = await axios.get(`/calculations/${calculationId}/work-records`);
    commit('SET_WORK_RECORDS', response?.data?.workRecords || []);
    return response.data;
  },
};

const mutations = {
  SET_WORK_RECORDS: (state, data) => {
    state.workRecords = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
