<template>
  <div
    class="user-initials"
    v-bind:class="size ? `user-initials--${size} ${backgroundColor}` : backgroundColor"
    v-bind:title="userName"
  >
    <span v-if="userInitials">{{userInitials}}</span>
    <i v-else class="icon-user-line"></i>
  </div>
</template>

<script>
export default {
  name: 'UserInitials',
  props: {
    userName: String,
    size: String,
    backgroundColor: {
      type: String,
      default: 'bg-user-initials',
    },
  },
  computed: {
    userInitials() {
      if (this.userName) {
        const names = this.userName
          .trim()
          .replace(/[^a-zA-Z ]/g, '')
          .split(' ');

        const initials = [
          names.shift(),
          names.pop(),
        ].map((name = '') => name.toString().toUpperCase().charAt(0)).join('');

        return initials;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.user-initials {
  cursor: default;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &--sm {
    width: 25px;
    height: 25px;
    font-size: 0.8rem;
  }

  &--lg {
    width: 40px;
    height: 40px;
  }
}

.bg-user-initials {
  background: #369AE2 ;
}
</style>
