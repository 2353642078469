<template>
  <div class="help-wrapper">
    <div class="bg-fun-blue">
      <b-container class="position-relative text-center py-5 px-3">
        <div style="0 0 0 0;position:relative;">
          <iframe
          src="https://player.vimeo.com/video/707364472?h=85bf98246a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
          class="help__video"
          style="width:560px;height:315px;" title="Boas vindas mapas mentais"></iframe>
        </div>
        <img class="help__header-img" src="@/assets/images/help.png" alt="Help cover">
      </b-container>
    </div>

    <b-container class="py-5" v-if="!isOnFirstSevenDays">
      <div class="row mt-4">
        <div class="col-md-9 mb-3 m-auto">
          <div
            class="item bg-white border shadow-sm"
            v-for="(item, index) in mindMaps"
            :key="`item-${index}`"
          >
            <h4 class="font-weight-bold text-center mb-0 text-gray-600 p-3">{{item.title}}</h4>
            <h6 class="px-3 pb-3">{{item.description}}</h6>
            <div class="position-relative m-auto text-center">
              <!-- <img class="w-100" v-bind:src="item.imgCover" alt="cover"> -->
              <!-- <i class="fa fa-play-circle-o video-item__play" aria-hidden="true" v-on:click="$emit('play', item)"></i> -->
              <a :href="item.url" target="blank">Acessar Mapa Mental</a>
            </div>
          </div>
        </div>
      </div>
    </b-container>

    <b-container class="py-5" v-else>
      <div class="row mt-4">
        <div class="col-md-9 mb-3 m-auto">
          <div class="item bg-white border shadow-sm">
            <h4 class="font-weight-bold text-center mb-0 text-gray-600 p-3">
              <span v-if="remainingDays > 0">
                O seu acesso a esta área estará disponível em {{ remainingDays }} dia(s).
              </span>
              <span v-else>
                O seu acesso a esta área estará disponível amanhã.
              </span>
            </h4>
          </div>
        </div>
      </div>
    </b-container>

    <div class="bg-fun-blue">
      <b-container class="text-center py-5 px-3">
        <h2 class="text-gray-200">Contato</h2>
        <h5 class="text-gray-200">Ainda tem alguma dúvida? Entre em conosco</h5>

        <div class="d-flex justify-content-center mt-5">
          <div class="text-white border border-white d-flex align-items-center p-3">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            <h6 class="mb-0 ml-2 border-left pl-2">contato@previdenciagenial.com.br</h6>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Help',
  components: {},
  computed: {
    ...mapGetters(['sessionUser', 'isOnFirstSevenDays', 'remainingDays'])
  },
  data: () => ({
    mindMapselected: null,
    mindMaps: [
      {
        title: 'CUSTEIO',
        url: 'https://mm.tt/map/2169448520?t=MfmVJsPydW',
        description: 'Contribuição previdenciária é tributo! Por isso, você deve ter alguma noções basicas sobre custeio e, para entender o que compõe o cálculo do benefício previdenciário, você deve saber o que é base contributiva e do que ela é composta.',
      },
      {
        title: 'TEMPO DE CONTRIBUIÇÃO NO RPPS',
        url: 'https://mm.tt/map/2185567994?t=ZxIRU3GAWD',
        description: 'A contagem do tempo de contribuição no RPPS tem várias nuances e diferenças com o RPPS, inclusive com relação à conversão de tempo especial em comum.',
      },
      {
        title: 'FORMAS DE CÁLCULO NO RPPS',
        url: 'https://mm.tt/map/2173309885?t=OaHmWIGFro',
        description: 'Quem trabalha com RGPS já está acostumado a ouvir falar em média para cálculo do benefício. No RPPS, também é necessário saber os conceitos de integralidade, proventos integrais e paridade.',
      },
      {
        title: 'PENSÃO POR MORTE',
        url: 'https://mm.tt/map/2215371822?t=RLDPUV6yoz',
        description: 'Tivemos mudanças drásticas na forma de cálculo da pensão por morte e também na sua aplicabilidade para os RPPS estaduais, municipais e distrital. Neste mapa mental, também trazemos quem são os dependentes e a duração do benefício.',
      },
    ]
  }),
}
</script>

<style lang="scss" scoped>
.help {
  &__video {
    position: relative;
    z-index: 2;
    max-width: 100%;
  }

  &__header-img {
    width: 100%;
    position: absolute;
    height: auto;
    width: 100%;
    position: absolute;
    height: auto;
    left: 0;
    bottom: 30px;
    opacity: 0.5;
    z-index: 1;
  }
}

.item {
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4rem;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      color: #00bcd4;
    }
  }
}
</style>
