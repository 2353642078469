import axios from 'axios';

export async function getSubscription() {
  const response = await axios.get('/subscription/');
  return response.data;
}

export async function getSubscriptionADM(userId) {
  const response = await axios.get(`/subscription/${userId}`);
  return response.data;
}

export async function cancelSubscription(query = {}) {
  const response = await axios.delete(`/subscription/`, {
    params: query
  });
  return response.data;
}

export async function cancelSubscriptionADM(query = {}) {
  const response = await axios.delete(`/admin/subscription/`, {
    params: query
  });
  return response.data;
}

export async function reverseCharge() {
  const response = await axios.post('/subscription/reverse-charge');
  return response.data;
}
