<template>
  <div class="customer-rules-wrapper p-3">
    <rule v-for="rule in rules" :rule="rule" :key="rule.id" />
    <div v-if="!rules.length && !loading">
      Não há regras aplicáveis.
    </div>
    <!-- <fields-checklist v-if="!rules.length" :form="customer" /> -->
  </div>
</template>

<script>
import TextInfo from '@/components/TextInfo.vue';
import Rule from '@/components/Rule.vue';
import { getApplicableRules } from '@/services/customers';
import FieldsChecklist from './FieldsChecklist.vue';

export default {
  name: 'Rules',
  components: { TextInfo, Rule, FieldsChecklist },


  mounted() {
    this.getApplicableRules();
  },
  data() {
    return {
      rules: [],
      customer: null,
      loading: false,
    }
  },
  methods: {
    async getApplicableRules() {
      this.loading = true;

      const { rules, calculation, customer } = await getApplicableRules(this.$route.params.calculationId);
      this.rules = rules;
      this.customer = customer;
      this.calculation = calculation;

      this.loading = false;
    }
  }
}
</script>

<style scoped>
</style>
