<template>
  <b-container class="list-clients-wrapper">
    <!-- User Interface controls -->
    <b-card class="shadow-sm">
      <b-row>
        <b-col lg="6" class="my-1">
          <b-form-group
            label="Ordenar"
            label-for="sort-by-select"
            label-cols-sm="3"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sort-by-select"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- nenhum --</option>
                </template>
              </b-form-select>

              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
                size="sm"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            label="Pesquisar"
            label-for="filter-input"
            label-cols-sm="3"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Digite para pesquisar"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col sm="5" md="6" class="my-1">
          <b-form-group
            label="Registros por página"
            label-for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col lg="6" class="my-1">
          <b-form-group
            v-model="sortDirection"
            label="Filtrar por"
            description="Deixe todos os filtros desativados para exibir todos os registros"
            label-cols-sm="3"
            label-size="sm"
            class="mb-0"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-checkbox-group
              v-model="filterOn"
              :aria-describedby="ariaDescribedby"
              class="mt-1"
            >
              <b-form-checkbox value="name">Nome</b-form-checkbox>
              <b-form-checkbox value="age">Idade</b-form-checkbox>
              <b-form-checkbox value="status">Status</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body class="mt-3 shadow-sm">

      <button
        class="bg-transparent w-100 text-center border border-dashed pb-10 text-gray-600"
        v-on:click="createNewCustomer()">
        <i class="fa fa-plus mr-2" aria-hidden="true"></i> Adicionar novo Cliente
      </button>
    </b-card>
    <b-card no-body class="mt-3 shadow-sm">
      <b-table
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isLoading"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-default my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Carregando clientes...</strong>
          </div>
        </template>

        <template #cell(name)="row">
          {{ row.value }}
        </template>

        <template #cell(birthDate)="row">
          <p class="mb-0" v-if="row.value">
            {{ row.value | moment('DD/MM/YYYY') }} - {{ row.value | moment('from', true) }}
          </p>
        </template>

        <template #cell(status)="row">
          <p class="mb-0" v-if="row.value === 'client'">Cliente</p>
          <p class="mb-0" v-else>Consulta</p>
        </template>

        <template #cell(actions)="row">
          <!-- link to customer -->
          <router-link
            :to="{ name: 'customer', params: { id: row.item.id } }"
            class="ml-2 btn btn-sm btn-outline-primary" title="Abrir" role="button" size="sm"
          >
            <!-- <b-icon icon="eye"></b-icon> -->
            <i class="fa fa-eye"></i>
          </router-link>

          <span class="ml-2 btn btn-sm btn-outline-danger" title="Excluir" role="button" size="sm" @click="deleteCustomer(row.item)">
            <i class="fa fa fa-trash"></i>
          </span>
        </template>

        <template #cell(lastCalcDate)="row">
          <p class="mb-0">
            {{ getLastCalculationDate(row.item) }}
          </p>
        </template>

        <template #cell(calcQtd)="row">
          <p class="mb-0">
            {{ getCalculationQuantity(row.item) }}
          </p>
        </template>
      </b-table>
    </b-card>

    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0"
      ></b-pagination>
    </div>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" size="lg" hide-footer @hide="resetInfoModal">
      <b-container>
        <b-col cols="12" align-self="stretch">
          <h2 class="text-left">Cálculo Simples - Servidores Públicos</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque porro obcaecati unde,
            maiores adipisci labore nulla deleniti corrupti qui
            maiores adipisci labore nulla deleniti corrupti qui.</p>
        </b-col>
        <router-link
          :to="{ path: 'calculations/new', query: { userId: infoModal.content.id }}"
          class="text-right float-right"
        >
          &#x226A; Iniciar Novo Cálculo
        </router-link>
      </b-container>
      <br />
      <br />
      <b-container>
        <b-col cols="12" align-self="stretch">
          <h2 class="text-left">Cálculo Avançado - Servidores Públicos</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque porro obcaecati unde,
            maiores adipisci labore nulla deleniti corrupti qui.</p>
        </b-col>
        <a
          class="text-right float-right"
        >
          &#x226A; Em breve!
        </a>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
  import { getCustomers, deleteCustomer, createCustomer } from '@/services/customers'
  import moment from 'moment';
  export default {
  name: 'ListCustomers',
  data() {
    return {
      isLoading: false,
      items: [
        {
          id: 3,
          status: null,
          age: 9,
          name: 'Mini Navarro',
          _rowVariant: 'success'
        },
        {
          id: 8,
          status: 'client',
          age: 87,
          name: 'Larsen Shaw',
          _cellVariants: { age: 'danger', status: 'warning' }
        }
      ],
      fields: [
        { key: 'name', label: 'Nome', sortable: 'client', sortDirection: 'desc' },
        { key: 'birthDate', label: 'Dt. Nascimento', sortable: 'client' },
        { key: 'state', label: 'UF', sortable: 'state' },
        { key: 'lastCalcDate', label: 'Dt. Último Cálculo', sortable: 'client' },
        { key: 'calcQtd', label: 'Qtd. Cálculos', sortable: 'client' },
        // { key: 'status', label: 'Status', sortable: 'client' },
        // {
        //   key: 'status',
        //   label: 'Is Active',
        //   formatter: (value, key, item) => {
        //     return value ? 'Yes' : 'No'
        //   },
        //   sortable: true,
        //   sortByFormatted: true,
        //   filterByFormatted: true
        // },
        { key: 'actions', label: 'Ações', class: 'text-right' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sessionUser () { return this.$store.getters.sessionUser },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.loadCustomers();
  },
  methods: {
    async createNewCustomer() {
      const { customer } = await createCustomer();
      this.$router.push({ name: 'customer', params: { id: customer.id }, query: { mode: 'edit' } });
    },
    async loadCustomers() {
      this.isLoading = true;
      const data = await getCustomers()
      this.items = data.customers
      this.totalRows = this.items.length
      this.isLoading = false;
    },
    async deleteCustomer(item) {
      const result = await this.$swal.fire({
        title: `Remover ${item.name || 'este usuário'}?`,
        text: "Esta ação não poderá ser desfeita!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode remover!'
      });

      if (result.isConfirmed) {
        await deleteCustomer(item.id);
        this.$swal.fire(
          'Pronto!',
          `${item.name || 'O usuário'} foi removido com sucesso!`,
          'success'
        )
        await this.loadCustomers();
      }
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    generateRandomDate() {
      const max = new Date();
      const min = new Date(2023, 0, 1);

      const date = new Date(min.getTime() + Math.random() * (max.getTime() - min.getTime()));

      return moment(date).format("DD/MM/YYYY");
    },
    getLastCalculationDate(customer) {
      const calculations = customer.calculation;

      if (!calculations) return '';

      let earliearDate = '';

      for (const calc of calculations) {
        const date = calc.updatedAt;
        if (!earliearDate) earliearDate = date;

        if (moment(date).isAfter(earliearDate)) break;

        earliearDate = date;
      }

      return earliearDate ? moment(earliearDate).format("DD/MM/YYYY") : earliearDate
    },
    getCalculationQuantity(customer) {
      const calculation = customer.calculation;

      return calculation?.length || 0;
    },
  }
}
</script>

<style lang="scss">
.list-clients-wrapper {
  .table.b-table {
    margin-bottom: 0;

    & > thead > tr th {
      border: none;
    }

    td, th {
      vertical-align: middle;
      display: table-cell;
      padding: 1rem 1.5rem;
    }
  }
}
</style>
