import store from "store";

const isManagement = (to, from, next) => {
  const { isAuthenticated, sessionUser } = store.getters;
  if (isAuthenticated && sessionUser.email === 'admin@admin.com') {
    next();
    return;
  }
  next('/login');
};

export default isManagement;
