<template>
  <div class="text-info align-items-center">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-skeleton width="100%"></b-skeleton>
      </template>

      <div class="d-flex align-items-center">
        <div>
          <h6 v-if="isSet(text)" class="mb-0 font-weight-bold text-info">{{text}}</h6>
          <h6 v-else class="mb-0 text-gray-500 font-italic">{{emptyText || 'Não informado'}}</h6>
          <!-- <button class="text-info__edit btn btn-link p-0" @click="isEditing = true">
            <i class="fa fa-pencil-square-o ml-1 fs-sm" aria-hidden="true"></i>
          </button> -->
        </div>

        <span
          v-if="tooltipInfo"
          v-b-tooltip.hover
          v-bind:title="tooltipInfo"
          class="ml-1"
        >
          <BIcon icon="info-circle" />
        </span>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>

export default {
  name: 'TextInfo',
  props: {
    text: String | Number,
    emptyText: String,
    tooltipInfo: String,
    isLoading: Boolean,
  },

  methods: {
    isSet(value) {
      return value !== null && value !== undefined && value !== "";
    },
    save() {
      this.$emit('save');
      this.isEditing = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .text-info {
    &:hover &__edit {
      visibility: visible;
    }

    &__edit {
      visibility: hidden;
    }
  }
</style>
