<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0" v-if="record">Deduções - Registro #{{record.id}}</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row" v-if="maxDeduction1 > 0">
        <div class="mt-3 col-md-5">
          <b-form-group
            label="Dias a serem deduzidos até 16/12/1998:"
            label-for="days-1"
          />
        </div>
        <div class="mt-2 d-flex col-md-5">
          <b-form-input
            placeholder="Dias"
            id="days-1"
            type="number"
            min="0"
            :max="maxDeduction1"
            v-model="form.deduction1"
            required
          />
          <div>
            <div class="input-group-text">dias</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="maxDeduction2 > 0">
        <div class="mt-3 col-md-5">
          <b-form-group
            label="Dias a serem deduzidos até 13/11/2019:"
            label-for="days-2"
          />
        </div>
        <div class="mt-2 d-flex col-md-5">
          <b-form-input
            placeholder="Dias"
            id="days-2"
            type="number"
            min="0"
            :max="maxDeduction2"
            v-model="form.deduction2"
            required
          />
          <div>
            <div class="input-group-text">dias</div>
          </div>
        </div>
      </div>
      <div class="row" v-if="maxDeduction3 > 0">
        <div class="mt-3 col-md-5">
          <b-form-group
            label="Dias a partir de 14/11/2019 até hoje:"
            label-for="days-3"
          />
        </div>
        <div class="mt-2 d-flex col-md-5">
          <b-form-input
            placeholder="Dias"
            id="days-3"
            type="number"
            min="0"
            :max="maxDeduction3"
            v-model="form.deduction3"
            required
          />
          <div>
            <div class="input-group-text">dias</div>
          </div>
        </div>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="save"
      >
        Aplicar
      </b-button>

      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
import InputDatePicker from '@/components/InputDatePicker.vue';
import moment from 'moment';

export default {
  name: 'ModalDeductionForm',

  components: {
    InputDatePicker,
  },

  props: {
    record: {
      type: Object,
    },
    parentId: {
      type: String,
    },
  },

  data: () => ({
    isLoading: false,
    isSaving: false,
    modalShow: true,
    form: {
      deduction1: 0,
      deduction2: 0,
      deduction3: 0,
    },
  }),

  created() {
    if (this.record) {
      this.form = { ...this.record };
    }
  },

  computed: {
    maxDeduction1() {
      if (moment(this.form.ingressDate).isAfter(moment("1998-12-16"))) return -1;

      let days = moment(this.form.egressDate).diff(moment(this.form.ingressDate), "days");

      if (moment(this.form.egressDate).isAfter(moment("1998-12-16"))) {
        const extraDays = moment(this.form.egressDate).diff(moment("1998-12-16"), "days");

        days = days - extraDays
      }

      return days;
    },

    maxDeduction2() {
      if (moment(this.form.ingressDate).isAfter(moment("2019-11-13"))) return -1;

      let days = moment(this.form.egressDate).diff(moment(this.form.ingressDate), "days");

      if (moment(this.form.egressDate).isAfter(moment("2019-11-13"))) {
        const extraDays = moment(this.form.egressDate).diff(moment("2019-11-13"), "days");

        days = days - extraDays;
      }

      return days;
    },

    maxDeduction3() {
      if (moment(this.form.egressDate).isBefore(moment("2019-11-14"))) return -1;

      let days = moment(this.form.egressDate).diff(moment(this.form.ingressDate), "days");

      if (moment(this.form.ingressDate).isBefore(moment("2019-11-14"))) {
        const extraDays = moment("2019-11-14").diff(moment(this.form.ingressDate), "days");

        days = days - extraDays;
      }

      return days;
    },
  },

  methods: {
    async save() {
      if (this.maxDeduction1 > 0 && this.form.deduction1 > this.maxDeduction1) {
        this.form.deduction1 = this.maxDeduction1;
        return;
      }

      if (this.maxDeduction2 > 0 && this.form.deduction2 > this.maxDeduction2) {
        this.form.deduction2 = this.maxDeduction2;
        return;
      }

      if (this.maxDeduction3 > 0 && this.form.deduction3 > this.maxDeduction3) {
        this.form.deduction3 = this.maxDeduction3;
        return;
      }

      this.isLoading = true;
      this.isSaving = true;

      try {
        await this.saveOrUpdate();

        this.$toast.open({
          message: 'Registro salvo com sucesso',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('save');
        this.$emit('hidden');
      } catch (error) {
        this.$toast.open({
          message: 'Erro ao salvar',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.isSaving = false;
        this.isLoading = false;
      }
    },

    saveOrUpdate() {
      const body = { workRecord: this.form };

      if (this.form.id) {
        return axios.put(`/work-records/${this.form.id}`, body);
      } else {
        return axios.post('/work-records', body);
      }
    },
  }
}
</script>

<style scoped>
</style>
