<template>
  <div class="help-wrapper">
    <div class="bg-fun-blue">
      <b-container class="position-relative text-center py-5 px-3">
        <h1 class="text-white"><span class="font-weight-bold">Cálculo<span class="text-secondary"> Previdenciário</span></span></h1>
        <div style="0 0 0 0;position:relative;">
          <iframe
            src="https://player.vimeo.com/video/707383952?h=85bf98246a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="help__video"
            style="width:560px;height:315px;" title="Boas vindas mapas mentais"></iframe>
        </div>
        <img class="help__header-img" src="@/assets/images/help.png" alt="Help cover">
      </b-container>
    </div>

    <b-container class="py-5" v-if="!isOnFirstSevenDays">
      <!-- <h2 class="text-gray-600">Tutoriais</h2> -->
      <!-- <h5 class="text-gray-600">Está com dúvida sobre a plataforma? assista nossos tutoriais para saber mais</h5> -->

      <div class="row mt-4">
        <VideoItem
          v-for="(video, index) in videos"
          v-bind:key="index"
          v-bind:item="video"
          v-on:play="handlerPlayVideo"
          class="col-md-6 mb-3"
        />
      </div>
    </b-container>

    <b-container class="py-5" v-else>
      <div class="row mt-4">
        <div class="col-md-9 mb-3 m-auto">
          <div class="item bg-white border shadow-sm">
            <h4 class="font-weight-bold text-center mb-0 text-gray-600 p-3">
              <span v-if="remainingDays > 0">
                O seu acesso a esta área estará disponível em {{ remainingDays }} dia(s).
              </span>
              <span v-else>
                O seu acesso a esta área estará disponível amanhã.
              </span>
            </h4>
          </div>
        </div>
      </div>
    </b-container>

    <div class="bg-fun-blue">
      <b-container class="text-center py-5 px-3">
        <h2 class="text-gray-200">Contato</h2>
        <h5 class="text-gray-200">Ainda tem alguma dúvida? Entre em conosco</h5>

        <div class="d-flex justify-content-center mt-5">
          <div class="text-white border border-white d-flex align-items-center p-3">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            <h6 class="mb-0 ml-2 border-left pl-2">contato@previdenciagenial.com.br</h6>
          </div>
        </div>
      </b-container>
    </div>

    <ModalVideo
      v-bind:video="videoSelected"
      v-if="showVideoModal"
      v-on:hidden="showVideoModal = false"
    />
  </div>
</template>

<script>

import VideoItem from '@/components/VideoItem';
import { mapGetters, mapActions } from 'vuex';
import ModalVideo from '@/components/ModalVideo';

export default {
  name: 'Help',
  components: {
    VideoItem,
    ModalVideo,
  },
  computed: {
    ...mapGetters(['sessionUser', 'isOnFirstSevenDays', 'remainingDays'])
  },
  data: () => ({
    videoSelected: null,
    showVideoModal: false,
    videos: [
      {
        title: 'BOAS-VINDAS ÀS VIDEOAULAS',
        videoUrl: '707385160',
        imgCover: require('@/assets/images/video-cover.png'),
        description: 'Video de boas-vindas',
      },
      {
        title: 'VIDEO-AULA 1',
        videoUrl: '734812757',
        imgCover: require('@/assets/images/video-cover.png'),
        description: 'Servidor com ingresso no serviço público até 31/12/2003 sempre aposentará com a última remuneração?',
      },
    ]
  }),

  methods: {
    handlerPlayVideo(video) {
      this.showVideoModal = true;
      this.videoSelected = video;
    },
  },
}
</script>

<style lang="scss" scoped>
.help {
  &__video {
    position: relative;
    z-index: 1;
    max-width: 100%;
  }

  &__header-img {
    width: 100%;
    position: absolute;
    height: auto;
    width: 100%;
    position: absolute;
    height: auto;
    left: 0;
    bottom: 30px;
    opacity: 0.5;
  }
}
</style>
