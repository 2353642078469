import axios from 'axios';

const state = {
  calculations: [],
};

const getters = {
  calculations: state => state.calculations,
};

const actions = {
  loadCalculations: async ({ commit }, customerId) => {
    const response = await axios.get('/calculations', { params: { customerId } });
    commit('SET_CALCULATIONS', response?.data?.calculations || []);
  },
};

const mutations = {
  SET_CALCULATIONS: (state, data) => {
    state.calculations = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
