import axios from 'axios';
import Vue from "vue";

const state = {
  status: "",
  profile: {},
  users: []
};

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.name,
  allUsers: state => state.users
};

const actions = {
  saveUser: async ({ commit, dispatch}, user) => {
    if(user.id) {
      dispatch('updateUser', user);
    } else {
      dispatch('createUser', user);
    }
  },
  createUser: async ({ commit, dispatch}, user) => {
    commit('USER_REQUEST');
    try {
      const response = await axios.post(`/users`, {user});
      commit('USER_ADDED', response.data.user);
    } catch (e) {
      commit('USER_ERROR');
    }
  },
  fetchUsers: async ({ commit, dispatch, rootState }) => {
    commit('USER_REQUEST');
    try {
      const response = await axios.get('/users');
      commit('USER_SUCCESS', response.data.users);
    } catch(e) {
      commit('USER_ERROR');
      
      dispatch('AUTH_LOGOUT'); // Deslogar caso erro 401 - Forbidden
    }
  },
  deleteUser:  async ({ commit, dispatch }, id) => {
    commit('USER_REQUEST');
    try {
      await axios.delete(`/users/${id}`);
      commit('USER_REMOVED', id);
    } catch(e) {
      commit('USER_ERROR');      
    }
  },
};

const mutations = {
  USER_REQUEST: state => {
    state.status = "loading";
  },
  USER_SUCCESS: (state, response) => {
    state.status = "success";
    Vue.set(state, 'users', response);
  },
  USER_ERROR: state => {
    state.status = "error";
  },
  AUTH_LOGOUT: state => {
    state.profile = {};
  },
  USER_ADDED: (state, user) =>  state.users.unshift(user),
  USER_REMOVED: (state, id) =>
    (state.users = state.users.filter(user => user.id !== id)),
};

export default {
  state,
  getters,
  actions,
  mutations
};
