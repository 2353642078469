import axios from 'axios';

export async function getUpdateIndex(query = {}) {
  const response = await axios.get('/updateIndex', { params: query });
  return response.data;
}

export async function updateUpdateIndex(body = {}) {
  const response = await axios.post('/updateIndex', body);
  return response.data;
}
