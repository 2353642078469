<template>
  <div
    v-if="showPageHeader"
    class="page-header"
  >
    <b-container class="d-flex align-items-center">
      <i
        v-if="iconClass"
        class="mr-3"
        v-bind:class="iconClass"
      ></i>
      <h5 class="mb-0 font-weight-bold">
        {{title}}
      </h5>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  computed: {
    showPageHeader() {
      return Boolean(this.meta?.title);
    },

    meta() {
      const route = this.$route.matched.find((r) => r.meta?.title);
      return route?.meta;
    },

    iconClass() {
      const { icon } = this.meta || {};
      if (!icon) { return; }
      return `fa ${icon}`;
    },

    title() {
      return this.meta?.title;
    },
  },
};
</script>

<style scoped>
  .page-header {
    font-weight: 600;
    padding: 0 40px;
    height: 55px;
    font-size: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background-color: #0a446d;
  }
</style>
