import moment from "moment";

const calculateDate = (initialDate, finalDate) => {
  let initialYears = initialDate.year();
  let initialMonths = initialDate.months() + 1;
  let initialDays = initialDate.date();

  let finalYears = finalDate.year();
  let finalMonths = finalDate.month() + 1;
  let finalDays = finalDate.date();

  let years = 0;
  let months = 0;
  let days = 0;

  if (finalDays - initialDays < 0) {
    finalMonths -= 1;
    finalDays += 30;
  }

  days = finalDays - initialDays;

  if (finalMonths - initialMonths < 0) {
    finalYears -= 1;
    finalMonths += 12;
  }

  months = finalMonths - initialMonths;

  years = finalYears - initialYears;

  days += 1;

  return { days, months, years };
}

const getTime = (workRecords, limitDate = null, field = null, gender, regimeDate, ignoreSpecial = false) => {
  const dates = [];

  let disablementLevel = null;

  if (field && field.includes("isDisabled")) {
    disablementLevel = field.split("-")[1];
    field = "isDisabled";
  }

  workRecords.forEach((workRecord) => {
    const { ingressDate, egressDate, addition, deduction1, deduction2, deduction3, isSpecialTime, disabledLevel } = workRecord;      

    if (!field || (field && workRecord[field] && (!disablementLevel || disablementLevel === disabledLevel))) {
      if (isSpecialTime && !field && moment(ingressDate).isBefore(moment(regimeDate))) {
        const multiple = (ignoreSpecial) ? 1 : (gender === "Homem") ? 1.4 : 1.2;

        if (limitDate) {
          if (moment(ingressDate).isBefore(limitDate)) {
            let egress = null;

            let isLimitDate = false;

            if (moment(egressDate).isBefore(limitDate)) {
              egress = moment(egressDate);
            } else {
              egress = moment(limitDate);
              isLimitDate = true;
            }

            let isBefore2019 = false;

            if (egress.isSameOrBefore(regimeDate)) {
              egress = moment(isLimitDate ? limitDate : egressDate);
              isBefore2019 = true;
            } else {
              egress = moment(regimeDate);
            }

            let ingress = moment(ingressDate);

            egress.add((addition * 2), 'days');
            egress.subtract(deduction1, 'days');
    
            if (moment(limitDate).isAfter(moment('1998-12-16'))) {
              egress.subtract(deduction2, 'days');
            }
      
            if (moment(limitDate).isAfter(moment(regimeDate))) {
              egress.subtract(deduction3, 'days');
            }

            let result = calculateDate(ingress, egress);

            let years = result.years;
            let months = result.months;
            let days = result.days;

            // let years = egress.diff(ingress, 'years');
            // egress = egress.subtract(years, 'years');
            // let months = egress.diff(ingress, 'months');
            // egress = egress.subtract(months, 'months');
            // let days = egress.diff(ingress, 'days');

            years *= multiple;
            months *= multiple;
            days *= multiple;

            if (!isBefore2019) {
              egress = moment(isLimitDate ? limitDate : egressDate);
              ingress = moment(regimeDate).add(1, 'day');

              // let years2 = egress.diff(ingress, 'years');
              // egress = egress.subtract(years2, 'years');
              // let months2 = egress.diff(ingress, 'months');
              // egress = egress.subtract(months2, 'months');
              // let days2 = egress.diff(ingress, 'days');

              let result = calculateDate(ingress, egress);

              let years2 = result.years;
              let months2 = result.months;
              let days2 = result.days;

              years += years2;
              months += months2;
              days += days2;
            }

            dates.push({ years, months, days });
          }
        } else {
          let egress = null;
          let isBefore2019 = false;

          if (moment(egressDate).isSameOrBefore(regimeDate)) {
            egress = moment(egressDate);
            isBefore2019 = true;
          } else {
            egress = moment(regimeDate);
          }

          let ingress = moment(ingressDate);
  
          egress.add((addition * 2), 'days');
          egress.subtract(deduction1, 'days');
          egress.subtract(deduction2, 'days');
          egress.subtract(deduction3, 'days');

          let result = calculateDate(ingress, egress);

          let years = result.years;
          let months = result.months;
          let days = result.days;

          // let years = egress.diff(ingress, 'years');
          // egress = egress.subtract(years, 'years');
          // let months = egress.diff(ingress, 'months');
          // egress = egress.subtract(months, 'months');
          // let days = egress.diff(ingress, 'days');

          years *= multiple;
          months *= multiple;
          days *= multiple;

          if (!isBefore2019) {
            egress = moment(egressDate);
            ingress = moment(regimeDate).add(1, 'day');

            // let years2 = egress.diff(ingress, 'years');
            // egress = egress.subtract(years2, 'years');
            // let months2 = egress.diff(ingress, 'months');
            // egress = egress.subtract(months2, 'months');
            // let days2 = egress.diff(ingress, 'days');

            let result = calculateDate(ingress, egress);

            let years2 = result.years;
            let months2 = result.months;
            let days2 = result.days;
            
            years += years2;
            months += months2;
            days += days2;
          }
    
          dates.push({ years, months, days });
        }
      } else {
        if (limitDate) {

          if (moment(ingressDate).isBefore(limitDate)) {
            let egress = null;

            if (moment(egressDate).isBefore(limitDate)) {
              egress = moment(egressDate)
            } else {
              egress = moment(limitDate);
            }

            let ingress = moment(ingressDate);

            egress.add((addition * 2), 'days');
            egress.subtract(deduction1, 'days');
    
            if (moment(limitDate).isAfter(moment('1998-12-16'))) {
              egress.subtract(deduction2, 'days');
            }
      
            if (moment(limitDate).isAfter(moment(regimeDate))) {
              egress.subtract(deduction3, 'days');
            }

            let result = calculateDate(ingress, egress);

            let years = result.years;
            let months = result.months;
            let days = result.days;

            // const years = egress.diff(ingress, 'years');
            // egress = egress.subtract(years, 'years');
            // const months = egress.diff(ingress, 'months');
            // egress = egress.subtract(months, 'months');
            // const days = egress.diff(ingress, 'days');

            // if (teste) console.log({ years, months, days });

            dates.push({ years, months, days });
          }
        } else {
          let egress = moment(egressDate);
          let ingress = moment(ingressDate);
  
          egress.add((addition * 2), 'days');
          egress.subtract(deduction1, 'days');
          egress.subtract(deduction2, 'days');
          egress.subtract(deduction3, 'days');

          let result = calculateDate(ingress, egress);

          let years = result.years;
          let months = result.months;
          let days = result.days;

          // const years = egress.diff(ingress, 'years');
          // egress = egress.subtract(years, 'years');
          // const months = egress.diff(ingress, 'months');
          // egress = egress.subtract(months, 'months');
          // const days = egress.diff(ingress, 'days');
    
          dates.push({ years, months, days });
        }
      }
    }
  });

  let years = 0;
  let months = 0;
  let days = 0;

  for (const date of dates) {
    years += date.years;
    months += date.months;
    days += date.days;
  }

  if (days >= 30) {
    const daysToMonths = Math.floor(days / 30);

    days -= (daysToMonths * 30);
    months += daysToMonths;
  }

  if (months >= 12) {
    const monthsToYears = Math.floor(months / 12);

    months -= (monthsToYears * 12);
    years += monthsToYears;
  }

  years = Math.floor(years);
  months = Math.floor(months);
  days = Math.floor(days);

  const hasTime = (years+months+days) > 0;

  return { years, months, days, hasTime };
}

const calculateTimeByDays = (totalDays) => {
  const daysInYear = 365;
  const daysInMonth = 30;

  // Calcular anos, meses e dias
  const years = Math.floor(totalDays / daysInYear);
  const remainingDaysAfterYears = totalDays % daysInYear;

  const months = Math.floor(remainingDaysAfterYears / daysInMonth);
  const days = Math.floor(remainingDaysAfterYears % daysInMonth);

  return { years, months, days };
}

const getTimeOld = (workRecords, limitDate = null, field = null, gender, regimeDate) => {
  let dates = [];

  let disablementLevel = null;

  if (field && field.includes("isDisabled")) {
    disablementLevel = field.split("-")[1];
    field = "isDisabled";
  }

  workRecords.forEach((workRecord) => {
    const { ingressDate, egressDate, addition, deduction1, deduction2, deduction3, isSpecialTime, disabledLevel } = workRecord;      

    if (!field || (field && workRecord[field] && (!disablementLevel || disablementLevel === disabledLevel))) {
      if (isSpecialTime && !field && moment(ingressDate).isBefore(moment(regimeDate))) {
        const multiple = (gender === "Homem") ? 1.4 : 1.2;

        if (limitDate) {
          if (moment(ingressDate).isBefore(limitDate)) {
            let egress = null;

            let isLimitDate = false;

            if (moment(egressDate).isBefore(limitDate)) {
              egress = moment(egressDate);
            } else {
              egress = moment(limitDate);
              isLimitDate = true;
            }

            let isBefore2019 = false;

            if (egress.isSameOrBefore(regimeDate)) {
              egress = moment(isLimitDate ? limitDate : egressDate);
              isBefore2019 = true;
            } else {
              egress = moment(regimeDate);
            }

            let ingress = moment(ingressDate);

            egress.add((addition * 2), 'days');
            egress.subtract(deduction1, 'days');
    
            if (moment(limitDate).isAfter(moment('1998-12-16'))) {
              egress.subtract(deduction2, 'days');
            }
      
            if (moment(limitDate).isAfter(moment(regimeDate))) {
              egress.subtract(deduction3, 'days');
            }

            let days = egress.diff(ingress, 'days');

            days *= multiple;

            if (!isBefore2019) {
              egress = moment(isLimitDate ? limitDate : egressDate);
              ingress = moment(regimeDate).add(1, 'day');

              let days2 = egress.diff(ingress, 'days');

              days += days2;
            }

            dates.push({ days });
          }
        } else {
          let egress = null;
          let isBefore2019 = false;

          if (moment(egressDate).isSameOrBefore(regimeDate)) {
            egress = moment(egressDate);
            isBefore2019 = true;
          } else {
            egress = moment(regimeDate);
          }

          let ingress = moment(ingressDate);
  
          egress.add((addition * 2), 'days');
          egress.subtract(deduction1, 'days');
          egress.subtract(deduction2, 'days');
          egress.subtract(deduction3, 'days');

          let days = egress.diff(ingress, 'days');

          days *= multiple;

          if (!isBefore2019) {
            egress = moment(egressDate);
            ingress = moment(regimeDate).add(1, 'day');

            let days2 = egress.diff(ingress, 'days');

            days += days2;
          }
    
          dates.push({ days });
        }
      } else {
        if (limitDate) {

          if (moment(ingressDate).isBefore(limitDate)) {
            let egress = null;

            if (moment(egressDate).isBefore(limitDate)) {
              egress = moment(egressDate)
            } else {
              egress = moment(limitDate);
            }

            let ingress = moment(ingressDate);

            egress.add((addition * 2), 'days');
            egress.subtract(deduction1, 'days');
    
            if (moment(limitDate).isAfter(moment('1998-12-16'))) {
              egress.subtract(deduction2, 'days');
            }
      
            if (moment(limitDate).isAfter(moment(regimeDate))) {
              egress.subtract(deduction3, 'days');
            }

            const days = egress.diff(ingress, 'days');

            dates.push({ days });
          }
        } else {
          let egress = moment(egressDate);
          let ingress = moment(ingressDate);
  
          egress.add((addition * 2), 'days');
          egress.subtract(deduction1, 'days');
          egress.subtract(deduction2, 'days');
          egress.subtract(deduction3, 'days');

          const days = egress.diff(ingress, 'days');
    
          dates.push({ days });
        }
      }
    }
  });

  let days = 0;

  for (const date of dates) {
    days += date.days;
  }

  const result = calculateTimeByDays(days);

  let years = result.years;
  let months = result.months;
  days = result.days;

  const hasTime = (years+months+days) > 0;

  return { years, months, days, hasTime };
}

const dates = {
    'União': '2019-11-13',
    'PR': '2019-12-03',
    'SP': '2020-03-07',
    'ES': '2020-07-01',
    'RS': '2019-12-22',
    'MT': '2020-08-20',
    'PB': '2021-07-02',
    'Curitiba': '2021-12-31'
}

const getRegimeDate = (regime) => {
    let date = dates[regime];
  
    if (regime === 'Sem Reforma') date = moment().add(1, 'day').format('YYYY-MM-DD');
    
    if (!date) return null;

    return moment(date);
}

const getIPAddress = async () => {
  return await fetch('https://api.ipify.org?format=json')
  .then(x => x.json())
  .then(({ ip }) => {
    return ip;
  });
}

export {
    getTime,
    getRegimeDate,
    getIPAddress
};
