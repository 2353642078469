<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="xl"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0">Relatório de Beneficio</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row" id="benefit-report">
        <div class="col-md-12">
          <h5>Cálculo Usado:</h5>
          <p>{{ benefitLogs.name }}</p>
        </div>
        <div class="col-md-12 my-2" v-if="benefitLogs.removedSalaries && benefitLogs.isAutoDiscart">
          <button class="btn btn-primary" v-on:click="showRemovedSalaries = !showRemovedSalaries">
            {{ showRemovedSalaries ? 'Esconder' : 'Ver' }} Descarte Automatico
          </button>
          <button class="btn btn-primary mx-2" v-on:click="exportToXLSX(benefitLogs.removedSalaries)">
            Exportar Salários
          </button>
          <div v-if="showRemovedSalaries">
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Indice de Atualização</th>
                  <th class="text-nowrap">Remuneração Atualizada</th>
                  <th class="text-nowrap">Valor Final</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in benefitLogs.removedSalaries"
                  v-bind:key="index"
                >
                  <th>
                    {{row.salary.month}}/{{row.salary.year}}
                  </th>
                  <th>
                    {{formatMoney(row.salary.value)}}
                  </th>
                  <th>
                    {{row.salary.update}}
                  </th>
                  <th>
                    {{formatMoney(row.salary.updatedValue)}}
                  </th>
                  <th>
                    {{formatMoney(row.benefit)}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 my-2" v-if="benefitLogs.salaries">
          <button class="btn btn-primary" v-on:click="showSalaries = !showSalaries">
            {{ showSalaries ? 'Esconder' : 'Ver' }} Salários
          </button>
          <button class="btn btn-primary mx-2" v-on:click="exportToXLSX(benefitLogs.salaries)">
            Exportar Salários
          </button>
          <div v-if="showSalaries">
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Indice de Atualização</th>
                  <th class="text-nowrap">Remuneração Atualizada</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in benefitLogs.salaries"
                  v-bind:key="index"
                >
                  <th>
                    {{row.month}}/{{row.year}}
                  </th>
                  <th>
                    {{formatMoney(row.value)}}
                  </th>
                  <th>
                    {{row.update}}
                  </th>
                  <th>
                    {{formatMoney(row.updatedValue)}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 my-2" v-if="benefitLogs.salariesSinceJul94">
          <button class="btn btn-primary" v-on:click="showJul94Salaries = !showJul94Salaries">
            {{ showJul94Salaries ? 'Esconder' : 'Ver' }} Salários Desde Julho de 94
          </button>
          <button class="btn btn-primary mx-2" v-on:click="exportToXLSX(benefitLogs.salariesSinceJul94)">
            Exportar Salários
          </button>
          <div v-if="showJul94Salaries">
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Indice de Atualização</th>
                  <th class="text-nowrap">Remuneração Atualizada</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in benefitLogs.salariesSinceJul94"
                  v-bind:key="index"
                >
                  <th>
                    {{row.month}}/{{row.year}}
                  </th>
                  <th>
                    {{formatMoney(row.value)}}
                  </th>
                  <th>
                    {{row.update}}
                  </th>
                  <th>
                    {{formatMoney(row.updatedValue)}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 my-2" v-if="benefitLogs.salariesSinceJul94Sorted">
          <button class="btn btn-primary" v-on:click="showJul94SalariesSorted = !showJul94SalariesSorted">
            {{ showJul94SalariesSorted ? 'Esconder' : 'Ver' }} Salários Desde Julho de 94 Ordenados por Valor
          </button>
          <div v-if="showJul94SalariesSorted">
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Indice de Atualização</th>
                  <th class="text-nowrap">Remuneração Atualizada</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in benefitLogs.salariesSinceJul94Sorted"
                  v-bind:key="index"
                >
                  <th>
                    {{row.month}}/{{row.year}}
                  </th>
                  <th>
                    {{formatMoney(row.value)}}
                  </th>
                  <th>
                    {{row.update}}
                  </th>
                  <th>
                    {{formatMoney(row.updatedValue)}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-12 my-2" v-if="benefitLogs.greatestSalaries">
          <button class="btn btn-primary" v-on:click="showGreatestSalaries = !showGreatestSalaries">
            {{ showGreatestSalaries ? 'Esconder' : 'Ver' }} {{ `${benefitLogs.greatestSalariesPercentage * 100}%` }} dos Maiores Salários Desde Julho de 94
          </button>
          <button class="btn btn-primary mx-2" v-on:click="exportToXLSX(benefitLogs.greatestSalaries)">
            Exportar Salários
          </button>
          <div v-if="showGreatestSalaries">
            <table class="table table-bordered mt-2">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Indice de Atualização</th>
                  <th class="text-nowrap">Remuneração Atualizada</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in benefitLogs.greatestSalaries"
                  v-bind:key="index"
                >
                  <th>
                    {{row.month}}/{{row.year}}
                  </th>
                  <th>
                    {{formatMoney(row.value)}}
                  </th>
                  <th>
                    {{row.update}}
                  </th>
                  <th>
                    {{formatMoney(row.updatedValue)}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.lastSalary">
          <h5>Ultimo Salário:</h5>
          <p>{{ benefitLogs.lastSalary.month }}/{{ benefitLogs.lastSalary.year }} - {{ formatMoney(benefitLogs.lastSalary.updatedValue )}}</p>
        </div>

        <div class="col-md-12 my-1" v-if="benefitLogs.value">
          <h5>Valor Total:</h5>
          <p>{{ formatMoney(benefitLogs.value) }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.count">
          <h5>Quantidade de Salários:</h5>
          <p>{{ benefitLogs.count }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.baseContribution">
          <h5>Valor Base<span v-if="benefitLogs.name === 'Ultima remuneração proporcional ao tempo de contribuição'"> (Valor Ultimo Salario)</span><span v-else> (Valor Total / Quantidade de Salários)</span>:</h5>
          <p>{{ formatMoney(benefitLogs.baseContribution) }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.gender">
          <h5>Gênero:</h5>
          <p>{{ benefitLogs.gender }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.baseDivisor">
          <h5>Divisor:</h5>
          <p>{{ benefitLogs.baseDivisor }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.baseDivisorInDays">
          <h5>Divisor em Dias:</h5>
          <p>{{ benefitLogs.baseDivisorInDays }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.contributionYears">
          <h5>Anos de Contribuição:</h5>
          <p>{{ benefitLogs.contributionYears }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.contributionMonths">
          <h5>Meses de Contribuição:</h5>
          <p>{{ benefitLogs.contributionMonths }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.contributionDays">
          <h5>Dias de Contribuição:</h5>
          <p>{{ benefitLogs.contributionDays }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.contributionInDays">
          <h5>Contribuição em Dias: (Dias + Meses * 30 + Anos * 365)</h5>
          <p>{{ benefitLogs.contributionInDays }}</p>
        </div>
        
        <div class="col-md-12 mb-1" v-if="benefitLogs.TC20">
          <h5>Tempo de Contribuição / 20 (TC/20):</h5>
          <p>{{ benefitLogs.TC20 }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.initialPercentage">
          <h5>Porcentagem Inicial:</h5>
          <p>{{ `${benefitLogs.initialPercentage}%` }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.extraPercentage">
          <h5>Porcentagem Extra:</h5>
          <p>{{ `${benefitLogs.extraPercentage > 0 ? benefitLogs.extraPercentage : 0}%` }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.percentage">
          <h5>Porcentagem:</h5>
          <p>{{ `${benefitLogs.percentage * 100}%` }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.reductorPercentage">
          <h5>Porcentagem do Redutor:</h5>
          <p>{{ `${benefitLogs.reductorPercentage * 100}%` }}</p>
        </div>
        
        <div class="col-md-12 mb-1" v-if="benefitLogs.retiredDate">
          <h5>Data da Aposentadoria:</h5>
          <p>{{ benefitLogs.retiredDate }}</p>
        </div>
        <div class="col-md-12 mb-1" v-if="benefitLogs.retiredAge">
          <h5>Anos na Aposentadoria:</h5>
          <p>{{ benefitLogs.retiredAge }}</p>
        </div>

        <div class="col-md-12 mb-1" v-if="benefitLogs.necessaryAge">
          <h5>Idade Necessária:</h5>
          <p>{{ benefitLogs.necessaryAge }}</p>
        </div>
        
        <div class="col-md-12 mb-1" v-if="benefitLogs.extraYears">
          <h5>Anos Extras:</h5>
          <p>{{ benefitLogs.extraYears > 0 ? benefitLogs.extraYears : 0 }}</p>
        </div>
        
        <div class="col-md-12 mb-1">
          <h5>Valor Final:</h5>
          <p>{{ formatMoney(benefitLogs.contribution) }}</p>
        </div>

        <div class="col-md-12 mb-1">
          <h5>Lógica Usada:</h5>
          <p v-for="line of explanations[benefitLogs.name]" :key="line">- {{ line }}</p>
        </div>

        <!-- <pre>{{ JSON.stringify(benefitLogs) }}</pre> -->
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <!-- <b-button
        variant="outline-primary"
        @click="exportToPDF()"
      >
        Exportar
      </b-button> -->
      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Fechar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: 'RuleBenefitReportModal',

  components: {},

  props: {
    benefitLogs: {
      type: Object,
    }
  },

  data: () => ({
    isLoading: false,
    isSaving: false,
    modalShow: true,
    showJul94Salaries: false,
    showJul94SalariesSorted: false,
    showGreatestSalaries: false,
    showSalaries: false,
    showRemovedSalaries: false,
    explanations: {
      'Ultima Remuneração': [
        'Buscar Todos Salários',
        'Buscar Último Salário',
        'Valor Final = Último Salário'
      ],

      'Ultima remuneração proporcional ao tempo de contribuição': [
        'Buscar Todos Salários',
        'Buscar Último Salário',
        'Valor Base = Último Salário',
        'Divisor = 35 (para Homem) ou Divisor = 30 (para Mulher)',
        'Divisor em Dias = Divisor * 365',
        'Contribuição Total em Dias = Dias de Contribuição + (Meses de Contribução * 30) + (Anos de Contribuição * 365)',
        'Valor Final = Valor Base * (Contribuição Total em Dias / Divisor em Dias)'
      ],

      'MÉDIA DE TODAS AS CONTRIBUIÇÕES DESDE JULHO/1994': [
        'Buscar Todos Salários desde Julho/1994',
        'Valor Total = Soma de Todos Salários Filtrados',
        'Quantidade de Salários = Quantidade de Salários Filtrados',
        'Valor Final = Valor Total / Quantidade de Salários'
      ],

      'MÉDIA DE 80% DAS MAIORES CONTRIBUIÇÕES DESDE JULHO/94': [
        'Buscar Todos Salários desde Julho/1994',
        'Ordernar Salários do Maior para o Menor',
        'Filtrar 80% dos Maiores Salários',
        'Valor Total = Soma de Todos Salários Filtrados',
        'Quantidade de Salários = Quantidade de Salários Filtrados',
        'Valor Final = Valor Total / Quantidade de Salários'
      ],

      'MÉDIA DE 80% DAS MAIORES CONTRIBUIÇÕES DESDE JULHO/94 + proporcional ao tempo de contribuição': [
        'Buscar Todos Salários desde Julho/1994',
        'Ordernar Salários do Maior para o Menor',
        'Filtrar 80% dos Maiores Salários',
        'Valor Total = Soma de Todos Salários Filtrados',
        'Quantidade de Salários = Quantidade de Salários Filtrados',
        'Valor Base = Valor Total / Quantidade de Salários',
        'Divisor = 35 (para Homem) ou Divisor = 30 (para Mulher)',
        'Divisor em Dias = Divisor * 365',
        'Contribuição Total em Dias = Dias de Contribuição + (Meses de Contribução * 30) + (Anos de Contribuição * 365)',
        'Valor Final = Valor Base * (Contribuição Total em Dias / Divisor em Dias)'
      ],

      'TC/20 com resultado limitado a 1 * (60% DA MÉDIA DE TODOS AS CONTRIBUIÇÕES DESDE JULHO DE 1994 + 2% POR CADA ANO QUE EXCEDER 20)': [
        'Buscar Todos Salários desde Julho/1994',
        'Valor Total = Soma de Todos Salários',
        'Quantidade de Salários = Quantidade de Salários',
        'Valor Base = Valor Total / Quantidade de Salários',
        'Anos Extras = Anos de Contribuição - 20',
        'Porcentagem Inicial = 60%',
        'Porcentagem Extra = 2% * Anos Extras (para Anos Extras maior que 0)',
        'Porcentagem = Porcentagem Inicial + Porcentagem Extra',
        'Valor Final = Valor Base * Porcentagem',
        'TC/20 = Anos de Contribuição / 20',
        'Se TC/20 < 1: Valor Final = Valor Final * TC/20',      
      ],

      'MÉDIA DE 80% DAS MAIORES CONTRIBUIÇÕES DESDE JULHO/94 + REDUTOR': [
        'Buscar Todos Salários desde Julho/1994',
        'Ordernar Salários do Maior para o Menor',
        'Filtrar 80% dos Maiores Salários',
        'Valor Total = Soma de Todos Salários Filtrados',
        'Quantidade de Salários = Quantidade de Salários Filtrados',
        'Valor Base = Valor Total / Quantidade de Salários',
        'Porcentagem do Redutor = 3.5% (Se aposentou antes de 31/12/2005) ou Porcentagem do Redutor = 5% (Se aposentou depois de 31/12/2005)',
        'Idade de Aposentadoria = Idade quando possivel Aposentadoria',
        'Idade Necessária = Idade Necessária para Regra',
        'Idade Extra = Idade de Aposentadoria - Idade Necessária',
        'Valor Final = Valor Base',
        'Se Idade Extra > 0: Valor Final = Valor Final - (Valor Final * Porcentagem do Redutor * Idade Extra)'
      ],

      '60% DA MÉDIA DE TODOS AS CONTRIBUIÇÕES DESDE JULHO DE 1994 + 2% POR CADA ANO QUE EXCEDER 20': [
        'Buscar Todos Salários desde Julho/1994',
        'Valor Total = Soma de Todos Salários',
        'Valor Base = Valor Total / Quantidade de Salários',
        'Anos Extras = Anos de Contribuição - 20',
        'Porcentagem Inicial = 60%',
        'Porcentagem Extra = 2% * Anos Extras (para Anos Extras maior que 0)',
        'Porcentagem = Porcentagem Inicial + Porcentagem Extra',
        'Valor Final = Valor Base * Porcentagem'
      ],

      '70% da MÉDIA DE 80% DAS MAIORES CONTRIBUIÇÕES DESDE JULHO/94 + 1% para cada grupo de 12 contribuições': [
        'Buscar Todos Salários desde Julho/1994',
        'Filtrar 80% dos Maiores Salários',
        'Valor Total = Soma de Todos Salários Filtrados',
        'Quantidade de Salários = Quantidade de Salários Filtrados',
        'Valor Base = Valor Total / Quantidade de Salários',
        'Porcentagem Inicial = 70%',
        'Porcentagem Extra = 1% * Anos de Contribuição',
        'Porcentagem = Porcentagem Inicial + Porcentagem Extra',
        'Valor Final = Valor Base * Porcentagem'
      ]
    }
  }),

  created() {

  },

  methods: {
    formatMoney(value) {
      if (!value) return "0".toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

      return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    },

    async exportToPDF() {
      const div = document.getElementById('benefit-report');

      let style = '';

      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        style += node.outerHTML;
      }

      const win = window.open();

      win.document.write(`<style>${style}</style>`);
      win.document.write(div.innerHTML);

      win.document.close();
      win.focus();
      win.print();
      win.close();
    },

    async exportToXLSX(salaries) {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Salários",
        Subject: "Salários",
        Author: "GenialPrev",
        CreatedDate: new Date(),
      };

      wb.SheetNames.push("Report Sheet");

      let ws_data = [
        [
          "Mês",
          "Valor",
          "Indice de Atualização",
          "Remuneração Atualizada",
        ],
      ];

      salaries.forEach((salary) => {
        const row = [
          `${salary.month}/${salary.year}`,
          `${this.formatMoney(salary.value)}`,
          `${salary.update}`,
          `${this.formatMoney(salary.updatedValue)}`
        ];

        ws_data.push(row);
      });

      let ws = XLSX.utils.aoa_to_sheet(ws_data);

       wb.Sheets["Report Sheet"] = ws;

      let wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
      let view = new Uint8Array(buf); //create uint8array as viewer

      for (let i = 0; i < wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xff; //convert to octet

      saveAs(
        new Blob([buf], { type: "application/octet-stream" }),
        "Salários.xlsx"
      );
    }
  }
}
</script>

<style scoped>
</style>
