/**
 * RestResource
 * 
 * This is inspired at @reststate/vuex and works similarly
 * @reststate/vuex: https://vuex.reststate.codingitwrong.com/
 */

import ResourceClient from './ResourceClient.js';
import deepEquals from './deepEquals';
const pluralize = require('pluralize');

const STATUS_INITIAL = 'INITIAL';
const STATUS_LOADING = 'LOADING';
const STATUS_ERROR = 'ERROR';
const STATUS_SUCCESS = 'SUCCESS';

const storeRecord = records => newRecord => {
  const existingRecord = records.find(r => r.id === newRecord.id);
  if (existingRecord) {
    Object.assign(existingRecord, newRecord);
  } else {
    records.push(newRecord);
  }
};

const getResourceIdentifier = resource => {
  if (!resource) {
    return resource;
  }

  return {
    type: resource.type,
    id: resource.id,
  };
};

const getRelationshipType = relationship => {
  const data = Array.isArray(relationship.data)
    ? relationship.data[0]
    : relationship.data;

  return data && data.type;
};

const storeRelations = ({ commit, dispatch }, result, resourceName, relations) => {
  // store the included records
  console.log('storeRelations', relations);

  let records = Array.isArray(result[resourceName]) ? result[resourceName]: [result[resourceName]];

  relations.forEach(relation => {
    records.forEach(record => {
      if(record[relation]) {
        const recordsToStore = Array.isArray(record[relation]) ? record[relation] : [record[relation]];
        recordsToStore.forEach(relatedRecord => {
          const action = `${relation}/storeRecord`;
          console.log(`dispatch`, action);
          dispatch(action, relatedRecord, { root: true });
          dispatch(`storeRelated`, {
            relation,
            record: relatedRecord
          });
        });
      }
    });
  });
};


const matches = criteria => test =>
  Object.keys(criteria).every(key => deepEquals(criteria[key], test[key]));

const handleError = commit => errorResponse => {
  commit('SET_STATUS', STATUS_ERROR);
  commit('STORE_ERROR', errorResponse);
  throw errorResponse;
};

const initialState = () => ({
  records: [],
  related: [],
  filtered: [],
  page: [],
  error: null,
  status: STATUS_INITIAL,
  links: {},
  lastCreated: null,
  lastMeta: null,
});

const resourceModule = ({ name: resourceName, httpClient, relations }) => {
  const client = new ResourceClient({ name: resourceName, httpClient });

  const getRelationshipIndex = params => {
    const { parent, relationship = resourceName } = params;
    const parentResourceIdentifier = getResourceIdentifier(parent);

    return {
      parent: parentResourceIdentifier,
      relationship,
    };
  };

  const singularResourceName = pluralize.singular(resourceName);
  const pluralResourceName = pluralize.plural(resourceName);
  console.log(`singularResourceName ${singularResourceName} pluralResourceName ${pluralResourceName}`);

  return {
    namespaced: true,

    state: initialState(),

    mutations: {
      REPLACE_ALL_RECORDS: (state, records) => {
        state.records = records;
      },

      REPLACE_ALL_RELATED: (state, related) => {
        state.related = related;
      },

      SET_STATUS: (state, status) => {
        state.status = status;
      },

      STORE_RECORD: (state, newRecord) => {
        const { records } = state;

        storeRecord(records)(newRecord);
      },

      STORE_RECORDS: (state, newRecords) => {
        const { records } = state;

        newRecords.forEach(storeRecord(records));
      },

      STORE_PAGE: (state, records) => {
        state.page = records.map(({ id }) => id);
      },

      STORE_META: (state, meta) => {
        state.lastMeta = meta;
      },

      STORE_ERROR: (state, error) => {
        state.error = error;
      },

      STORE_RELATED: (state, { relation, record }) => {
        console.log(`object relation`, relation);
        const { related } = state;
        if (!related[relation]) {
          state.related[relation] = [];
        }

        const existingRecord = related[relation].find(relatedRecord => relatedRecord.id == record.id);

        console.log(`existingRecord`, existingRecord);
        if (existingRecord) {
          Object.assign(existingRecord, record);
        } else {
          related[relation].push(record);
          console.log(`STORE_RELATED`, related);
        }
      },

      STORE_FILTERED: (state, { matchedIds, params }) => {
        const { filtered } = state;

        const existingRecord = filtered.find(matches(params));
        if (existingRecord) {
          existingRecord.matchedIds = matchedIds;
        } else {
          filtered.push(Object.assign({ matchedIds }, params));
        }
      },

      STORE_LAST_CREATED: (state, record) => {
        state.lastCreated = record;
      },

      REMOVE_RECORD: (state, record) => {
        state.records = state.records.filter(r => r.id !== record.id);
      },

      SET_LINKS: (state, links) => {
        state.links = links || {};
      },

      RESET_STATE: state => {
        Object.assign(state, initialState());
      },
    },

    actions: {
      loadAll({ commit, dispatch }, { options } = {}) {
        console.log('Resource name', resourceName );
        commit('SET_STATUS', STATUS_LOADING);
        return client
          .all({ options })
          .then(result => {
            commit('SET_STATUS', STATUS_SUCCESS);
            commit('REPLACE_ALL_RECORDS', result[resourceName]);
            commit('STORE_META', result.meta);
            // storeIncluded({ commit, dispatch }, result);
            storeRelations({ commit, dispatch }, result, resourceName, relations);
          })
          .catch(handleError(commit));
      },

      loadById({ commit, dispatch }, { id, options }) {
        commit('SET_STATUS', STATUS_LOADING);
        return client
          .find({ id, options })
          .then(results => {
            console.log('results', results);
            commit('SET_STATUS', STATUS_SUCCESS);
            commit('STORE_RECORD', results[singularResourceName]);
            commit('STORE_META', results.meta);
            // storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      loadWhere({ commit, dispatch }, params) {
        const { filter, options } = params;
        commit('SET_STATUS', STATUS_LOADING);
        return client
          .where({ filter, options })
          .then(results => {
            commit('SET_STATUS', STATUS_SUCCESS);
            const matches = results.data;
            const matchedIds = matches.map(record => record.id);
            commit('STORE_RECORDS', matches);
            commit('STORE_FILTERED', { params, matchedIds });
            commit('STORE_META', results.meta);
            storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      loadPage({ commit, dispatch }, { options }) {
        commit('SET_STATUS', STATUS_LOADING);
        return client
          .all({ options })
          .then(response => {
            commit('SET_STATUS', STATUS_SUCCESS);
            commit('STORE_RECORDS', response.data);
            commit('STORE_PAGE', response.data);
            commit('STORE_META', response.meta);
            commit('SET_LINKS', response.links);
            storeIncluded({ commit, dispatch }, response);
          })
          .catch(handleError(commit));
      },

      loadNextPage({ commit, state, dispatch }) {
        const options = {
          url: state.links.next,
        };
        return client.all({ options }).then(response => {
          commit('STORE_RECORDS', response.data);
          commit('STORE_PAGE', response.data);
          commit('SET_LINKS', response.links);
          commit('STORE_META', response.meta);
          storeIncluded({ commit, dispatch }, response);
        });
      },

      loadPreviousPage({ commit, state, dispatch }) {
        const options = {
          url: state.links.prev,
        };
        return client.all({ options }).then(response => {
          commit('STORE_RECORDS', response.data);
          commit('STORE_PAGE', response.data);
          commit('SET_LINKS', response.links);
          commit('STORE_META', response.meta);
          storeIncluded({ commit, dispatch }, response);
        });
      },

      loadRelated({ commit, dispatch }, params) {
        const { parent, relationship = resourceName, options } = params;
        commit('SET_STATUS', STATUS_LOADING);
        const paramsToStore = {
          ...params,
          relationship,
        };
        return client
          .related({ parent, relationship, options })
          .then(results => {
            commit('SET_STATUS', STATUS_SUCCESS);
            const { id, type } = parent;
            if (Array.isArray(results.data)) {
              const relatedRecords = results.data;
              const relatedIds = relatedRecords.map(record => record.id);
              commit('STORE_RECORDS', relatedRecords);
              commit('STORE_RELATED', { params: paramsToStore, relatedIds });
            } else {
              const record = results.data;
              const relatedIds = record.id;
              commit('STORE_RECORDS', [record]);
              commit('STORE_RELATED', { params: paramsToStore, relatedIds });
            }
            commit('STORE_META', results.meta);
            storeIncluded({ commit, dispatch }, results);
          })
          .catch(handleError(commit));
      },

      create({ commit }, record) {
        const recordData = {};
        recordData[singularResourceName] = record;
        return client.create(recordData).then(result => {
          commit('STORE_RECORD', result[singularResourceName]);
          commit('STORE_LAST_CREATED', result[singularResourceName]);
        });
      },

      update({ commit, dispatch, getters }, record) {
        const requestData = {};
        requestData[singularResourceName] = record;
        return client.update(record.id, requestData).then(() => {
          const oldRecord = getters.byId({ id: record.id });

          // remove old relationships first
          if (oldRecord && oldRecord.relationships) {
            for (const entry of Object.entries(oldRecord.relationships)) {
              const [relationship, entity] = entry;
              const type = getRelationshipType(entity);
              const paramsToStore = {
                relationship,
                parent: getResourceIdentifier(oldRecord),
              };

              dispatch(
                `${type}/storeRelated`,
                {
                  params: paramsToStore,
                  relatedIds: null,
                },
                { root: true },
              );
            }
          }

          // save entity
          commit('STORE_RECORD', record);
        });
      },

      delete({ commit }, record) {
        return client.delete(record).then(() => {
          commit('REMOVE_RECORD', record);
        });
      },

      storeRecord({ commit }, record) {
        commit('STORE_RECORD', record);
      },

      storeRelated({ commit }, { relation, record }) {
        commit('STORE_RELATED', {relation, record});
      },

      removeRecord({ commit }, record) {
        commit('REMOVE_RECORD', record);
      },

      resetState({ commit }) {
        commit('RESET_STATE');
      },
    },

    getters: {
      isLoading: state => state.status === STATUS_LOADING,
      isError: state => state.status === STATUS_ERROR,
      error: state => state.error,
      hasPrevious: state => !!state.links.prev,
      hasNext: state => !!state.links.next,
      all: state => state.records,
      lastCreated: state => state.lastCreated,
      byId: state => id => state.records.find(r => r.id == id),
      lastMeta: state => state.lastMeta,
      page: state =>
        state.page.map(id => state.records.find(record => record.id === id)),
      where: state => params => {
        const entry = state.filtered.find(matches(params));

        if (!entry) {
          return [];
        }

        const ids = entry.matchedIds;
        return ids.map(id => state.records.find(record => record.id === id));
      },
      related: state => params => {
        if(!state.related[params.related]) {
          console.log('Nothing to do here.');
          return [];
        }
        const related = state.related[params.related].filter(matches(params.relation));
        return related;
      },
    },
  };
};

const mapResourceModules = ({ names, httpClient }) =>
  names.reduce(
    (acc, name) =>
      Object.assign({ [name]: resourceModule({ name, httpClient }) }, acc),
    {},
  );

export { resourceModule, mapResourceModules };