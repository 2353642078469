<template>
  <b-container class="list-calculations-wrapper">
    <div class="calculations-wrapper p-3" v-if="selectedCalculation">
      <b-card class="mt-3 mb-3 shadow-sm" id="section-to-print" no-body>
        <div class="row mx-0 p-3 bg-gray-100 border-bottom d-flex justify-content-between">
          <h5 class="font-weight-bold mb-0 text-gray-700">Novo Cálculo</h5>
        </div>
        <div class="row mx-0">
          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Nome do Cálculo</h6>
              <div v-if="isEditing">
                <input type="text" class="form-control form-control-sm" v-model="selectedCalculation.name" v-on:blur="inputValidation('name')" />
                <ValidationMessage field="name" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="selectedCalculation.name" v-else />
            </div>
          </div>
          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Dt. Criação</h6>
              <TextInfo :isLoading="isLoading" :text="formatDate(selectedCalculation.createdAt)" />
            </div>
          </div>
          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Dt. Para Atualização</h6>
              <div v-if="isEditing">
                <InputDatePicker v-model="selectedCalculation.calculationDate" v-on:blur="inputValidation('calculationDate')" :ignoreLimit="true" />
                <ValidationMessage field="calculationDate" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="formatDate(selectedCalculation.calculationDate)" v-else />
            </div>
          </div>
          <div class="col-md-3 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Regime de Previdência</h6>
              <div v-if="isEditing">
                <autocomplete 
                  :search="searchRegime"
                  :get-result-value="getRegimeValue"
                  @submit="handleRegimeSubmit"
                  :debounce-time="750"
                >
                </autocomplete>
                <ValidationMessage field="regime" v-bind:validations="validations" />
              </div>
              <TextInfo :isLoading="isLoading" :text="calculation.regime.name || calculation.regime" v-else />
            </div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Dt. Ingresso no Serv. Público</h6>
              <div v-if="isEditing">
                <InputDatePicker v-model="selectedCalculation.publicServiceIngressDate" v-on:blur="inputValidation('publicServiceIngressDate')" />
                <ValidationMessage field="publicServiceIngressDate" v-bind:validations="validations" />
              </div>
              <TextInfo
                :isLoading="isLoading"
                :text="publicServiceIngressDate"
                :tooltipInfo="isEditing && workRecords.length ? workRecordMessage : ''"
                v-else
              />
            </div>
          </div>
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Serviço Público</h6>
              <div class="row">
                <div class="col-6 text-gray-500 font-italic">
                  <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="selectedCalculation.publicServiceTimeYears" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="publicServiceTime.years"
                    :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.publicServiceTimeMonths" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="publicServiceTime.months"
                    :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.publicServiceTimeDays" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="publicServiceTime.days"
                    :tooltipInfo="isEditing && publicServiceTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>

              <ValidationMessage field="publicServiceTime" v-bind:validations="validations" />
            </div>
          </div>
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Magistério</h6>
  
              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="selectedCalculation.teachingTimeYears" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="selectedCalculation.teachingTimeYears" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>
  
              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.teachingTimeMonths" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="selectedCalculation.teachingTimeMonths" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>
  
              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.teachingTimeDays" />
                  <TextInfo :isLoading="isLoading" emptyText="-" :text="selectedCalculation.teachingTimeDays" v-else />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>
  
              <ValidationMessage field="teachingTime" v-bind:validations="validations" />
            </div>
          </div>
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Carreira</h6>

              <div class="row">
                <div class="col-6 text-gray-500 font-italic">
                  <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="selectedCalculation.carreerTimeYears" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="carreerTime.years"
                    :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.carreerTimeMonths" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="carreerTime.months"
                    :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>

              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.carreerTimeDays" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="carreerTime.days"
                    :tooltipInfo="isEditing && carreerTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>
              
              <ValidationMessage field="carreerTime" v-bind:validations="validations" />
            </div>
          </div>
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
          <div class="p-3">
            <h6 class="text-uppercase text-gray-600 small">Tempo no Cargo</h6>

            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="selectedCalculation.positionTimeYears" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.years"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.positionTimeMonths" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.months"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.positionTimeDays" />
                <TextInfo
                  :isLoading="isLoading"
                  emptyText="-"
                  :text="positionTime.days"
                  :tooltipInfo="isEditing && positionTime ? workRecordMessage : ''"
                  v-else
                />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
              
            <ValidationMessage field="positionTime" v-bind:validations="validations" />
          </div>
        </div>
          <div class="col-md-2 px-0 border-right border-bottom border-gray-200">
            <div class="p-3">
              <h6 class="text-uppercase text-gray-600 small">Tempo de Contribuição</h6>
  
              <div class="row">
                <div class="col-6 text-gray-500 font-italic">
                  <input v-if="isEditing" type="number" min="0" class="form-control form-control-sm" v-model="selectedCalculation.contributionTimeYears" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.years"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
              </div>
  
              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.contributionTimeMonths" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.months"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
              </div>
  
              <div class="row">
                <div class="col-6">
                  <input v-if="isEditing" type="number" min="0" class="mt-1 form-control form-control-sm" v-model="selectedCalculation.contributionTimeDays" />
                  <TextInfo
                    :isLoading="isLoading"
                    emptyText="-"
                    :text="contributionTime.days"
                    :tooltipInfo="isEditing && contributionTime ? workRecordMessage : ''"
                    v-else
                  />
                </div>
                <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
              </div>
                
              <ValidationMessage field="contributionTime" v-bind:validations="validations" />
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-end mt-2 mb-2 mr-2">
            <button class="btn btn-danger ml-3" @click="closeCalculation()">Cancelar</button>
            <button class="btn btn-warning ml-3" @click="createCalc(selectedCalculation)">Criar</button>
        </div>
      </b-card>
    </div>

    <router-view />

    <div class="bg-gray-100 border-bottom d-flex p-3 justify-content-between align-items-end">
      <h5 class="text-uppercase text-primary text-black">Lista de cálculos</h5>
      <button class="btn btn-primary text-uppercase" @click="createNewCalculations()">Novo Cálculo</button>
    </div>

    <b-card no-body class="mt-3 shadow-sm">
      <b-table
        :items="calculations"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy="isLoading"
        stacked="md"
        show-empty
        small
        fixed 
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-default my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Carregando cálculos...</strong>
          </div>
        </template>

        <template #cell(name)="row">
          {{ row.value }}
        </template>

        <template #cell(createdAt)="row">
          <p class="mb-0" v-if="row.value">
            {{ row.value | moment('DD/MM/YYYY') }}
          </p>
        </template>

        <template #cell(updatedAt)="row">
          <p class="mb-0" v-if="row.value">
            {{ row.value | moment('DD/MM/YYYY') }}
          </p>
        </template>

        <template #cell(calculationDate)="row">
          <p class="mb-0" v-if="row.value">
            {{ row.value | moment('DD/MM/YYYY') }}
          </p>
        </template>

        <template #cell(actions)="row">
          <div class="d-flex flex-column align-items-center">
            <div class="mt-1">
              <router-link
                class="btn btn-sm btn-outline-primary" title="Abrir" role="button" size="sm"
                v-bind:to="{ name: 'calculations', params: { calculationId: row.item.id } }"
              >
                <i class="fa fa-eye"></i>
              </router-link>

              <span class="mr-1 ml-1 btn btn-sm btn-outline-primary" title="Duplicar" role="button" size="sm" @click="openCopyModal(row.item)">
                <i class="fa fa-clone"></i>
              </span>
    
              <span class="btn btn-sm btn-outline-danger" title="Excluir" role="button" size="sm" @click="deleteCalculation(row.item)">
                <i class="fa fa-trash"></i>
              </span>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <div class="d-flex justify-content-center mt-3 mb-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        size="sm"
        class="my-0"
      ></b-pagination>
    </div>

    <b-modal ref="copy-calculation-modal" title="Duplicação de Um Cálculo" size="md" centered hide-footer>
      <b-container class="border rounded p-3">
        <h6 class="text-black">Nome do Cálculo de Origem:</h6>
        <input type="text" :value="copiedCalculation.copiedName" class="form-control form-control-sm mb-1" disabled />
      </b-container>
      <b-container class="border rounded p-3 mt-3">
        <h6 class="text-black">Nome do Novo Cálculo a Ser Criado:</h6>
        <input type="text" v-model="copiedCalculation.name" class="form-control form-control-sm mb-1" />
      </b-container>
      <b-container class="border rounded p-3 mt-3">
        <h6 class="text-black">Selecione os Dados Que Deverão Ser Duplicados:</h6>
        <b-form-checkbox-group
          id="calculation-data-checkbox"
          v-model="copiedCalculation.selectedData"
          :options="calculationData"
          name="calculationData"
          stacked
        />
      </b-container>
      <div class="d-flex justify-content-around mt-3">
        <button type="button" class="btn btn-primary" @click="submitCopyModal()">Duplicar <i class="fa fa-check" aria-hidden="true"></i></button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import { deleteCalculation, createCalculation, copyCalculation } from '@/services/calculations';
  import ValidationMessage from '@/components/ValidationMessage.vue';
  import InputDatePicker from '@/components/InputDatePicker.vue';
  import TextInfo from '@/components/TextInfo.vue';
  import moment from 'moment';
  import { getRegimes } from '@/services/regimes';
  import Autocomplete from '@trevoreyre/autocomplete-vue';

  export default {
  name: 'ListCalculations',
  props: ["customerId"],
  components: { InputDatePicker, ValidationMessage, TextInfo, Autocomplete },
  data() {
    const initialCalculation = {
        name: null,
        customerId: this.customerId,
        // lastCalcDate: null,
        state: null,
        regime: null,
        publicServiceIngressDate: null,
        publicServiceTimeYears: 0,
        publicServiceTimeMonths: 0,
        publicServiceTimeDays: 0,
        teachingTimeYears: 0,
        teachingTimeMonths: 0,
        teachingTimeDays: 0,
        carreerTimeYears: 0,
        carreerTimeMonths: 0,
        carreerTimeDays: 0,
        positionTimeYears: 0,
        positionTimeMonths: 0,
        positionTimeDays: 0,
        contributionTimeYears: 0,
        contributionTimeMonths: 0,
        contributionTimeDays: 0,
      }

    return {
      selectedCalculation: null,
      initialCalculation,
      validations: {},
      isLoading: false,
      fields: [
        { key: 'name', label: 'Nome', sortable: 'client', sortDirection: 'desc' },
        { key: 'createdAt', label: 'Dt. Criação', sortable: 'client' },
        { key: 'updatedAt', label: 'Dt. Ult. Alteração', sortable: 'client' },
        // { key: 'state', label: 'UF', sortable: 'state' },
        { key: 'calculationDate', label: 'Dt. Último Cálculo', sortable: 'client' },
        { key: 'regime', label: 'Regime Previdência', sortable: 'client' },
        { key: 'actions', label: 'Ações', class: 'text-center' }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      isEditing: false,
      copiedCalculation: {
        id: null,
        copiedName: '',
        name: '',
        selectedData: ['time', 'salary']
      },
      calculationData: [
        { text: 'Tempo de Contribuição', value: 'time' },
        { text: 'Salários de Contribuição', value: 'salary' },
      ],
      workRecordMessage: 'Não é possível alterar esse campo pois o sistema está considerando os dados dos registros de trabalho.',
      regimes: []
    }
  },
  computed: {
    ...mapGetters('workRecords', ['workRecords']),
    ...mapGetters('calculations', ['calculations']),

    sessionUser () { return this.$store.getters.sessionUser },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },

    publicServiceIngressDate() {
      const { selectedCalculation } = this;

      if (selectedCalculation.publicServiceIngressDate) {
        return selectedCalculation.publicServiceIngressDate;
      }

      return null;
    },

    publicServiceTime() {
      const { selectedCalculation } = this;

        return {
          years: selectedCalculation.publicServiceTimeYears,
          months: selectedCalculation.publicServiceTimeMonths,
          days: selectedCalculation.publicServiceTimeDays,
        }
    },

    teachingTime() {
      const { selectedCalculation } = this;

        return {
          years: selectedCalculation.teachingTimeYears,
          months: selectedCalculation.teachingTimeMonths,
          days: selectedCalculation.teachingTimeDays,
        }
    },

    carreerTime() {
      const { selectedCalculation } = this;

        return {
          years: selectedCalculation.carreerTimeYears,
          months: selectedCalculation.carreerTimeMonths,
          days: selectedCalculation.carreerTimeDays,
        }
    },

    positionTime() {
      const { selectedCalculation } = this;

      return {
        years: selectedCalculation.positionTimeYears,
        months: selectedCalculation.positionTimeMonths,
        days: selectedCalculation.positionTimeDays,
      }
    },

    contributionTime() {
      const { selectedCalculation } = this;

        return {
          years: selectedCalculation.contributionTimeYears,
          months: selectedCalculation.contributionTimeMonths,
          days: selectedCalculation.contributionTimeDays,
        }
    },
  },
  mounted() {
    // Set the initial number of calculations
    this.totalRows = this.calculations.length
    this.updateList();
  },
  methods: {
    ...mapActions('calculations', ['loadCalculations']),

    async searchRegime(input) {
      try {
        this.regimes = await getRegimes({ name: input });
      } catch (err) {}

      return this.regimes;
    },

    getRegimeValue(result) {
      return result.name;
    },

    handleRegimeSubmit(result) {
      this.selectedCalculation.regime = result.name;
      this.selectedCalculation.regimeId = result.regimeId;
    },

    async updateList() {
      this.isLoading = true;

      await this.loadCalculations(this.customerId);

      this.totalRows = this.calculations.length;

      this.isLoading = false;
    },
    async createCalc(data) {
      const { calculation } = await createCalculation(data);

      this.$router.push({ name: 'calculations', params: { calculationId: calculation.id }})

      this.selectedCalculation = null;
      this.isEditing = false;

      this.updateList();
    },
    async createNewCalculations() {
      if (this.$route.params.calculationId) {
        this.$router.push({ name: 'customer', params: { id: this.customerId }})
      }

      this.selectedCalculation = this.initialCalculation;
      this.isEditing = true;
    },
    inputValidation(field) {
      let isValid = true;
      const { validations } = this;
      const isNormalField = !['contributionTime', 'teachingTime'].includes(field);
      
      if (isNormalField) {
        const value = String(this.selectedCalculation[field] || '').replace('Data inválida', '');

        if (!value) {
          validations[field] = 'Campo obrigatório';
          isValid = false;
        } else {
          validations[field] = '';
        }

        if (field === 'email') {
          const emailRegex = /\S+@\S+\.\S+/;
          if (value && !emailRegex.test(value)) {
            validations.email = 'E-mail inválido';
            isValid = false;
          }
        }

        if (field === 'name') {
          if (value && value.length > 27) {
            validations[field] = 'Nome muito longo.'
          }
        }
      }

      if (field = 'contributionTime') {
        if (!this.selectedCalculation.contributionTimeYears && !this.selectedCalculation.contributionTimeMonths && !this.selectedCalculation.contributionTimeDays) {
          validations.contributionTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.contributionTime = '';
        }
      }

      if (field = 'teachingTime') {
        if (!this.selectedCalculation.teachingTimeYears && !this.selectedCalculation.teachingTimeMonths && !this.selectedCalculation.teachingTimeDays) {
          validations.teachingTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.teachingTime = '';
        }
      }

      if (field = 'carreerTime') {
        if (!this.selectedCalculation.carreerTimeYears && !this.selectedCalculation.carreerTimeMonths && !this.selectedCalculation.carreerTimeDays) {
          validations.carreerTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.carreerTime = '';
        }
      }

      if (field = 'publicServiceTime') {
        if (!this.selectedCalculation.publicServiceTimeYears && !this.selectedCalculation.publicServiceTimeMonths && !this.selectedCalculation.publicServiceTimeDays) {
          validations.publicServiceTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.publicServiceTime = '';
        }
      }

      if (field = 'positionTime') {
        if (!this.selectedCalculation.positionTimeYears && !this.selectedCalculation.positionTimeMonths && !this.selectedCalculation.positionTimeDays) {
          validations.positionTime = 'Campo obrigatório';
          isValid = false;
        } else {
          validations.positionTime = '';
        }
      }

      this.validations = { ...validations };
      return isValid;
    },

    formValidation() {
      let isValid = true;
      const fields = [
        'address',
        'birthDate',
        'city',
        'currentProfession',
        'email',
        'gender',
        'mainPhoneNumber',
        'maritalStatus',
        'name',
        'secondaryPhoneNumber',
        'state',
        'calculationDate',
        'zipcode',
        'publicServiceTime',
        'teachingTime',
        'carreerTime',
        'positionTime',
        'contributionTime',
        'publicServiceIngressDate',
      ]

      fields.forEach((field) => {
        if (!this.inputValidation(field)) {
          isValid = false;
        }
      });

      return isValid;
    },
    async deleteCalculation(item) {
      const result = await this.$swal.fire({
        title: `Remover ${item.name || 'este cálculo'}?`,
        text: "Esta ação não poderá ser desfeita!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode remover!'
      });

      if (result.isConfirmed) {
        if (this.$route.params.calculationId && this.$route.params.calculationId === item.id) {
          this.$router.push({ name: 'customer', params: { id: this.customerId }})
        }

        await deleteCalculation(item.id, this.customerId);
        this.$swal.fire(
          'Pronto!',
          `${item.name || 'O cálculo'} foi removido com sucesso!`,
          'success'
        )
        this.updateList();
      }
    },
    closeCalculation() {
      this.selectedCalculation = null;
      this.isEditing = false;
    },
    openCopyModal(calculation) {
      if (!calculation) return;

      this.copiedCalculation = {
        ...this.copiedCalculation,
        copiedName: calculation.name,
        id: calculation.id,
      }

      this.$refs['copy-calculation-modal'].show();
    },
    closeCopyModal() {
      this.$refs['copy-calculation-modal'].hide();

      this.copiedCalculation = {
        id: null,
        copiedName: '',
        name: '',
        selectedData: ['time', 'salary']
      };
    },
    async submitCopyModal() {
      if (!this.copiedCalculation) return;

      const data = { 
        calculationId: this.copiedCalculation.id, 
        name: this.copiedCalculation.name, 
        copiedData: this.copiedCalculation.selectedData,
        // customerId: this.customerId,
      };
      await copyCalculation(data);

      this.closeCopyModal()
      this.updateList();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    }
  }
}
</script>

<style lang="scss">
.list-calculations-wrapper {
  .table.b-table {
    margin-bottom: 0;

    & > thead > tr th {
      border: none;
    }

    td, th {
      vertical-align: middle;
      display: table-cell;
      padding: 1rem 1.5rem;
    }
  }
}
</style>
