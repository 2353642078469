<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0" v-if="record">Editar cálculo #{{record.id}}</h5>
        <h5 class="mb-0" v-else>Criar cálculo</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="Nome do cálculo"
            label-for="calculation-name"
          >
            <b-form-input
              id="calculation-name"
              v-model="form.name"
              placeholder="Informe um nome"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-4">
          <b-form-group
            label="Dt. Criação"
            label-for="create-date"
          >
            <InputDatePicker v-model="form.createdAt" />
          </b-form-group>
        </div>
        
        <div class="col-md-4">
          <b-form-group
            label="Dt. Para Atualização"
            label-for="create-date"
          >
            <InputDatePicker v-model="form.calculationDate" />
          </b-form-group>
        </div>

        <div class="col-md-4">    
          <b-form-group
            label="Dt. Ingresso no Serv. Público"
          >
            <InputDatePicker v-model="form.publicServiceIngressDate" v-on:blur="inputValidation('publicServiceIngressDate')" />
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group
            label="Regime Previdência"
          >
            <b-form-select
              size="sm"
              id="inline-form-custom-select-pref"
              class="mb-2 mr-sm-2 mb-sm-0 col-sm-12"
              :options="[{ text: 'Escolha...', value: null }, 'Regime 1', 'Regime 1']"
              v-model="form.type"
            />
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group
            label="Tempo Serviço Público"
          >
            <input
              type="number"
              min="0"
              class="form-control form-control-sm"
              v-model="form.publicServiceTime"
              v-on:blur="inputValidation('publicServiceTime')"
            />
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group
            label="Tempo Carreira"
          >
            <input
              type="number"
              min="0"
              class="form-control form-control-sm"
              v-model="form.carreerTime"
              v-on:blur="inputValidation('carreerTime')"
            />
          </b-form-group>
        </div>

        <div class="col-md-3">
          <b-form-group
            label="Tempo Cargo"
          >
            <input type="number" min="0" class="form-control form-control-sm" v-model="form.positionTime" v-on:blur="inputValidation('positionTime')" />
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="Tempo de Magistério"
          >
            <div class="row">
              <div class="col-6">
                <input type="number" min="0" class="form-control form-control-sm" v-model="form.teachingTimeYears" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input type="number" min="0" class="mt-1 form-control form-control-sm" v-model="form.teachingTimeMonths" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input type="number" min="0" class="mt-1 form-control form-control-sm" v-model="form.teachingTimeDays" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
          </b-form-group>
        </div>
        
        <div class="col-md-6">
          <b-form-group
            label="Tempo de Contribuição"
          >
            <div class="row">
              <div class="col-6 text-gray-500 font-italic">
                <input type="number" min="0" class="form-control form-control-sm" v-model="form.contributionTimeYears" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Ano(s)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input type="number" min="0" class="mt-1 form-control form-control-sm" v-model="form.contributionTimeMonths" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Mês(es)</div>
            </div>

            <div class="row">
              <div class="col-6">
                <input type="number" min="0" class="mt-1 form-control form-control-sm" v-model="form.contributionTimeDays" />
              </div>
              <div class="col-6 text-gray-500 font-italic">Dia(s)</div>
            </div>
          </b-form-group>
        </div>

      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="save"
      >
        Salvar
      </b-button>

      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
import InputDatePicker from '@/components/InputDatePicker.vue';
import moment from 'moment';

export default {
  name: 'ModalWorkRecordsForm',

  components: {
    InputDatePicker,
  },

  props: {
    record: {
      type: Object,
    },
    parentId: {
      type: String,
    },
  },

  data: () => ({
    isLoading: false,
    isSaving: false,
    modalShow: true,
    form: {
      companyName: '',
      state: '',
      city: '',
      ingressDate: '',
      egressDate: '',
      isPublicService: false,
      isTeaching: false,
      expiredVacations: false,
      expiredVacations: 0,
      hasExpiredPremiumLicenses: false,
      expiredPremiumLicenses: 0,
    },
  }),

  created() {
    if (this.record) {
      this.form = {
        ...this.record,
        ingressDate: this.record.ingressDate ? moment(this.record.ingressDate).format('DD/MM/YYYY') : '',
        egressDate: this.record.egressDate ? moment(this.record.egressDate).format('DD/MM/YYYY') : '',
      };
    }
  },

  methods: {
    inputValidation(field) {
      // this.$v.form[field].$touch();
    },
    async save() {
      this.isLoading = true;
      this.isSaving = true;

      try {
        await this.saveOrUpdate();

        this.$toast.open({
          message: 'Registro salvo com sucesso',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('save');
        this.$emit('hidden');
      } catch (error) {
        this.$toast.open({
          message: 'Erro ao salvar',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.isSaving = false;
        this.isLoading = false;
      }
    },

    saveOrUpdate() {
      const { form } = this;
      const body = { workRecord: form };

      form.calculationId = this.parentId;

      if (form.id) {
        return axios.put(`/work-records/${form.id}`, body);
      } else {
        return axios.post('/work-records', body);
      }
    },
  }
}
</script>

<style scoped>
</style>
