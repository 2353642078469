<template>
  <div class="home mt-5">
    <b-container class="list-indexes-wrapper">
      <b-card no-body class="mt-3 shadow-sm">
        <b-row class="p-2">
          <b-col lg="12" class="my-1">
            <span>Data:</span>
            <vue-monthly-picker 
              v-model="form.date" 
              placeHolder="MM/YYYY"
              dateFormat="MM/YYYY"
            />
          </b-col>
          <b-col lg="6" class="my-1">
            <button class="btn btn-primary text-uppercase" @click="filterUpdateIndex">Filtrar</button>
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="mt-3 shadow-sm">
        <b-row class="p-2">
          <b-col lg="12" class="my-1">
            <span>Salário Minimo:</span>
            <input type="number" min="0" class="mt-1 form-control form-control-sm" v-model="minSalary">
          </b-col>
        </b-row>
      </b-card>

      <b-card no-body class="mt-3 shadow-sm">
        <input type="file" @change="importIndexes">
        <button
          class="bg-transparent w-100 text-center border border-dashed pb-10 text-gray-600"
          @click="saveIndexes"
          :disabled="processing"
        >
          Importar Índices / Atualizar Salário Minimo
        </button>
        <div class="text-center text-default my-2" v-if="errorText">
          {{ errorText }}
        </div>
        <div class="text-center text-default my-2" v-if="processing">
          <b-spinner class="align-middle"></b-spinner> Importando...
        </div>
      </b-card>

      <b-card no-body class="mt-3 shadow-sm">
        <b-table 
          :items="items" 
          :fields="fields" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter"
          :filter-included-fields="filterOn" 
          :sort-by.sync="sortBy" 
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" 
          :busy="isLoading" 
          stacked="md" 
          show-empty 
          small 
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-default my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Carregando...</strong>
            </div>
          </template>
        </b-table>
      </b-card>

      <div class="d-flex justify-content-center mt-3">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="sm"
          class="my-0"></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>
import VueMonthlyPicker from 'vue-monthly-picker'
import { getUpdateIndex, updateUpdateIndex } from '@/services/updateIndex';
import readXlsxFile from 'read-excel-file';
import moment from 'moment';

export default {
  name: 'Indexes',
  components: { VueMonthlyPicker },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        {
          key: 'month', 
          label: 'Mês',
          align: 'center',
          sortable: true,
          sortDirection: 'desc',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'year', 
          label: 'Ano',
          sortable: true,
          sortDirection: 'desc',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'index',
          label: 'Index',
          sortable: true,
          sortDirection: 'desc',
          thClass: 'text-center',
          tdClass: 'text-center',
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      file: null,
      processing: null,
      errorText: "",
      form: {
        date: "",
      },
      minSalary: 0,
    }
  },
  computed: {
    sessionUser() { return this.$store.getters.sessionUser },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.load();
  },
  methods: {
    async load(query = {}) {
      this.isLoading = true;

      if (!query.date) {
        const now = moment();

        query.date = now.format("MM/YYYY");
      }

      const data = await getUpdateIndex(query);

      if (data.indexFoundInDate) {
        this.items = data.updateIndex;
      } else {
        this.items = [];
      }
      
      this.totalRows = this.items.length;
      this.minSalary = (this.totalRows > 0) ? this.items[0].min_salary : 0;

      this.isLoading = false;
    },
    async filterUpdateIndex() {
      if (this.form.date) {
        await this.load({ date: this.form.date.format("MM/YYYY") });
      } else {
        await this.load();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async importIndexes(e) {
      this.errorText = "";

      if (e.target.files[0]) {
        this.file = e.target.files[0];
        return;
      }

      this.file = null;
    },
    async saveIndexes() {
      if (!this.form.date) {
        this.$toast.open({
          message: 'Por favor, escolha uma data para os índices importados.',
          type: 'error',
          position: 'top-right'
        });
        return;
      }

      // if (this.file) {
      //   try {
      //     const months = {
      //       'jan': '01',
      //       'fev': '02',
      //       'mar': '03',
      //       'abr': '04',
      //       'mai': '05',
      //       'jun': '06',
      //       'jul': '07',
      //       'ago': '08',
      //       'set': '09',
      //       'out': '10',
      //       'nov': '11',
      //       'dez': '12'
      //     };

      //     readXlsxFile(this.file).then(async (rows) => {
      //       this.processing = true;

      //       let columns = [];
      //       for (const row of rows) {
      //         for (let i = 0; i < row.length; i++) {
      //           const item = row[i];

      //           if (columns[i]) {
      //             columns[i] = [...columns[i], item];
      //           } else {
      //             columns[i] = [item];
      //           }
      //         }
      //       }

      //       let indexes = [];
      //       for (const column of columns) {
      //         if (column[0] === "COMP.") {
      //           continue;
      //         }

      //         let date = '';

      //         for (let i = 0; i < column.length; i++) {
      //           const item = column[i];

      //           if (i === 0) {
      //             const monthString = item.split('/')[0];
      //             const monthNumber = months[monthString];

      //             date = item.replace(monthString, monthNumber);
      //           }

      //           if (i >= 2) {
      //             const indexDate = moment(columns[0][i]).add(1, 'day').format('MM/YYYY').split("/");
      //             const month = indexDate[0];
      //             const year = indexDate[1];

      //             const index = item;

      //             const min_salary = 0;

      //             if (!index || index <= 1) break;

      //             indexes.push({ month, year, index, date, min_salary });
      //           }
      //         }
      //       }
            
      //       for (let i = 0; i < indexes.length; i += 1000) {
      //         const chunk = indexes.slice(i, i + 1000);

      //         await new Promise(resolve => setTimeout(resolve, 10000));

      //         updateUpdateIndex({ indexes: chunk });  
      //       }
            
      //       this.processing = false;
      //     });

      //     return;
      //   } catch (err) {
      //     this.processing = false;
      //     this.$toast.open({
      //       message: 'Erro ao processar arquivo.',
      //       type: 'error',
      //       position: 'top-right'
      //     });
      //     return;
      //   }
      // }

      if (this.file) {
        try {
          const indexDate = this.form.date.format("MM/YYYY");

          readXlsxFile(this.file).then(async (rows) => {
            this.processing = true;

            let indexes = [];
            for (const row of rows) {
              if (typeof row[1] === "object" && typeof row[2] === "number") {
                const index = row[2];

                const date = moment(row[1]).add(1, 'day').format('MM/YYYY').split("/");
                const month = date[0];
                const year = date[1];
                
                indexes.push({ month, year, index, date: indexDate, min_salary: this.minSalary });
              }
            }
            
            await updateUpdateIndex({ indexes });  
            this.processing = false;
          });

          return;
        } catch (err) {
          this.processing = false;
          this.$toast.open({
            message: 'Erro ao processar arquivo.',
            type: 'error',
            position: 'top-right'
          });
          return;
        }
      }

      if (!this.file && this.minSalary) {
        try {
          const indexDate = this.form.date.format("MM/YYYY");

          this.processing = true;
          await updateUpdateIndex({ indexes: [{ date: indexDate, min_salary: this.minSalary }] });  
          this.processing = false;

          return;
        } catch (err) {
          this.processing = false;
          this.$toast.open({
            message: 'Erro ao atualizar salário minimo.',
            type: 'error',
            position: 'top-right'
          });
          return;
        }
      }

    }
  }
}
</script>

<style lang="scss">
.list-indexes-wrapper {
  .table.b-table {
    margin-bottom: 0;

    &>thead>tr th {
      border: none;
    }

    td,
    th {
      vertical-align: middle;
      display: table-cell;
      padding: 1rem 1.5rem;
    }
  }
}
</style>
