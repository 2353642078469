import axios from 'axios';

export async function requestPasswordResetEmail(body = {}) {
  const response = await axios.post('/auth/forgot-password', body);
  return response.data;
}

export async function resetPassword(body = {}) {
  const response = await axios.post('/auth/reset-password', body);
  return response.data;
}
