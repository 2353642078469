<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0" v-if="record">Adições - Registro #{{record.id}}</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row">
        <div class="mt-3 col-md-5">
          <b-form-group
            label="Dias de licença-prêmio não usufruidas:"
            label-for="days"
          />
        </div>
        <div class="mt-2 d-flex col-md-5">
          <b-form-input
            placeholder="Dias"
            id="days"
            type="number"
            min="0"
            v-model="form.addition"
            required
          />
          <div>
            <div class="input-group-text">dias</div>
          </div>
        </div>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="save"
      >
        Aplicar
      </b-button>

      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
import InputDatePicker from '@/components/InputDatePicker.vue';
import moment from 'moment';

export default {
  name: 'ModalAdditionForm',

  components: {
    InputDatePicker,
  },

  props: {
    record: {
      type: Object,
    },
    parentId: {
      type: String,
    },
  },

  data: () => ({
    isLoading: false,
    isSaving: false,
    modalShow: true,
    form: {
      addition: 0,
    },
  }),

  created() {
    if (this.record) {
      this.form = { ...this.record };
    }
  },

  methods: {
    async save() {
      this.isLoading = true;
      this.isSaving = true;

      try {
        await this.saveOrUpdate();

        this.$toast.open({
          message: 'Registro salvo com sucesso',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('save');
        this.$emit('hidden');
      } catch (error) {
        this.$toast.open({
          message: 'Erro ao salvar',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.isSaving = false;
        this.isLoading = false;
      }
    },

    saveOrUpdate() {
      const body = { workRecord: this.form };

      if (this.form.id) {
        return axios.put(`/work-records/${this.form.id}`, body);
      } else {
        return axios.post('/work-records', body);
      }
    },
  }
}
</script>

<style scoped>
</style>
