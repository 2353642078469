<template>
  <div class="users">
    <main role="main" class="container">
      <div class="my-3 p-3 bg-white rounded shadow-sm">
        <h3 class="border-bottom border-gray pb-2 mb-0">
          <!-- Usuários -->
        </h3>
        <router-link :to="{ name: 'edit-user', params: { id: 'new'} }">
          <button class="btn btn-primary ml-3 float-right">
            <i class="fa fa-user-plus mr-2"></i>  Novo Usuário
          </button>
        </router-link>
        <table class="table">
          <thead>
            <tr>
              <!-- <th scope="col">#</th> -->
              <th scope="col">Nome</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in allUsers" v-bind:key="user.id">
              <!-- <th scope="row">{{user.id}}</th> -->
              <!-- <th><UserAvatar /></th> -->
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.active ? 'Ativo' : 'Inativo' }}</td>
              <td>
                <!-- <button class="btn btn-sm btn-warning ml-2" @click="deleteUser(user.id)"> -->
                <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
                  <button class="btn btn-sm btn-warning ml-2">
                    <i class="fa fa-pencil"></i>
                  </button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Users',
  computed: mapGetters(['allUsers']),
  methods: {
    ...mapActions(['fetchUsers', 'deleteUser']),
  },
  components: {},
  created() {
    this.fetchUsers();
  }
}
</script>

<style scoped>
.btn-actionss {}
</style>
