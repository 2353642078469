import axios from 'axios';
import { resourceModule } from '../RestResource/';

const httpClient = axios.create({
  baseURL: 'http://localhost:3000/',
});

const config = {
  name: 'activities',
  relations: ['employees'],
  httpClient
};

export default resourceModule(config);