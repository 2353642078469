<template>
  <div class="col-md-6 mb-3">
    <div class="video-item bg-white border shadow-sm">
      <div class="position-relative">
        <iframe
          :src="videoUrl"
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen class="help__video"
          style="width:560px;height:315px;" title="Boas vindas mapas mentais"></iframe>
        </div>
        <!-- <img class="w-100" v-bind:src="item.imgCover" alt="Video cover"> -->
      </div>
      <h4 class="font-weight-bold text-center mb-0 text-gray-600 p-3">{{item.title}}</h4>
      <h6 class="px-3 pb-3">{{item.description}}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoItem',

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    videoUrl() {
      return `https://player.vimeo.com/video/${this.item.videoUrl}?h=85bf98246a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-item {
    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 4rem;
      cursor: pointer;
      transition: all 0.5s ease;

      &:hover {
        color: #00bcd4;
      }
    }
  }
</style>
