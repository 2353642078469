<template>
  <div class="border bg-white-blue rounded border-info border-left-4 mb-3">
    <div class="p-2 border-bottom d-flex align-items-center">
      <div>
        <h6 class="mb-1 text-gray-600 font-weight-bold">
          <i class="fa fa-calculator mr-1" aria-hidden="true"></i> {{item.name}}
        </h6>
        <!-- <h6 class="mb-0 text-gray-500 small" v-if="item.ingressDate && item.egressDate">
          Período de {{ duration }}
        </h6> -->
      </div>

      <button
        class="btn btn-link p-0 btn-sm ml-auto"
        title="Editar"
        v-on:click="$emit('edit', item)"
      >
        <i class="fa fa-edit" aria-hidden="true"></i>
      </button>

      <button
        class="btn btn-link text-danger p-0 btn-sm ml-4"
        title="Remover"
        v-on:click="$emit('remove', item)"
      >
        <i class="fa fa-trash" aria-hidden="true"></i>
      </button>
    </div>

    <div>
      <div class="row align-items-center">
        <div class="col-md-3 border-right">
          <div class="p-2">
            <h6 class="text-uppercase text-gray-600 small">Ingresso</h6>
            <h6 class="text-info mb-0">
              <i class="fa fa-calendar-check-o mr-1" aria-hidden="true"></i>
              {{item.ingressDate | moment('DD/MM/YYYY')}}
            </h6>
          </div>
        </div>
        <div class="col-md-3 border-right">
          <div class="p-2">
            <h6 class="text-uppercase text-gray-600 small">Encerramento</h6>
            <h6 class="text-info mb-0">
              <i class="fa fa-calendar-times-o mr-1" aria-hidden="true"></i>
              {{item.egressDate | moment('DD/MM/YYYY')}}
            </h6>
          </div>
        </div>

        <div class="col-md-6">
          <div class="p-2">
            <div
              class="d-flex align-items-center mb-1"
              v-if="fillPercentage <= 100"
            >
              <h6 class="text-uppercase text-gray-600 small mb-0">Remunerações preenchidas</h6>

              <span
                class="text-uppercase small font-weight-bold ml-1 text-gray-600"
              >
                {{ fillNumber }} de {{ total }}
              </span>

              <span
                class="ml-1 font-weight-bold small"
                v-bind:class="completed ? 'text-info': 'text-secondary'"
              >
                ({{fillPercentage}}%)
              </span>

              <button
                class="btn btn-sm ml-auto px-1 py-0"
                v-bind:class="completed ? 'btn-outline-info': 'btn-outline-secondary'"
                v-on:click="$emit('addRemuneration', item)"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                Inserir
              </button>
            </div>
            <div class="d-flex align-items-center mb-1" v-else>
              <i class="fa fa-exclamation-triangle text-warning mr-1" aria-hidden="true"></i>
              <h6 class="text-error bold mb-0">Data de início e término inválidas</h6>
            </div>

            <b-progress
              v-if="fillPercentage <= 100"
              v-bind:value="fillPercentage"
              v-bind:max="100"
              v-bind:variant="completed ? 'info': 'secondary'"
              striped
              show-value
            ></b-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'CalculationItem',

  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  computed: {
    salaries() {
      return this.item.salaries;
    },

    total() {
      const start = moment(this.item.ingressDate);
      const end = moment(this.item.egressDate);
      let months = 0;

      while (start.isSameOrBefore(end)) {
        months++;
        start.add(1, 'month');
      }

      return months;
    },

    fillNumber() {
      const { salaries } = this;
      if (!salaries) return 0;
      return salaries.filter((s) => s.value).length;
    },

    fillPercentage() {
      const { salaries, total, fillNumber } = this;
      if (!salaries) return 0;
      return Math.ceil((fillNumber / total) * 100);
    },

    completed() {
      return this.fillPercentage === 100;
    },

    duration() {
      const { ingressDate, egressDate } = this.item;
      // using moment duration
      const duration = moment.duration(moment(egressDate).diff(moment(ingressDate)));
      const years = duration.years();
      const months = duration.months();

      return `${years} ano${years > 1 ? 's' : ''} e ${months} mês${months > 1 ? 'es' : ''}`;
    },
  },
}
</script>

<style scoped>
</style>
