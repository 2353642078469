<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="xl"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0">Remunerações</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row" v-if="!loadingRemunerations">
        <div class="col-lg-8 mb-3 border-right">
          <div class="text-left ml-4">
            <!-- <span class="font-weight-bold">Orientações para uso da tabela</span>: -->
            <!-- pegar índices de atualização no site <a href="http://gov.br" target="_blank">gov.br</a>, conforme o mês do cálculo. Incluir remunerações do segurado, conforme contracheques. -->
            <span class="font-weight-bold">Informação:</span>
            <br>
            1. Índice de atualização do <a href="http://gov.br" target="_blank">gov.br</a>, conforme o mês atual.
            <br>
            2. Incluir remunerações do segurado, conforme contracheques.
            <br>
            3. O cálculo preliminar será realizado com base nas remunerações informadas. Somente remunerações a partir de 07/1994 serão consideradas.
          </div>

          <b-card class="mt-3 mb-3 shadow-sm" no-body>
            <div class="p-3">
              <div class="row d-flex justify-content-start">
                <div class="col-md-5 mt-2">
                  <b-form-group
                    label="Valor do Salário:"
                    label-for="salary"
                  />
                </div>
                <div class="col-md-5">
                  <money class="form-control" v-model="form.salary" v-bind="moneyConfigs"></money>
                </div>
              </div>
              <div class="row d-flex justify-content-start">
                <div class="col-md-5 mt-2">
                  <b-form-group
                    label="Mês/Ano de Inicio:"
                    label-for="initialDate"
                  />
                </div>
                <div class="col-md-5">
                  <vue-monthly-picker 
                    v-model="form.initialDate" 
                    :min="minDate"
                    :max="maxDate"
                    placeHolder="MM/YYYY"
                    dateFormat="MM/YYYY"
                  />
                </div>
              </div>
              <div class="row d-flex justify-content-start">
                <div class="col-md-5 mt-2">
                  <b-form-group
                    label="Repetir mesmo Valor até (Mês/Ano):"
                    label-for="finalDate"
                  />
                </div>
                <div class="col-md-5">
                  <vue-monthly-picker 
                    v-model="form.finalDate" 
                    :min="minDate"
                    :max="maxDate"
                    placeHolder="MM/YYYY"
                    dateFormat="MM/YYYY"
                  />
                </div>
              </div>
              <div>
                <b-button
                  v-on:click="includeValues"
                  variant="primary"
                >
                  <b-spinner
                    v-if="includingValues"
                    small
                    variant="white"
                  ></b-spinner>
                  Incluir
                </b-button>
              </div>
            </div>
          </b-card>

          <div class="modal-remuneration-table">
            <table class="table table-bordered mt-2 modal-remuneration-table__table">
              <thead>
                <tr>
                  <th class="text-nowrap">Mês</th>
                  <th class="text-nowrap" style="min-width: 150px">Valor</th>
                  <th class="text-nowrap">Atualização</th>
                  <th class="text-nowrap text-right">Remuneração atualizada</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in rows"
                  v-bind:key="index"
                >
                  <th>
                    {{row.month === '13' ? `${row.month}°` : row.month}}/{{row.year}}
                  </th>
                  <th>
                    <div class="d-flex align-items-center">
                      <money class="form-control" v-model="row.value" v-bind="moneyConfigs"></money>
                      <button
                        v-b-tooltip.hover
                        title="Repetir valor na linha abaixo"
                        class="btn btn-sm btn-link ml-1 px-0"
                        v-on:click="handlerCopyValue(row, index)"
                      >
                        <BIcon icon="arrow-down-circle-fill" size="sm" />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div class="d-flex align-items-center">
                      <input class="form-control mr-1" v-model="row.update" type="number" disabled /> %
                    </div>
                  </th>
                  <th class="text-right">
                    R$ {{updatedValue(row) || '0,00'}}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-4">
          <div>
            <h5>
              Progresso
              <span class="text-info font-weight-bold small">({{percentage}}%)</span>
            </h5>
            <div>
              <b-progress
                v-bind:value="percentage"
                v-bind:max="100"
                height="2rem"
                variant="info"
                show-value
                striped
              ></b-progress>
            </div>

            <div v-if="percentage < 30" class="d-flex align-items-center border bg-white-blue border-info mt-2 px-2 py-1">
              <i class="fa fa-info-circle text-info" aria-hidden="true"></i>
              <h6 class="mb-0 small text-gray-600 border-left ml-2 pl-2">*É necessário pelo menos <strong>30%</strong> para realizar o cálculo preliminar.</h6>
            </div>
            <button v-else class="btn btn-info w-100 mt-2">
              <i class="fa fa-calculator mr-1" aria-hidden="true"></i>
              CALCULAR PRÉVIA
            </button>
          </div>

          <div class="mt-4">
            <div class="border border-info bg-white-blue rounded">
              <!-- <h6 class="mb-0 font-weight-bold p-2 border-bottom text-gray-700">Valor do benefício</h6> -->
              <div>
                <div class="border-bottom p-2">
                  <div class="row">
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-gray-600 small">Média preliminar?</h6>
                    </div>
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-lg-right text-info">R$ {{formatMoney(average)}}</h6>
                    </div>
                  </div>
                </div>

                <div class="border-bottom p-2">
                  <div class="row">
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-gray-600 small">Tempo de contribuição?</h6>
                    </div>
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-lg-right text-info">{{contributionTime}}</h6>
                    </div>
                  </div>
                </div>

                <!-- <div class="border-bottom p-2">
                  <div class="row">
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-gray-600 small">Valor do benefício?</h6>
                    </div>
                    <div class="col-lg-6">
                      <h6 class="font-weight-bold mb-0 text-lg-right text-info">R$ 4.800,00</h6>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="p-4 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <h6 class="mb-0 mt-2 font-weight-bold text-gray-600">Carregando informações...</h6>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        v-on:click="save"
        variant="primary"
      >
        <b-spinner
          v-if="saving"
          small
          variant="white"
        ></b-spinner>
        Salvar e continuar depois
      </b-button>

      <b-button variant="outline-primary" @click="cancel()">
        Fechar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BIcon } from 'bootstrap-vue'
import InputDatePicker from '@/components/InputDatePicker.vue';
import VueMonthlyPicker from 'vue-monthly-picker'
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'ModalRemunerationForm',
  components: { BIcon, InputDatePicker, VueMonthlyPicker },
  props: {
    workRecord: {
      type: Object,
      required: true,
    },
    updateIndex: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.loadRemunerations();
  },

  data: () => ({
    currentIndex: 0.9968,
    modalShow: true,
    rows: [],
    remunerations: [],
    saving: false,
    loadingRemunerations: false,
    moneyConfigs: {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false,
    },
    form: {
      salary: 0,
      initialDate: '',
      finalDate: '',
    },
    includingValues: false,
    selectedMonth: ''
  }),

  computed: {
    minDate() {
      return moment(this.workRecord.ingressDate).startOf('month');
    },

    maxDate() {
      return moment(this.workRecord.egressDate).endOf('month');
    },

    average() {
      const { salaries } = this;
      if (salaries) {
        const total = salaries.reduce((partialSum, a) => partialSum + a.updatedValue, 0);
        if (salaries.length) {
          return total / salaries.length;
        }
      }

      return 0;
    },

    percentage() {
      const total = this.rows.length;
      const completed = this.rows.filter(row => row.value).length;

      return completed ? Math.round(completed / total * 100) : 0;
    },

    salaries() {
      return this.workRecord.salaries;
    },

    contributionTime() {
      const { rows } = this
      const totalMonths = rows.length;
      const years = parseInt(totalMonths / 12);
      const months = totalMonths % 12;

      const yearLabel = years > 1 ? 'anos' : 'ano';
      const monthLabel = months > 1 ? 'meses' : 'mês';
      const totalMonthLabel = totalMonths > 1 ? 'meses' : 'mês';

      if (years) {
        if (months) {
          return `${years} ${yearLabel} e ${months} ${monthLabel}`;
        } else {
          return `${years} ${yearLabel}`;
        }
      } else {
        return `${totalMonths} ${totalMonthLabel}`;
      }
    },
  },

  methods: {
    includeValues() {
      this.includingValues = true;

      const { salary, initialDate, finalDate } = this.form;
      const initial = moment(initialDate);
      const final = moment(finalDate);

      const { ingressDate, egressDate } = this.workRecord;
      const { updateIndex } = this;
      const start = moment(ingressDate);
      const end = moment(egressDate);
      const months = [];

      while (start.isSameOrBefore(end)) {
        months.push(start.clone());
        start.add(1, 'month');
      }

      this.rows = months.map((date) => {
        const isUpdated = (date.isBetween(initial, final, null, '[]'));

        if (isUpdated) {
          const currentUpdateIndex = updateIndex ? updateIndex.find(i => (
            i.month === date.format('MM')
            && i.year === date.format('YYYY')
          )) : {};

          const value = salary;
          const update = currentUpdateIndex?.index || 1;
          const updatedValue = value * update;
          const month = date.format('MM');
          const year = date.format('YYYY');

          // if before 07/1994, return
          if (date.isBefore('1994-07-01')) return;

          return { month, year, value, update, updatedValue };
        } else {
          const currentValue = this.rows.find(r => (
            r.month === date.format('MM')
            && r.year === date.format('YYYY')
          ));

          return currentValue;
        }
      });

      // remove undefined from this.rows
      this.rows = this.rows.filter(row => row);

      const uniqueYears = [...new Set(this.rows.map(row => row.year))];

      uniqueYears.forEach(year => {
        const thirteenthMonthRemuneration = this.rows.find(r => r.month === '13' && r.year === year);

        if (thirteenthMonthRemuneration) {
          const currentUpdateIndex = updateIndex ? updateIndex.find(i => (
            i.month === '12' && i.year === year
          )) : {};

          const value = thirteenthMonthRemuneration.value;
          const update = currentUpdateIndex?.index || 1;
          const updatedValue = value * update;

          this.rows.push({
            month: '13',
            year,
            value,
            update,
            updatedValue
          });
        } else {
          const decemberRow = this.rows.find(row => row.month === '12' && row.year === year);

          if (decemberRow) {
            const thirteenthMonthRow = {
              ...decemberRow,
              month: '13'
            };
            this.rows.push(thirteenthMonthRow);
          }
        }
      });

      this.rows.sort((a, b) => {
        if (a.year === b.year) {
          return parseInt(a.month, 10) - parseInt(b.month, 10);
        }
        return parseInt(a.year, 10) - parseInt(b.year, 10);
      });

      this.form.salary = 0;

      this.includingValues = false;
    },

    handlerCopyValue(row, index) {
      const { rows } = this;
      const previousRow = rows[index + 1];
      if (previousRow) {
        previousRow.value = row.value;
      }
    },

    updatedValue(record) {
      const value = record.value || 0;
      const update = record.update || 1;
      const total = value * update;

      record.updatedValue = total;

      return this.formatMoney(total);
    },

    buildMonthRows() {
      const { ingressDate, egressDate } = this.workRecord;
      const { remunerations, updateIndex } = this;
      const start = moment(ingressDate);
      const end = moment(egressDate);
      const months = [];

      while (start.isSameOrBefore(end)) {
        months.push(start.clone());
        start.add(1, 'month');
      }

      this.rows = months.map((date) => {
        // merge remunerations
        const remuneration = remunerations ? remunerations.find(r => (
          r.month === date.format('MM')
          && r.year === date.format('YYYY')
        )) : {};

        const currentUpdateIndex = updateIndex ? updateIndex.find(i => (
          i.month === date.format('MM')
          && i.year === date.format('YYYY')
        )) : {};

        const value = remuneration?.value;
        const update = currentUpdateIndex?.index || 1;
        const updatedValue = value * update;
        const month = date.format('MM');
        const year = date.format('YYYY');

        // if before 07/1994, return
        if (date.isBefore('1994-07-01')) return;

        return { month, year, value, update, updatedValue };
      });

      // remove undefined from this.rows
      this.rows = this.rows.filter(row => row);

      const uniqueYears = [...new Set(this.rows.map(row => row.year))];

      uniqueYears.forEach(year => {
        const thirteenthMonthRemuneration = remunerations ? remunerations.find(r => r.month === '13' && r.year === year) : null;

        if (thirteenthMonthRemuneration) {
          const currentUpdateIndex = updateIndex ? updateIndex.find(i => (
            i.month === '12' && i.year === year
          )) : {};

          const value = thirteenthMonthRemuneration.value;
          const update = currentUpdateIndex?.index || 1;
          const updatedValue = value * update;

          this.rows.push({
            month: '13',
            year,
            value,
            update,
            updatedValue
          });
        } else {
          const decemberRow = this.rows.find(row => row.month === '12' && row.year === year);

          if (decemberRow) {
            const thirteenthMonthRow = {
              ...decemberRow,
              month: '13'
            };
            this.rows.push(thirteenthMonthRow);
          }
        }
      });

      this.rows.sort((a, b) => {
        if (a.year === b.year) {
          return parseInt(a.month, 10) - parseInt(b.month, 10);
        }
        return parseInt(a.year, 10) - parseInt(b.year, 10);
      });
    },

    loadRemunerations() {
      this.remunerations = this.salaries;
      this.buildMonthRows();
    },

    formatMoney(value, digits) {
      const d = digits !== undefined ? digits : 2;
      const nf = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      });
      return nf.format(value);
    },

    async save() {
      if (this.saving) { return; }

      this.saving = true;

      try {
        const salaries = this.rows.map((row) => ({ ...row, update: Number(row.update) }))
        await axios.put(`/work-records/${this.workRecord.id}`, {
          workRecord: {
            // ...this.workRecord,
            ingressDate: this.workRecord.ingressDate,
            egressDate: this.workRecord.egressDate,
            salaries: salaries,
          },
        });

        this.$toast.open({
          message: 'Registros salvos com sucesso',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('save');
      } catch (error) {
        this.$toast.open({
          message: 'Erro ao salvar',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.saving = false;
      }
    }
  },
}
</script>

<style lang="scss">
.modal-remuneration-table {
  max-height: 60vh;
  overflow-y: auto;

  &__table {
    margin-bottom: 0;

    td, th {
      vertical-align: middle !important;
      display: table-cell !important;
      padding: 0.5rem 1.5rem !important;
    }
  }
}
</style>
