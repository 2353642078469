<template>
  <div class="user-edit" v-if="user">
    <div class="card border-0">
      <b-container fluid="sm">
        <h3 class="border-bottom border-gray pb-2 pt-3">
          <span v-if="isNewUser">Criar</span><span v-else>Editar</span> Usuário
        </h3>

        <b-form @submit.prevent="submitForm">
          <b-form-group label="Nome:" label-for="name">
            <b-form-input id="name" v-model="user.name" required></b-form-input>
          </b-form-group>
          <b-form-group label="E-mail:" label-for="email">
            <b-form-input id="email" v-model="user.email" type="email" required :disabled="!isManagement"></b-form-input>
          </b-form-group>
          <b-form-group label="Senha:" label-for="password">
            <b-form-input id="password" v-model="user.password" type="password" required></b-form-input>
          </b-form-group>
          <b-form-group label="Confirmar Senha:" label-for="confirmPassword">
            <b-form-input id="confirmPassword" v-model="user.confirmPassword" type="password" required></b-form-input>
          </b-form-group>
          <b-form-group v-if="isManagement"  label="Status:" label-for="active">
            <b-form-checkbox id="active" v-model="user.active" switch>
              Ativo
            </b-form-checkbox>
          </b-form-group>

          <h5>Relatório Genial:</h5>
          <b-form-group label="Logotipo (Cabeçalho):" label-for="logo">
            <input type="file" accept="image/*" @change=changeLogo>
            <button v-if="user.logo" class="btn btn-primary ml-3" @click="removeLogo">Remover Logo</button>
            <div class="mb-2 logo" v-if="logoPreview || user.logo">
              <img :src="logoPreview || user.logo" />
            </div>
          </b-form-group>
          <b-form-group label="Escritório (Rodapé):" label-for="office">
            <b-form-input id="office" v-model="user.office"></b-form-input>
          </b-form-group>

          <b-form-group>
            <h5 class="mb-3">Plano {{ plan.id ? plan.name : 'não encontrado' }} {{ expirationDate ? `- Vigente até ${expirationDate}` : '' }}</h5>
            <h5 v-if="isManagement">Tipo de Pagamento: {{ planType }}</h5>
            <button type="button" class="btn btn-primary" @click="openCancelSubscriptionModal" v-if="plan.type === 'SUBSCRIPTION'">Cancelar Assinatura {{allowRefund ? 'e Pedir Reembolso' : ''}}</button>
          </b-form-group>

          <b-row align-h="end" >
            <b-col cols="3">
              <router-link :to="{ name: isManagement ? 'list-users' : 'home' }" class="btn btn-outline-primary ml-3 float-right">
                Voltar
              </router-link>
              <button type="button" class="btn btn-primary ml-3 float-right" @click="save">Salvar</button>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <ModalCancelSubscription
        v-if="showCancelSubscriptionModal"
        v-bind:plan="plan"
        v-bind:subscriptionId="subscription.id"
        v-bind:expirationDate="expirationDate"
        v-bind:allowRefund="allowRefund"
        v-on:hidden="handlerCloseModal"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ModalCancelSubscription from '@/components/ModalCancelSubscription';
import UserService from '@/services/UserService';
import { getSubscription, getSubscriptionADM } from '@/services/subscriptions';
import { getPlan } from '@/services/plans';
import { mapGetters } from 'vuex';

export default {
  components: { ModalCancelSubscription },

  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        active: false,
        office: '',
        logo: '',
      },
      logoFile: '',
      logoPreview: '',
      plan: {},
      subscription: {},
      showCancelSubscriptionModal: false
    };
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters(['isManagement']),

    planType() {
      switch (this.plan?.type) {
        case 'CREDIT_CARD':
          return 'Cartão de Crédito';
        case 'PIX':
          return 'PIX';
        case 'SUBSCRIPTION':
          return 'Assinatura';
        default: 
          return 'Não encontrado';
      }
    },

    isNewUser() {
      return this.$route.params.id === 'new';
    },

    expirationDate() {
      return this.user.expirationAsaasSub ? moment(this.user.expirationAsaasSub).format('DD/MM/YYYY') : null;
    },

    allowRefund() {
      return this.plan.type === 'PIX' && this.user.trialAsaasExpiration ? moment(this.user.trialAsaasExpiration).isAfter(moment()) : false;
    }
  },

  methods: {
    changeLogo(e) {
      const image = e.target.files[0];

      this.logoFile = image;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
        this.logoPreview = e.target.result;
      };
    },

    removeLogo() {
      this.user.logo = '';
      this.logoFile = '';
    },

    async fetchData() {
      if (!this.isNewUser) {
        const { user } = await UserService.findById(this.$route.params.id)
        this.user = {
          ...this.user,
          ...user
        };
      }

      // let subscription = {};

      // try {
      //   if (this.isManagement) {
      //     subscription = await getSubscriptionADM(this.user.id);
      //   } else {
      //     subscription = await getSubscription();
      //   }
      // } catch (err) {}

      // this.subscription = subscription;

      if (this.user.planId) {
        const plan = await getPlan(this.user.planId);
        this.plan = plan;
      }
    },

    async save() {
      try {
        // const data = {
        //   ...this.user,
        //   active: this.user.active ? 1 : 0,
        // };

        let data = new FormData();

        data.append('name', this.user.name); 
        data.append('email', this.user.email); 
        data.append('password', this.user.password); 
        data.append('active', this.user.active ? 1 : 0); 
        data.append('office', this.user.office); 

        if (this.logoFile) {
          data.append('file', this.logoFile); 
        } else {
          data.append('logo', this.user.logo); 
        }

        if (this.isNewUser) {
          await UserService.create(data);

          this.$swal.fire('Pronto!',
          `Usuário criado com sucesso!`,
          'success');

          this.$router.push({ name: 'list-users' });
        } else {
          if (data.password) {
            if (!data.confirmPassword || (data.confirmPassword !== data.password)) {
              this.$swal.fire({
                icon: 'error',
                title: 'Falha ao salvar usuário',
                text: 'Senha e Confirmação de Senha devem ser iguais',
              });

              return;
            }
          }

          const { user } = await UserService.update(this.user.id, data);

          this.user = { ...this.user, ...user };

          this.$swal.fire('Pronto!',
          `Usuário atualizado com sucesso!`,
          'success');
        }
      } catch (e) {
        return this.$swal.fire({
          icon: 'error',
          title: 'Falha ao salvar usuário',
          text: e.response.data.errorMessage,
        });
      }
    },

    openCancelSubscriptionModal() {
      this.showCancelSubscriptionModal = true;
    },

    handlerCloseModal() {
      this.showCancelSubscriptionModal = false;
    }
  },
}
</script>
<style>
  .logo {
    max-width: 250px;
    max-height: 250px;
  }
</style>