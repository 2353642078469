<template>
  <footer class="mt-5 pt-5 text-muted text-center text-small">
    <p class="mb-0">
      <Copyright class="text-white text-truncate" />
    </p>
    <!-- <ul class="list-inline">
      <li class="list-inline-item"><a href="" target="blank">Política de Privacidade</a></li>
      <li class="list-inline-item"><a href="" target="blank">Termos de uso</a></li>
      <li class="list-inline-item"><a href="" target="blank">Suporte</a></li>
    </ul> -->
  </footer>
</template>

<script>
import Copyright from '@/components/Copyright';
export default {
  components: { Copyright },
}
</script>
