<template>
  <div class="home mt-5">
    <b-container class="list-clients-wrapper">

      <b-card no-body class="mt-3 shadow-sm">
        <!-- button to create new account -->
        <button
          class=""
          @click="createAccount"
        >
          Nova conta
        </button>
      </b-card>
      <b-card no-body class="mt-3 shadow-sm">
        <b-table :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter"
          :filter-included-fields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :busy="isLoading" stacked="md" show-empty small @filtered="onFiltered">
          <template #table-busy>
            <div class="text-center text-default my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Carregando...</strong>
            </div>
          </template>

          <template #cell(name)="row">
            {{ row.value }}
          </template>

          <template #cell(licenceExpiryDate)="row">
            <p class="mb-0" v-if="isExpired(row.value)">
              EXPIRADO em {{ row.value | moment('DD/MM/YYYY') }}
            </p>
            <p class="mb-0" v-else>
              {{ row.value | moment('DD/MM/YYYY') }} - em {{ row.value | moment('from', true) }}
            </p>
          </template>

          <template #cell(status)="row">
            <p class="mb-0">{{row.value}}</p>
          </template>

          <template #cell(actions)="row">
            <span class="ml-2 btn btn-sm btn-outline-danger" title="Desativar" role="button" size="sm"
              @click="inactivateAccount(row.item)"
              v-if="row.item.status === 'ACTIVE'"
            >
              <i class="fa fa fa-users-slash"></i>
            </span>

            <span class="ml-2 btn btn-sm btn-outline-success" title="Ativar" role="button" size="sm"
              @click="activateAccount(row.item)"
              v-else
            >
              <i class="fa fa fa-users"></i>
            </span>
          </template>
        </b-table>
      </b-card>

      <div class="d-flex justify-content-center mt-3">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="sm"
          class="my-0"></b-pagination>
      </div>

      <!-- Info modal -->
      <b-modal :id="infoModal.id" :title="infoModal.title" size="lg" hide-footer @hide="resetInfoModal">
        <b-container>
          <b-col cols="12" align-self="stretch">
            <h2 class="text-left">Cálculo Simples - Servidores Públicos</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque porro obcaecati unde,
              maiores adipisci labore nulla deleniti corrupti qui
              maiores adipisci labore nulla deleniti corrupti qui.</p>
          </b-col>
          <router-link :to="{ path: 'calculations/new', query: { userId: infoModal.content.id } }"
            class="text-right float-right">
            &#x226A; Iniciar Novo Cálculo
          </router-link>
        </b-container>
        <br />
        <br />
        <b-container>
          <b-col cols="12" align-self="stretch">
            <h2 class="text-left">Cálculo Avançado - Servidores Públicos</h2>
            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Neque porro obcaecati unde,
              maiores adipisci labore nulla deleniti corrupti qui.</p>
          </b-col>
          <a class="text-right float-right">
            &#x226A; Em breve!
          </a>
        </b-container>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { getAccounts, inactivateAccount, createAccount, activateAccount } from '@/services/accounts'
export default {
  name: 'Accounts',
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        {
          key: 'name', label: 'Nome',
          sortable: true,
          sortDirection: 'desc'
        },
        {
          key: 'email',
          label: 'E-mail',
          sortable: true,
        },
        {
          key: 'licenceExpiryDate',
          label: 'Data de Expiração',
          sortable: true,
          sortDirection: 'desc',
        },
        {
          key: 'status',
          label: 'Status',
          formatter: (value, key, item) => {
            return value === 'ACTIVE'? 'Ativo' : 'Inativo'
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true
        },

        { key: 'actions', label: 'Ações', class: 'text-right' }

      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5, 10, 15, 50, 100],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      }
    }
  },
  computed: {
    sessionUser() { return this.$store.getters.sessionUser },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.load();
  },
  methods: {
    async createNewCustomer() {
      const { customer } = await createCustomer();
      this.$router.push({ name: 'customer', params: { id: customer.id }, query: { mode: 'edit' } });
    },
    async load() {
      this.isLoading = true;
      const data = await getAccounts()
      this.items = data.accounts
      this.totalRows = this.items.length
      this.isLoading = false;
    },
    async inactivateAccount(item) {
      const result = await this.$swal.fire({
        title: `Deseja desativar esta conta?`,
        text: "",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode Cancelar!'
      });

      if (result.isConfirmed) {
        await inactivateAccount(item.id);
        await this.load();
      }
    },

    async activateAccount(item) {
      const result = await this.$swal.fire({
        title: `Deseja ativar esta conta?`,
        text: "",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode Ativar!'
      });

      if (result.isConfirmed) {
        await activateAccount(item.id);
        await this.load();
      }
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    isExpired(date) {
      return new Date(date) < new Date();
    },
    async createAccount() {
      this.$swal.fire({
        title: "Adicionar uma nova conta",
        html:
          '<input type="text" id="name" class="swal2-input" placeholder="Nome" autocomplete="off">' +
          '<input type="email" id="email" class="swal2-input" placeholder="Email" autocomplete="off">' +
          '<input type="password" id="password" class="swal2-input" placeholder="Senha" autocomplete="off">' +
          '<input type="date" id="licenceExpiryDate" class="swal2-input" placeholder="sadd" autocomplete="off">',
        showCancelButton: true,
        confirmButtonText: "Criar!",
        cancelButtonText: "Cancelar",
        preConfirm: async () => {
          // Get the values from the form fields
          const name = document.getElementById("name").value;
          const email = document.getElementById("email").value;
          const password = document.getElementById("password").value;
          const licenceExpiryDate = document.getElementById("licenceExpiryDate").value;

          try {
            await createAccount({ name, email, password, licenceExpiryDate });
            await this.load();
          } catch (error) {
            this.$swal.showValidationMessage(`Request failed: ${error}`);
          }
        },
        onOpen: function () {
          // Set the focus to the first form field
          document.getElementById("name").focus();
        }
      });
    },
  }
}
</script>

<style lang="scss">
.list-clients-wrapper {
  .table.b-table {
    margin-bottom: 0;

    &>thead>tr th {
      border: none;
    }

    td,
    th {
      vertical-align: middle;
      display: table-cell;
      padding: 1rem 1.5rem;
    }
  }
}
</style>
