<template>


<div class="card mb-4 shadow-sm">
  <div class="card-header">
    <h4 class="my-0 font-weight-normal">{{title}}</h4>
  </div>
  <div class="card-body">
    <ul class="list-unstyled text-left mt-3 mb-4">
      <li v-for="requirement in requirements" v-bind:key="requirement.id">
        <label class='with-square-checkbox'>
          <input checked='true' type='checkbox' disabled>
          <span>{{requirement.text}}</span>
        </label>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
export default {
  name: 'RequirementsChecklist',
  props: ["title", "requirements"]
}
</script>

<style scoped>
  label.with-square-checkbox {
    cursor: pointer;
  }
  label.with-square-checkbox span {
    line-height: 24px;
  }
  label.with-square-checkbox span::before {
    margin-right: 4px;
    padding-left: 1px;
    width: 21px;
    height: 21px;
    border: 2px solid grey;
    display: inline-block;
    line-height: 15px;
    content: '';
    float: left;
  }
  label.with-square-checkbox input[type="checkbox"] {
    display: none;
  }
  label.with-square-checkbox input[type="checkbox"]:checked + span::before {
    /* font-family: 'Glyphicons Halflings'; */
    /* content: '\e013'; */
    content: '\2714';
    color: hsl(77, 80%, 40%);
  }
  li {
    list-style-type: none;
  }
</style>