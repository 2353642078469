import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OnlineClasses from '../views/OnlineClasses.vue'
import MindMaps from '../views/MindMaps.vue'
import Customer from '../views/customer/Customer.vue'
import Calculations from '../views/customer/calculations/Calculations.vue'
import CustomerCalculationList from '../views/customer/calculations/CalculationList.vue'
import CustomerRules from '../views/customer/Rules.vue'
import GenialReport from '../views/customer/GenialReport.vue'
import FutureRules from '../views/customer/FutureRules.vue'
import RulesNotApplicable from '../views/customer/RulesNotApplicable.vue'
import CustomerWorkRecords from '../views/customer/work-records/WorkRecords.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Plans from '../views/Plans.vue'
import Users from '../views/Users.vue'
import UserEdit from '../views/user/UserEdit.vue'
import ListUsers from '../views/user/List.vue'
// import NewCalculation from '../views/calculations/New.vue'
// import ViewCalculation from '../views/calculations/View.vue'
// import EditCalculation from '../views/calculations/Edit.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Management from '../views/Management.vue'
import Accounts from '../views/management/Accounts.vue'
import Indexes from '../views/management/Indexes.vue'

Vue.use(VueRouter)

import isAuthenticated from './policies/isAuthenticated'
import isNotAuthenticated from './policies/isNotAuthenticated'
import isManagement from './policies/isManagement'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/plans',
    name: 'plans',
    component: Plans,
    beforeEnter: isNotAuthenticated
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: isAuthenticated,
    meta: {
      title: 'Calculadora',
      icon: 'fa-user-circle',
    },
  },
  {
    path: '/online-classes',
    name: 'online-classes',
    component: OnlineClasses,
    beforeEnter: isAuthenticated,
    meta: {
      title: 'Video Aulas',
      icon: 'fa-question-circle-o',
    },
  },
  {
    path: '/mindmaps',
    name: 'mindmaps',
    component: MindMaps,
    beforeEnter: isAuthenticated,
    meta: {
      title: 'Mapas Mentais',
      icon: 'fa-question-circle-o',
    },
  },
  {
    path: '/customer/:id',
    name: 'customer',
    component: Customer,
    beforeEnter: isAuthenticated,
    meta: {
      title: 'Cliente',
      icon: 'fa-user-circle',
    },
    children: [
      {
        path: 'calculations/:calculationId',
        name: 'calculations',
        component: Calculations,
        children: [
          {
            path: 'rules',
            name: 'rules',
            component: CustomerRules,
          },
          {
            path: 'rules-not-applicable',
            name: 'rules-not-applicable',
            component: RulesNotApplicable,
          },
          {
            path: 'future-rules',
            name: 'future-rules',
            component: FutureRules,
          },
          {
            path: 'work-records',
            name: 'work-records',
            component: CustomerWorkRecords,
          },
          {
            path: 'genial-report',
            name: 'genial-report',
            component: GenialReport,
          },
        ],
      },
    ],
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      title: 'Usuários',
      icon: 'fa-user-circle',
    },
    children: [
      {
        path: '/',
        name: 'list-users',
        beforeEnter: isManagement,
        component: ListUsers,
      },
      {
        path: ':id/edit',
        name: 'edit-user',
        beforeEnter: isAuthenticated,
        component: UserEdit,
      },
    ],
  },
  {
    path: '/management',
    name: 'management',
    component: Management,
    beforeEnter: isManagement,
    meta: {
      title: 'Gestão',
      icon: 'fa-id-card',
    },
    children: [
      {
        path: 'accounts',
        name: 'accounts',
        component: Accounts,
        meta: {
          title: 'Contas',
        },
      },
      {
        path: 'indexes',
        name: 'indexes',
        component: Indexes,
        meta: {
          title: 'Índices',
        },
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
