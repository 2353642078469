<template>
  <div>
    <p>
      A ORIENTA LEGAL LTDA., inscrita no CNPJ n. 43.877.917/0001-81, acorda, com o contratante os termos e condições que regulam a utilização do software, incluindo recursos e funcionalidades, garantias e suporte, assim como todo o conteúdo associado ao serviço.
    </p>
    <p>
      Assim, a ORIENTA LEGAL LTDA., empresa que criou a Genial Prev, passa a ser referida no presente instrumento como CONTRATANTE, e, o assinante, sendo aquele que solicita a liberação da licença, como CONTRATADO.
    </p>

    <p>
      1. Serviço da Genial Prev
    </p>

    <p>
      1.1. A Genial Prev é uma plataforma de ensino de Direito Previdenciário e foi desenvolvida pela ORIENTA LEGAL LTDA., auxiliando os profissionais do Direito ou de áreas correlatas, oferecendo ferramentas de cadastro de clientes, de cálculos, de importação de documentos, relatórios, suporte nos cálculos, aulas, mapas mentais e outros materiais e outras funcionalidades, com a venda de licença de periodicidade anual.
    </p>

    <p>
      2. Aceitação das condições de uso
    </p>

    <p>2.1. O seu uso dos serviços da Genial Prev. é regido por estes termos e se sujeita às presentes condições, que constituem contrato vinculante entre o contratante e o contratado.</p>
    <p>2.2 O contratado está ciente dos Termos de Uso e, caso não aceite, pedimos que não utilize os nossos serviços.</p>
    <p>2.3. A Contratante se reserva o direito de atualizar ou alterar estes termos. Após a publicação da alteração destes Termos de Uso, o fato de continuar utilizando os Serviços constitui sua expressa concordância para com os Termos de Uso atualizados.</p>
    <p>2.4. Caso você tenha qualquer dúvida, ou tiver algum ponto que julgue ser direcionado pra nosso encarregado em relação aos seus dados pessoais, entre em contato:</p>
    <p>Whatsapp: (41) 99895-9781</p>
    <p>E-mail: suporte@previdenciagenial.com.br</p>
    <p>3. Assinatura</p>
    <p>3.1 Pelos serviços contratados, você pagará a Genial Prev, o valor da licença anual de R$ 3.000,00 (três mil reais), em 12 (doze) parcelas mensais e iguais de R$ 250,00 (duzentos e cinquenta reais)</p>
    <p>3.2 A contratante poderá alterar o preço dos planos de assinatura anualmente ou em outra frequência. Todavia, nenhuma alteração desfavorável ao contratado será aplicada sem uma comunicação através do e-mail principal cadastrado.</p>
    <p>3.2. Forma de Pagamento: seu plano deverá ser pago por cartão de crédito.</p>
    <p>3.2.1. A data da contratação é definida como data de aniversário e ocorre anualmente, ou seja, a renovação se dará 1 ano – ou 365 dias – após a data de aniversário, configurando assim ciclos anuais de cobrança.</p>
    <p>3.2.2. A contratação é feita pelo site http://app.previdenciagenial.com.br/#/register</p>
    <p>3.2.3. Se ocorrer do cartão de crédito, escolhido como forma de pagamento, for cancelado ou expirado por qualquer motivo, você se compromete a informar os dados de um novo cartão, ou optar por outra forma de pagamento válida para evitar qualquer suspensão no uso do sistema ou na prestação dos serviços.</p>
    <p>3.2.4. A contratante não armazena dados de cartão de crédito. Os dados de cartão ficam salvos em empresa especializada e com “PCI compliance”.</p>
    <p>3.3.</p>
    <p>Período de contratação: Começa no dia da assinatura do plano, renovado nas datas de aniversário automaticamente, por ciclos iguais e ininterruptos, utilizando seu login e senha de acesso.</p>
    <p>3.4. Vedação de Compartilhamento de acesso: Para manter o respeito e o cuidado com todos os contratantes, o contratante do presente instrumento se compromete a não compartilhar seus dados de acesso.</p>
    <p>3.4.1. Caso haja qualquer acesso desconhecido ou suspeita de que o nome de usuário e senha foram divulgados ou obtidos por terceiros, o contratante se compromete a entrar em contato com a contratada.</p>
    <p>3.5. Reativação de Licença: caso a licença expire, ou seja, não haja renovação após o período de 1 ano, valerão para a reativação de licenças os planos e condições vigentes na data do retorno.</p>
    <p>3.5.1. Com a reativação da licença, não garantimos disponibilidade dos dados já inseridos anteriormente.</p>
    <p>4. Acesso</p>
    <p>4.1.</p>
    <p>Liberação do acesso: após a contratação e autorização do pagamento em seu cartão de crédito, seu acesso à plataforma será liberado em até 72 horas corridas.</p>
    <p>4.2. Bloqueio de acesso: Em caso de inadimplência de qualquer fatura por prazo igual ou superior a 7 (sete) dias corridos, fica o contratante autorizado, independente de prévio aviso, a bloquear o seu acesso ao software ou a funcionalidades específicas.</p>
    <p>4.2.1. O bloqueio de acesso impedirá novas inclusões de dados e o acesso à plataforma como um todo.</p>
    <p>4.2.2. Caso o pagamento não seja identificado em até 7 dias após a data da inadimplência, a sua conta será suspensa.</p>
    <p>4.2.3. Para voltar a utilizar a totalidade de nossos serviços, o contratante deverá regularizar sua licença.</p>
    <p>4.3. Cancelamento do acesso:. Para o cancelamento do acesso, você deverá solicitar expressamente ao nosso Atendimento.</p>
    <p>4.3.1. A única forma de cancelamento é a feita de forma expressa pelo contratante administrador da assinatura.</p>
    <p>4.3.2. Por segurança, manteremos seus dados para eventuais consultas por 3 meses após o cancelamento.</p>
    <p>4.3.3. O não pagamento não caracteriza cancelamento da licença, apenas causa para o bloqueio do acesso (4.2) e inadimplência.</p>
    <p>5. Direito de garantia</p>
    <p>5.1. A contratante oferece a garantia de 15 dias corridos após a assinatura no site. O contratante poderá suspender ou cancelar sua assinatura, sem qualquer cobrança, em até 15 dias corridos da liberação do acesso. Neste caso, o reembolso será no valor integral contratado por você.</p>
    <p>5.1.1.</p>
    <p>Essa garantia se aplica uma única vez e no primeiro acesso, salvo indisponibilidade do sistema, que será tratado caso a caso.</p>
    <p>5.1.2.</p>
    <p>Caso o contratante, por engano ou qualquer outra razão, tente utilizar mais de uma vez o período de garantia, infringindo o item 1, a contratante se resguarda o direito de cobrança da anualidade integral.</p>
    <p>5.1.3.</p>
    <p>Qualquer garantia ou promoção que altere a garantia para além dessa é mera liberalidade do contratado.</p>
    <p>5.1.4. Em caso de reativação da licença não há um novo período de garantia.</p>
    <p>5.1.5. A contratada se resguarda ao direito de verificar os dados cadastrais e de pagamento, quando houver solicitação de reembolso, nas hipóteses de violação do item 5.1.1.</p>
    <p>5.2. Para que haja o reembolso integral do período de garantia, o cancelamento deve ser expresso, via e-mail de suporte suporte@previdenciagenial.com.br</p>
    <p>No cancelamento da licença, não será realizada qualquer cobrança e aplicação de juros, apenas será debitado o valor do respectivo período.</p>
    <p>7. Cancelamento</p>
    <p>7.1. Assim como na contratação da assinatura, o cancelamento deve apenas seguir o procedimento indicado através de expressa solicitação no e-mail suporte@previdenciagenial.com.br.</p>
    <p>7.2. Apenas o cancelamento expresso, conforme indicado pelo CJ, encerra a prestação de serviços destes Termos de Uso. Enquanto isso não for realizado, a contratante continuará a disponibilizar os serviços conforme os Termos de Uso.</p>
    <p>7.3. No cancelamento de um plano de pagamento anual, não será realizada qualquer cobrança e aplicação de juros, apenas será debitado o valor do respectivo período.</p>
    <p>7.4. O cancelamento da assinatura deve ser expresso e realizado pelo contratante. Para efetuar o cancelamento, você deve acessar o procedimento indicado pela CJ, que se encontra no FAQ.</p>
    <p>7.4.1. Caso não seja efetuada a solicitação de cancelamento, a contratação da licença anual será renovada automaticamente através da emissão de uma nova fatura de cobrança gerada por igual período e assim sucessivamente a cada data de aniversário.</p>
    <p>7.5.</p>
    <p>Todas as informações incluídas pelo contratante estarão à sua disposição a qualquer momento durante a assinatura e por 3 meses após o cancelamento expresso ou vencimento da licença.</p>
    <p>7.6. A contratada se reserva o direito de cancelar seu acesso ao software nos casos em que o contratado ou algum usuário use o software de forma diversa daquela estipulada no presente instrumento.</p>
    <p>7.6.1. O cancelamento do seu acesso ao software será realizado mediante envio de e-mail, que deverá ser respondido em até 24 (vinte e quatro) horas úteis, sob pena de cancelamento do acesso.</p>
    <p>7.6.2. Caso o contratante entenda ter havido uso indevido do software, mesmo após sua resposta, reserva-se o direito de cancelar o acesso. Esse cancelamento ocorrerá após 24 (vinte e quatro) horas a contar do envio do e-mail acima referido, ou após a resposta do contratante, o que ocorrer primeiro.</p>
    <p>8. Obrigações do contratado</p>
    <p>8.1. As obrigações do contratado são:</p>
    <p>8.1.1.</p>
    <p>Garantir o funcionamento pleno e regular do software, com segurança dos dados e transparência nos cálculos e registros;</p>
    <p>8.1.2. Manter disponíveis o serviço contratado durante 24 horas por dia, nos 7 dias da semana, exceto: (i) durante interrupções planejadas (que serão notificadas , com antecedência através de e-mail, e que serão programadas na medida do possível durante os finais de semana, ou horários não comerciais nos dias de semana, ou (ii) qualquer indisponibilidade causada por caso fortuito ou força maior, ações de governo, inundações, incêndios, terremotos, conflitos civis, atos de terrorismo, greves ou problemas laborais (exceto os que envolvem funcionários da contratada), falhas ou atrasos do fornecedor do serviço de Internet.</p>
    <p>8.1.3.</p>
    <p>Manter as informações pessoais do contratante, bem como seus registros de acesso, em sigilo e em ambiente seguro;</p>
    <p>8.1.4. Na ocorrência de falhas de programação (“bugs”), a contratada assume o compromisso de corrigir o erro mais rápido possível e atualizar o software para todos os usuários;</p>
    <p>8.1.5. Cancelar o acesso do contratante, desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em vigor, para garantir a segurança e consistência do software.</p>
    <p>8.2. A contratada se reserva no direito de alterar e atualizar funcionalidades do software, além de suspender o acesso em casos de manutenção ou outros procedimentos, sem que isso gere qualquer direito a ressarcimento ou indenização.</p>
    <p>8.3. A contratada não garante o acesso ao site livre de erros ou problemas internos ou externos, bem como os de força maior, como, por exemplo, quedas de energias, falhas na internet, dentre outros.</p>
    <p>8.4. Em nenhuma circunstância, a contratada poderá ser responsabilizada quanto à exatidão dos resultados obtidos pelo uso do software, não sendo responsável perante o contratante por eventuais perdas e danos, ou por quaisquer danos patrimoniais e extrapatrimoniais, incluindo ações judiciais, extrajudiciais ou administrativas, resultantes do uso do software.</p>
    <p>8.5. Nós nos comprometemos com o Suporte. O serviço de suporte oferecido pela CJ, compreende 2 (dois) canais de apoio, gerenciados por pessoas especializadas em Direito e/ou tecnologia da informação e programação, são eles:</p>
    <p>8.5.1. Whatsapp</p>
    <p>8.5.5. E-mails enviados para suporte@previdenciagenial.com.br</p>
    <p>8.6. O suporte tem como objetivo atender às seguintes questões:</p>
    <p>8.6.1. Falhas de programação (“bugs”) nas funcionalidades</p>
    <p>8.6.2. Cálculos com problemas técnicos do</p>
    <p>8.6.3. Dúvidas sobre como usar as funcionalidades</p>
    <p>8.6.4. Dúvidas sobre a metodologia do contratado</p>
    <p>8.6.5. Sugestões de melhorias, embora não gerem qualquer obrigação à contratada, ou direito ao contratante.</p>
    <p>8.6.6. Dúvidas sobre o preenchimento do cadastro.</p>
    <p>8.6.7. Dúvidas quanto à parte financeira (antes e após a contratação). Por exemplo: pagamento, renovação, cancelamento, suspensão.</p>
    <p>§1º As respostas do suporte serão dadas por mensagens em até 48h úteis.</p>
    <p>§2º O suporte não atenderá às seguintes questões:</p>
    <p>Dúvidas sobre Direito material ou processual, por exemplo: O contratado solicita ao suporte que esclareça a possibilidade de utilizar determinado índice que não é adotado pela legislação ou pelo manual de cálculos da Justiça Federal e a fundamentação de sua utilização, por ser mais favorável ao cliente.</p>
    <p>Análise de casos concretos</p>
    <p>Análise de documentos dos clientes do contratante, por exemplo: O usuário envia documentos ao suporte para análise, sem indício de falha técnica no cálculo ou solicitação por parte do suporte, com a finalidade de que os analistas encontrem nos documentos períodos ou salários de contribuição não considerados pelo contratado.</p>
    <p>Conferência e revisão dos cálculos9. Suas Obrigações</p>
    <p>9.1. São obrigações do contratado:</p>
    <p>9.1.1. Manter os dados cadastrais e suas informações pessoais sempre verdadeiras e atualizadas. Em especial o seu email de cadastro, que é a principal forma de nos comunicarmos;</p>
    <p>9.1.2. Verificar todas as informações inseridas no software, inclusive por meio de importação de documentos;</p>
    <p>9.1.3. Analisar o resultado dos cálculos previdenciários, ou de outros campos do Direito, e o Direito aplicado ao caso concreto;</p>
    <p>9.1.4. Utilizar o software em sua atividade empresarial nos estritos termos deste, respeitando ainda a legislação vigente e o direito de terceiros.</p>
    <p>9.1.5. Manter seguros, não divulgar e não fornecer para outra pessoa seu login e senha, utilizando de preferência uma senha segura que seja diferente das senhas usadas em outros serviços, como medida de segurança;</p>
    <p>9.2. Avisar o contratante sempre que achar algum erro no software (“bug”);</p>
    <p>9.3. Caso acredite que seu login e senha de acesso ao software tenham sido roubados ou sejam de conhecimento de outras pessoas, por qualquer razão, você deverá imediatamente comunicar tal fato à contratada, sem prejuízo da alteração da sua senha imediatamente, por meio do software. Parágrafo único: Será de responsabilidade do contratante exclusivamente: Análise da validade, aplicação e procedência de teses. Análise de cálculos que não foram criados na plataforma. Análise de documentos dos seus clientes do escritório, salvo se o documento for relevante para identificação de algum bug no sistema, caso em que o contratada atenderá a demanda. Análise de diferenças dos cálculos feitos pelo contratado com os cálculos feitos em outras planilhas ou softwares. Análise de resultado de um cálculo individual feito na plataforma do contratado, salvo identificação de bug específico no software. Obtenção e aplicação de conhecimentos de cálculo, e matérias de direito previdenciário, civil, tributário, trabalhista, penal e demais áreas . Análise de cálculos realizados pelo RPPS, pela Justiça Federal, por contadores e por outros softwares. Análise, compreensão e aplicação do direito e do cálculo sobre o caso concreto. Eleger, contratar, selar parcerias ou quaisquer outras formas de vínculo com advogados, ou outros profissionais. Enviar qualquer dúvida ou solicitação diretamente pelo canal de ajuda dentro do software ou para o email suporte@previdenciagenial.com.br, tendo em vista que estes são os únicos canais válidos para tanto.</p>
    <p>10. Propriedade Intelectual</p>
    <p>10.1. É importante frisar que o software pertence ao contratado e que há uma cessão de uso do programa. Por isso, ao utilizar a Plataforma Genial Prev, o contratante não adquire nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material, sobre ou relacionados ao software ou nenhuma parte dele.</p>
    <p>10.2. O contratado também não adquire nenhum direito sobre ou relacionado ao software ou qualquer componente dele, além dos direitos expressamente garantidos por este Termo ou em qualquer outro contrato mutuamente acordado por escrito entre o contratante e o contratado. Quaisquer direitos não expressamente concedidos sob o presente instrumento são reservados integralmente ao contratado, sob as penas da lei, além de apuração de perdas e danos.</p>
    <p>10.3. Também será de propriedade exclusiva da contratado todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a, textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material. A marca Genial Prev e o software estão regularmente registrados e protegidos no INPI.</p>
    <p>11. Restrições</p>
    <p>11.1. Sem prejuízos de outras obrigações expostas no presente Termo de Uso, fica proibido a você ou a terceiros, sob pena de ações cíveis e penais cabíveis, além de multa contratual de até 300 vezes o valor do plano anual disponível: a. Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software objeto deste Termo de Uso, assim como seus módulos, partes, manuais ou quaisquer informações a ele relativas; b. Retirar ou alterar, total ou parcialmente, os avisos de reserva de direito existente no software e na documentação; c. Praticar engenharia reversa, descompilação, desmontagem do software, ou qualquer tentativa de “exploit” de software ou tentativa de acesso de conta que não a sua própria dentro do programa. d. Usar automações e “crawlers” não autorizados envolvendo o sistema ou site da contratada. e. Usar de forma indevida ou deturpar a criação de cálculos, clientes ou de outra métrica de valor associada ao software. E12. Confidencialidade e proteção dos dados</p>
    <p>12.1.</p>
    <p>O contratante consente, de forma livre e expressa, que a contratada colete, use, armazene e faça o tratamento de suas Informações, incluindo seus dados pessoais, de conta, os quais serão necessários para que o serviço ofertado seja prestado em sua integralidade.</p>
    <p>12.2. O contratado consente, livre e expressamente, em fornecer os dados que permitam o acesso às Informações necessárias para que o software execute todas as funções para as quais foi projetado.</p>
    <p>12.3. Você consente que, ao acessar o site da contratada, esta poderá coletar informações técnicas de navegação, tais como tipo de navegador do computador utilizado para acesso ao site, endereço de protocolo de Internet, páginas visitadas, tempo médio gasto no site e informações gerais anonimizadas sobre o uso. Tais informações poderão ser usadas para orientar você e melhorar os nossos serviços ofertados, ficando vedado o uso público das informações, exceto as que já forem públicas, mas liberado o uso pelo contratante para os fins aqui descritos.</p>
    <p>12.4. Você consente livre e expressamente que suas informações poderão ser transferidas a terceiros em decorrência da venda, aquisição, fusão, reorganização societária ou qualquer outra mudança no controle da contratada.</p>
    <p>12.5.</p>
    <p>Você consente livre e expressamente que a contratada utilize cookies apenas para controlar a audiência e a navegação em seu site e possibilitar a identificação de serviços segmentados e personalizados ao perfil do usuário.</p>
    <p>12.6. A contratada garante que estas informações coletadas por meio de cookies são estatísticas e não pessoais, bem como que não serão utilizadas para propósitos diversos dos expressamente previstos nestes Termos de Uso, comprometendo-se a adotar todas as medidas necessárias a fim de evitar o acesso e o uso de tais informações por quaisquer terceiros, sem a devida autorização.</p>
    <p>12.7. O contratante responsável pela veracidade das informações prestadas e eventuais inconsistências podem impactar no seu acesso ao Site ou ao Aplicativo.</p>
    <p>12.7.1. O login e a senha são intransferíveis, o que garante a fonte dos dados e a segurança da informação. O contratante é responsável pela guarda, sigilo e bom uso do seu login e senha</p>
    <p>13. Condições Gerais</p>
    <p>13.1. Caso o contratante venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou em parte, seja de dados, seja do software, será considerado como parte do software fornecido pela contratada, ficando, portanto, sua propriedade incorporada pela contratada e seu uso condicionado a estas cláusulas contratuais;</p>
    <p>13.2. Estes Termos de Uso obrigam as partes e seus sucessores e somente o contratante possui licença não exclusiva para a utilização do mesmo, vedada a transferência de direitos e obrigações acordados por este instrumento. O contratado poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações inerentes ao presente Termos de Uso;</p>
    <p>13.3. O contratante aceita receber comunicações via correio eletrônico sobre treinamentos, parcerias e campanhas relacionadas ao software;</p>
    <p>13.4. A contratada poderá, ao seu exclusivo critério, a qualquer tempo e sem a necessidade de comunicação prévia: a. Encerrar, modificar ou suspender, total ou parcialmente, o acesso do Usuário ao software, quando referido acesso ou cadastro estiver em violação das condições estabelecidas neste Termo de Uso; b. Excluir, total ou parcialmente, as informações cadastradas pelo Usuário que não estejam em consonância com as disposições deste Termo de Uso; c. Acrescentar, excluir ou modificar o conteúdo oferecido no Site. d. Alterar quaisquer termos e condições deste Termo de Uso mediante simples comunicação ao contratado a ser realizada por e-mail.</p>
    <p>13.5. A contratada ainda poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do software, mediante comunicação prévia por escrito contratante, com antecedência mínima de 15 (quinze) dias, disponibilizando formas e alternativas de extrair do Site as informações, salvo nas hipóteses de caso fortuito ou força maior.</p>
    <p>13.6.</p>
    <p>O contratante poderá, por meio de comunicação ao endereço eletrônico indicado pelo contratado em seu cadastro ou por meio de aviso no Site, definir preços para a oferta de determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços tenham sido ofertados de forma gratuita, sendo a utilização destes, após o referido aviso, considerada como concordância do contratante com a cobrança de tais preços.</p>
    <p>O contratante concorda que somente a pessoa cadastrada poderá solicitar por meio do e-mail suporte@previdenciagenial.com.br, que as suas informações inseridas no software sejam apagadas. Ainda, o contratante declara sua ciência de que, uma vez apagadas, estas não poderão mais ser recuperadas, ficando o contratado isento de qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste procedimento solicitado pelo contratante.</p>
    <p>As referidas informações serão apagadas em, no máximo, 72 (setenta e duas) horas a contar do recebimento do e-mail acima.</p>
    <p>O contratante é responsável pelo apagamento de seus dados imediatamente após o envio da referida solicitação.</p>
    <p>14. Legislação Aplicável</p>
    <p>14.1. Estes Termos de Uso se sujeitam às leis brasileiras e as partes elegem, de forma irrevogável e irretratável, o foro da Comarca da Cidade de Curitiba, Estado do Paraná, para dirimir quaisquer dúvidas ou controvérsias oriundas destes Termos de Uso, com a exclusão de qualquer outro, por mais privilegiado que seja.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
