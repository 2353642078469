import axios from 'axios';

export async function getPlans() {
  const response = await axios.get('/plans');
  return response.data;
}

export async function getPlan(planId, query) {
  const response = await axios.get(`/plans/${planId}`, {
    params: query
  });
  return response.data;
}
