<template>
  <div class="work-records-wrapper p-3">
    <button
      class="bg-transparent w-100 text-center border border-dashed p-0 mb-3 text-gray-600"
      v-on:click="showWorkRecordsFormModal = true"
    >
      <i class="fa fa-plus mr-2" aria-hidden="true"></i> Inserir registro
    </button>

    <!-- <div class="border rounded border-info border-left-4 mb-3" style="background-color: #FAFB7F; border: 1px solid black !important;" v-if="indexDate">
      <div class="p-2 border-bottom d-flex align-items-center">
        <div>
          <h6 class="mb-2 text-gray-600 font-weight-bold">
            Índice Utilizado: {{ indexDate }}
          </h6>
        </div>
      </div>
    </div> -->

    <div class="border rounded border-info border-left-4 mb-3" style="background-color: #FAFB7F; border: 1px solid black !important;" v-if="isConcomitantTime">
      <div class="p-2 border-bottom d-flex align-items-center">
        <div>
          <h6 class="mb-2 text-gray-600 font-weight-bold">
            Tempo concomitante em {{ concomitantWorkRecords }}.
          </h6>
        </div>
      </div>
    </div>

    <WorkRecordItem
      v-for="record in workRecords"
      v-bind:key="record.id"
      v-bind:item="record"
      v-on:addRemuneration="handlerAddRemuneration"
      v-on:edit="handlerEditWorkRecord"
      v-on:remove="handlerRemoveWorkRecord"
      v-on:addition="handlerAddition"
      v-on:deduction="handlerDeduction"
    />

    <!-- Modal -->
    <ModalWorkRecordsForm
      v-if="showWorkRecordsFormModal"
      v-bind:parentId="parentId"
      v-bind:record="recordSelected"
      v-on:hidden="handlerCloseModal"
      v-on:save="fetchWorkRecords"
    />

    <ModalDeductionForm
      v-if="showDeductionFormModal"
      v-bind:parentId="parentId"
      v-bind:record="recordSelected"
      v-on:hidden="handlerCloseModal"
      v-on:save="fetchWorkRecords"
    />

    <ModalAdditionForm
      v-if="showAdditionFormModal"
      v-bind:parentId="parentId"
      v-bind:record="recordSelected"
      v-on:hidden="handlerCloseModal"
      v-on:save="fetchWorkRecords"
    />

    <ModalRemunerationForm
      v-if="showRemunerationFormModal"
      v-bind:workRecord="recordSelected"
      v-bind:updateIndex="updateIndex"
      v-on:hidden="handlerCloseModal"
      v-on:save="fetchWorkRecords"
    />
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import WorkRecordItem from './components/WorkRecordItem';
import ModalWorkRecordsForm from './components/ModalWorkRecordsForm';
import ModalRemunerationForm from './components/ModalRemunerationForm';
import ModalDeductionForm from './components/ModalDeductionForm';
import ModalAdditionForm from './components/ModalAdditionForm';
import { getUpdateIndex } from '@/services/updateIndex';

export default {
  name: 'WorkRecords',
  components: {
    WorkRecordItem,
    ModalWorkRecordsForm,
    ModalRemunerationForm,
    ModalDeductionForm,
    ModalAdditionForm,
  },

  data: () => ({
    showWorkRecordsFormModal: false,
    showRemunerationFormModal: false,
    showDeductionFormModal: false,
    showAdditionFormModal: false,
    recordSelected: null,
    concomitantWorkRecords: "",
    indexDate: null
  }),

  async created() {
    const response = await this.loadWorkRecords(this.$route.params.calculationId);
    this.calculation = response.calculation;
    this.fetchUpdateIndex();
  },

  computed: {
    ...mapGetters('workRecords', ['workRecords']),

    parentId() {
      return this.$route.params.calculationId;
    },

    isConcomitantTime() {
      const workRecords = [...this.workRecords];

      let isConcomitant = false;

      while (workRecords.length > 1) {
        const currentWorkRecord = workRecords.shift();

        for (const workRecord of workRecords) {
          if (moment(workRecord.ingressDate).isBetween(currentWorkRecord.ingressDate, currentWorkRecord.egressDate)) {
            isConcomitant = true;
            this.concomitantWorkRecords = `${currentWorkRecord.companyName} e ${workRecord.companyName}`;
            break;
          }

          if (moment(workRecord.egressDate).isBetween(currentWorkRecord.ingressDate, currentWorkRecord.egressDate)) {
            isConcomitant = true;
            this.concomitantWorkRecords = `${currentWorkRecord.companyName} e ${workRecord.companyName}`;
            break;
          }
        }

        if (isConcomitant) break;
      };

      return isConcomitant;
    }
  },

  methods: {
    ...mapActions('workRecords', ['loadWorkRecords']),

    fetchWorkRecords() {
      this.loadWorkRecords(this.$route.params.calculationId);
    },

    fetchUpdateIndex() {
      const date = this.calculation.calculationDate ? moment(this.calculation.calculationDate).format("MM/YYYY") : moment().format("MM/YYYY");
      getUpdateIndex({ date })
        .then((response) => {
          this.updateIndex = response.updateIndex;

          if (!response.indexFoundInDate) {
            this.indexDate = response.indexDate;
          } else {
            this.indexDate = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handlerAddRemuneration(record) {
      this.recordSelected = record;
      this.showRemunerationFormModal = true;
    },

    handlerEditWorkRecord(record) {
      this.recordSelected = record;
      this.showWorkRecordsFormModal = true;
    },

    handlerAddition(record) {
      this.recordSelected = record;
      this.showAdditionFormModal = true;
    },

    handlerDeduction(record) {
      this.recordSelected = record;
      this.showDeductionFormModal = true;
    },

    async handlerRemoveWorkRecord(record) {
      const result = await this.$swal.fire({
        title: 'Tem certeza que deseja remover esse registro?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode remover!'
      });

      if (!result.isConfirmed) { return; }

      try {
        await axios.delete(`/work-records/${record.id}`);

        this.$swal.fire(
          'Pronto!',
          `Removido com sucesso!`,
          'success'
        )
        await this.fetchWorkRecords();
      } catch (error) {
        this.$swal.fire(
          'Ops!',
          `Não foi possível remover, tente novamente mais tarde!`,
          'error'
        )
      }
    },

    handlerCloseModal() {
      this.showWorkRecordsFormModal = false;
      this.showRemunerationFormModal = false;
      this.showDeductionFormModal = false;
      this.showAdditionFormModal = false;
      this.recordSelected = null;
    },
  },
}
</script>

<style scoped>
</style>
