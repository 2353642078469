<template>
    <div class="container text-center">
        <p>Por favor, regularize sua(s) fatura(s) para continuar usando a plataforma ou entre em contato conosco para mais informações.</p>

        <div class="row justify-center">
            <div v-if="paymentInfo">
                <p class="copy-and-paste" @click="copyToClipboard(paymentInfo.copyAndPaste)">
                    <span class="bold">Copia e cola:</span> <span id="copy-and-paste">{{ paymentInfo.copyAndPaste }}</span>
                </p>
                <img :src="'data:image/png;base64,' + paymentInfo.qrCode" />
            </div>
            <div class="card col-4" v-for="payment of payments" :key="payment.id" v-else>
                <div class="text-left p-3">
                    <p>Valor: R${{ formatMoney(payment.value, 2) }}</p>
                    <p>Status: {{ formatStatus(payment.status) }}</p>
                    <p>Venceu em: {{ formatDate(payment.originalDueDate) }}</p>
                </div>

                <!-- <a class="btn btn-primary mb-2" :href="payment.invoiceUrl" target="_blank">PAGAR AGORA!</a> -->
                <button class="btn btn-primary mb-2" @click=getPaymentInfo(payment.id)>PAGAR AGORA!</button>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { listPayments, getPayment } from '@/services/payments';
    export default {
        name: 'Payments',
        data() {
            return {
                payments: [],
                paymentInfo: null
            }
        },
        mounted() {
            this.getPayments();
        },
        computed: {
        },
        methods: {
            async getPayments() {
                try {
                    const payments = await listPayments();

                    this.payments = payments;
                } catch (err) {

                }
            },
            formatMoney(value, digits) {
                const d = digits !== undefined ? digits : 2;
                const nf = new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: d,
                    maximumFractionDigits: d,
                });
                return nf.format(value);
            },
            formatStatus(status) {
                switch (status) {
                    case "PENDING":
                        return "PENDENTE"; 
                }
                return status;
            },

            formatDate(date) {
                return moment(date).format('DD/MM/YYYY');
            },

            async getPaymentInfo(paymentId) {
                const paymentInfo = await getPayment(paymentId);

                this.paymentInfo = paymentInfo;
            },

            copyToClipboard(text) {
                navigator.clipboard.writeText(text);

                this.$toast.open({
                    message: 'Código copia e cola copiado.',
                    type: 'info',
                    position: 'bottom-right',
                });
            }
        }
    };
</script>

<style>
    .justify-center {
        justify-content: center;
    }
    .bold {
        font-weight: bold;
    }
    .copy-and-paste {
        cursor: pointer;
    }
</style>