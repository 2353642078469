<template>
  <div class="input-date-picker">
    <the-mask
      type="text"
      id="birth-date"
      class="form-control form-control-sm flex-fill"
      v-bind:value="value"
      v-on:click.native="openDropdown"
      v-on:keyup.native="inputValidation"
      v-on:blur.native="$emit('blur', $event)"
      :mask="['##/##/####']"
      :masked="true"
    />
    <date-picker
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      valueType="format"
      format="DD/MM/YYYY"
      v-bind:clearable="false"
      ref="inputDrop"
      v-bind:disabled-date="disabledDate"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'InputDatePicker',
  props: ['value', 'ignoreLimit'],
  components: { DatePicker },

  methods: {
    openDropdown() {
      const inputDrop = this.$refs.inputDrop;
      inputDrop.openPopup();
    },

    disabledDate(date) {
      if (this.ignoreLimit) return;
      
      const today = new Date();
      return date > today;
    },

    inputValidation(e) {
      const input = e.target.value;

      if (input.length === 10) {
        const date = new Date(input.split('/').reverse().join('-'));
        const today = new Date();

        if (date <= today || this.ignoreLimit) {
          this.$emit('input', input);
        } else {
          this.$toast.open({
            message: 'A data informada é inválida, informe uma data válida menor ou igual a hoje',
            type: 'error',
            position: 'top-right'
          });
          e.target.value = this.value === 'Data inválida' ? '' : this.value;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.input-date-picker {
  display: flex;
  align-items: center;

  .mx-datepicker {
    width: 50px;

    input {
      display: none;
    }
  }
}
</style>
