import axios from 'axios';

export async function getCustomers(query = {}) {
  const response = await axios.get('/customers', query);
  return response.data;
}

// delete one
export async function deleteCustomer(id) {
  const response = await axios.delete(`/customers/${id}`);
  return response.data;
}

export async function getCustomerById(id) {
  const response = await axios.get(`/customers/${id}`);
  return response.data;
}

export async function updateCustomer(id, data) {
  const response = await axios.put(`/customers/${id}`, { customer: data });
  return response.data;
}

export async function createCustomer(data = {}) {
  const response = await axios.post('/customers', { customer: data });
  return response.data;
}

export async function getApplicableRules(calculationId) {
  const response = await axios.get(`/calculations/${calculationId}/applicable-rules`);
  return response.data;
}

export async function getNotApplicableRules(calculationId) {
  const response = await axios.get(`/calculations/${calculationId}/not-applicable-rules`);
  return response.data;
}

export async function getFutureRules(calculationId) {
  const response = await axios.get(`/calculations/${calculationId}/future-rules`);
  return response.data;
}
