<template>
  <b-modal
    v-model="modalShow"
    no-body
    hide-footer
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0">{{video.title}}</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <div>
      <iframe
        class="w-100"
        height="400"
        v-bind:src="video.videoUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalVideo',
  props: {
    video: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    modalShow: true,
  }),
}
</script>

<style scoped>
</style>
