<template>
  <div class="copyright">
    Genialprev © {{ currentYear }}
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
