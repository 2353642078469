<template>
  <div class="home mt-5">
    <div class="text-center" v-if="accountStatus === 'PENDING'">
      Aguardando confirmação do pagamento. Se você já fez o pagamento, aguarde ou entre em contato.
    </div>
    <div v-if="accountStatus === 'INACTIVE'">
      <Payments />
    </div>
    <!-- <img alt="logo" src="../assets/logo.png"> -->
    <ListCustomers v-if="accountStatus === 'ACTIVE'" />
    <div class="d-flex justify-content-center mt-5">
      <div class="card-deck d-flex justify-content-center col-6 mb-3">
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import ListCustomers from '@/components/ListCustomers.vue';
import RequirementsChecklist from '@/components/RequirementsChecklist.vue';
import Payments from '@/components/Payments.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    ListCustomers,
    RequirementsChecklist,
    Payments
  },
  computed: {
    ...mapGetters(['accountStatus']),
  }
}
</script>

<style scoped>
  /* .home {
    text-align: center;
  } */
</style>
