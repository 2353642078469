import axios from 'axios';

export async function getCalculations(query = {}) {
  const response = await axios.get('/calculations', { params: query });
  return response.data;
}

export async function deleteCalculation(id, customerId) {
  const response = await axios.delete(`/calculations/${id}`, { params: { customerId } });
  return response.data;
}

export async function getCalculationById(id) {
  const response = await axios.get(`/calculations/${id}`);
  return response.data;
}

export async function updateCalculation(id, data) {
  const response = await axios.put(`/calculations/${id}`, data);
  return response.data;
}

export async function createCalculation(data = {}) {
  const response = await axios.post('/calculations', data);
  return response.data;
}

export async function copyCalculation(data = {}) {
  const response = await axios.post('/copy-calculations', data);
  return response.data;
}
