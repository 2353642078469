
<template>
  <div class="bg-primary text-center">
    <form class="form-signin" @submit.prevent="requestPasswordResetEmail">
      <img class="mb-5" src="../assets/logo.webp" alt="">
      <h4 class="mb-3 text-white" v-if="!passwordResetEmailSent">Solicitação de recuperação de senha</h4>

      <h3 class="mb-3 text-white" v-if="passwordResetEmailSent">
        Um email foi enviado para <b>{{ email }}</b> com as instruções de recuperação de senha
      </h3>

      <label for="email" class="sr-only" v-if="!passwordResetEmailSent">Email (login)</label>
      <input type="text" id="email" v-model="email" class="form-control mb-1" placeholder="Seu e-mail" required=""
        autofocus="" v-if="!passwordResetEmailSent">

      <!-- <label for="inputPassword" class="sr-only">Senha</label>
      <input type="password" id="inputPassword" v-model="password" class="form-control" placeholder="Sua senha"
        required=""> -->
      <button class="btn btn-lg btn-secondary btn-block mt-3" type="submit" v-if="!passwordResetEmailSent">Enviar</button>


      <div class="mt-3 mb-3">
        <p>
          <router-link to="/login" class="text-white"> Voltar</router-link>
        </p>
      </div>

      <p class="mt-5 mb-3">
        <Copyright class="text-center" />
      </p>
    </form>
  </div>
</template>

<script>
import Copyright from '@/components/Copyright.vue';
import { requestPasswordResetEmail } from '@/services/auth';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: "",
      password: "",
      passwordResetEmailSent: false
    };
  },
  components: { Copyright },
  methods: {
    requestPasswordResetEmail: function() {
      const { email } = this;

      requestPasswordResetEmail({ email }).then(() => {
        this.passwordResetEmailSent = true;
      }).catch((error) => {
        this.$toast.open({
          message: error?.response?.data?.errorMessage,
          type: 'error',
          position: 'top-right',
        });
      });
    }
  }
}
</script>

<style>
.form-signin {
  width: 100%;
  max-width: 390px;
  padding: 15px;
  margin: auto;
}
</style>
