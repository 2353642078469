<template>
  <div class="validation-message">
    <span v-if="validations[field]" class="text-danger small">
      - {{ validations[field] }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ValidationMessage',
  props: {
    field: {
      type: String,
      required: true,
    },
    validations: {
      type: Object,
      required: true,
    },
  },
};
</script>
