<template>
  <div class="bg-primary">
    <div class="container text-white">
      <div class="py-5 text-center">
        <img class="d-block mx-auto mb-4" src="../assets/logo.webp">
        <h1>
          Você escolheu o plano:
        </h1>
        <h1>
          <strong>
            <span class="plan-title">{{ plan.name }} - {{ plan.period === 'yearly' ? 'Anual' : 'Mensal' }} - </span>
            <span class="plan-title" v-if="payment.type === 'CREDIT_CARD' && plan.card">
              {{installments}}X R$ {{ formatMoney(plan.card.value / installments, 2) }}
            </span>
            <span class="plan-title" v-if="payment.type === 'PIX' && plan.pix">
              1X R$ {{ formatMoney(plan.pix.value, 2) }}
            </span>
            <span class="plan-title" v-if="payment.type === 'SUBSCRIPTION'">
              R$ {{ formatMoney(plan.monthlyValue, 2) }} / mês
            </span>
          </strong>
        </h1>
      </div>

      <div class="row">
        <div class="col-md-8" v-if="plan">
          <p class="price-text-1 mb-0">Tudo por:</p>
          <p class="price-text-2" v-if="payment.type === 'CREDIT_CARD' && plan.card">
            {{installments}}X R$ {{ formatMoney(plan.card.value / installments, 2) }}
          </p>
          <p class="price-text-2" v-if="payment.type === 'PIX' && plan.pix">
            1X R$ {{ formatMoney(plan.pix.value, 2) }}
          </p>
          <p class="price-text-2" v-if="payment.type === 'SUBSCRIPTION'">
            R$ {{ formatMoney(plan.monthlyValue, 2) }} / mês
          </p>

          <div class="card p-3" v-if="loading">
            <div class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
              <h6 class="mb-0 mt-2 font-weight-bold text-black">Carregando...</h6>
            </div>
          </div>

          <div class="row card" v-if="step === 'data' && !loading">
            <form class="text-black needs-validation p-3" @submit.prevent="changeStep('payment')">
              <h2 class="form-heading">Comece aqui</h2>

              <div class="row px-3">
                <div class="col-md-6 mb-3">
                  <label for="firstName">Seu Nome</label>
                  <input type="text" class="form-control" id="firstName" placeholder="" value="" required="" v-model="account.name">
                  <div class="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="email">Seu e-mail</label>
                  <div class="input-group">
                    <input type="text" class="form-control" id="email" placeholder="" required="" autocomplete="username" v-model="account.email">
                    <div class="invalid-feedback">
                      O email não pode ser vazio.
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="cpf">CPF</label>
                  <the-mask type="text" id="cpf" class="form-control" placeholder="" value="" required="" v-model="account.cpf" :mask="'###.###.###-##'" :masked="false" />
                  <div class="invalid-feedback">
                    CPF necessário
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="phone">Telefone</label>
                  <the-mask
                    type="text"
                    class="form-control"
                    id="phone" placeholder=""
                    value=""
                    required=""
                    v-model="account.phoneNumber"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    :masked="false"
                  />
                  <div class="invalid-feedback">
                    Telefone necessário
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="state">Estado</label>
                  <b-form-select v-model="account.state" :options="stateOptions"></b-form-select>
                  <div class="invalid-feedback">
                    Estado necessário
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="city">Cidade</label>
                  <input type="text" class="form-control" id="city" placeholder="" value="" required="" v-model="account.city">
                  <div class="invalid-feedback">
                    Cidade necessário
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="neighborhood">Bairro</label>
                  <input type="text" class="form-control" id="neighborhood" placeholder="" value="" required="" v-model="account.neighborhood">
                  <div class="invalid-feedback">
                    Bairro necessário
                  </div>
                </div>
                <div class="col-md-6 mb-3">
                  <label for="street">Rua</label>
                  <input type="text" class="form-control" id="street" placeholder="" value="" required="" v-model="account.street">
                  <div class="invalid-feedback">
                    Rua necessária
                  </div>
                </div>
                <div class="col-md-2 mb-3">
                  <label for="streetNumber">Número</label>
                  <input type="text" class="form-control" id="streetNumber" placeholder="" value="" required="" v-model="account.streetNumber">
                  <div class="invalid-feedback">
                    Número necessário
                  </div>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="zipcode">CEP</label>
                  <the-mask class="form-control" id="zipcode" placeholder="" v-model="account.zipcode" :mask="'#####-###'" :masked='false'/>
                  <div class="invalid-feedback">
                    CEP necessário
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <label for="password">Senha</label>
                  <input type="password" class="form-control" id="password" autocomplete="password" placeholder="" value="" required="" v-model="account.password" >
                </div>
                <div class="col-md-4 mb-3">
                  <label for="confirmPassword">Confirmação de senha</label>
                  <input type="password" class="form-control" id="confirmPassword" autocomplete="password" placeholder="" value="" required="" v-model="account.confirmPassword">
                </div>
              </div>
  
              <div class="row px-3">
                <div class="col-md-4 mb-3" v-if="plan.pix && plan.card">
                  <label for="page-customer-payment-type">Tipo de Pagamento:</label>    
                  <select id="page-customer-payment-type" class="form-control payment-select" v-model="payment.type">
                    <option value="CREDIT_CARD">Cartão</option>
                    <option value="PIX">PIX</option>
                    <option value="SUBSCRIPTION">Assinatura</option>
                  </select>
                </div>

                <div class="col-md-4 mb-3" v-if="payment.type === 'CREDIT_CARD' && plan.card && plan.card.value">
                  <label for="page-customer-installments" >Quantidade de Parcelas:</label>    
                  <select id="page-customer-installments" class="form-control payment-select" v-model="installments">
                    <option v-for="index in 12" :key="index" :value="index">{{index}}x R$ {{ formatMoney(plan.card.value / index, 2) }}</option>
                  </select>
                </div>
              </div>

              <div class="col-12 m-auto">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="termsAndConditions"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  Declaro que li e aceito os <b-link class="text-secondary" v-b-modal.modal-1>termos de uso da plataforma.</b-link class="secondary">
                </b-form-checkbox>
              </div>

              <div class="col-md-4 m-auto p-5">
                <button class="btn btn-secondary btn-lg btn-block text-center" type="submit">Próximo </button>
              </div>
            </form>
          </div>

          <div class="row card" v-if="step === 'payment' && !loading">
            <div class="p-3" v-if="payment.type === 'PIX' && paymentInfo">
              <p class="copy-and-paste" @click="copyToClipboard(paymentInfo.copyAndPaste)">
                <span class="bold">Copia e cola:</span> <span id="copy-and-paste">{{ paymentInfo.copyAndPaste }}</span>
              </p>
              <div class="text-center">
                <img :src="'data:image/png;base64,' + paymentInfo.qrCode" />
              </div>
              <a class="btn btn-secondary" href="/#/login">Ir para Login</a>
            </div>
            <form class="text-black needs-validation p-3" @submit.prevent="register" v-if="payment.type === 'CREDIT_CARD' || payment.type === 'SUBSCRIPTION'">
              <div class="row px-3">
                <div class="col-md-12 mb-3">
                  <label for="card">Nome no Cartão</label>
                  <input type="text" class="form-control" id="card" placeholder="" value="" required="" v-model="payment.name">
                  <div class="invalid-feedback">
                    Valid name is required.
                  </div>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="phone">Número do Cartão</label>
                  <the-mask
                    type="text"
                    class="form-control"
                    id="phone" 
                    placeholder=""
                    value=""
                    required=""
                    v-model="payment.number"
                    :mask="['#### #### #### ####']"
                    :masked="false"
                  />
                  <div class="invalid-feedback">
                    Número do cartão necessário
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <label for="card">Validade (Mês)</label>
                  <input type="text" class="form-control" id="card" placeholder="" value="" required="" v-model="payment.expiryMonth">
                  <div class="invalid-feedback">
                    Valid month is required.
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <label for="card">Validade (Ano)</label>
                  <input type="text" class="form-control" id="card" placeholder="" value="" required="" v-model="payment.expiryYear">
                  <div class="invalid-feedback">
                    Valid year is required.
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <label for="card">CVV</label>
                  <input type="text" class="form-control" id="card" placeholder="" value="" required="" v-model="payment.ccv">
                  <div class="invalid-feedback">
                    CVV is required.
                  </div>
                </div>

                <div class="col-12 m-auto">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="termsAndConditions"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                  >
                    Declaro que li e aceito os <b-link class="text-secondary" v-b-modal.modal-1>termos de uso da plataforma.</b-link class="secondary">
                  </b-form-checkbox>
                </div>

                <div class="col-md-4 m-auto p-5">
                  <button class="btn btn-secondary btn-lg btn-block text-center" type="submit">Finalizar Cadastro </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-md-4 my-5">
          <div class="my-5"></div>

          <div>
            <h5 class="title"><span>Garantia de {{ plan.guarantee }}.</span> <br /> <span>Estorno automático</span></h5>
            <p class="description">Fique 100% confiante ao testar com garantia de dinheiro de volta. É só solicitar dentro do programa no prazo da garantia.</p>
          </div>

          <div>
            <h5 class="title"><span></span>ASSINATURA RECORRENTE. <br /> <span>ALTERE OU CANCELE QUANDO QUISER</span></h5>
            <p class="description">Mude seu plano quando quiser. Sem fidelidade, sem letras miúdas. Risco zero.</p>
          </div>
        </div>
      </div>
      <div>
        <b-modal id="modal-1" title="TERMOS DE USO DOS SERVIÇOS ORIENTA LEGAL LTDA.">
          <TermsAndConditions />
        </b-modal>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from 'components/Footer';
import TermsAndConditions from 'components/TermsAndConditions';
import { mapGetters } from 'vuex';
import { getPlan } from '../services/plans';
import { subscribe } from '../services/accounts';
import { getIPAddress } from '../utils/common';

export default {
  name: 'Register',
  computed: mapGetters(['authstatus', 'authErrorMessage']),
  data() {
    return {
      loading: false,
      stateOptions: [
        { value: null, text: 'Por favor, selecione uma opção' },
        // All Brazil states
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
      account: {
        name: '',
        email: '',
        phoneNumber: '',
        state: '',
        city: '',
        neighborhood: '',
        street: '',
        streetNumber: '',
        zipcode: '',
        password: '',
        confirmPassword: ''
      },
      payment: {
        name: '',
        number: '',
        expiryMonth: '',
        expiryYear: '',
        ccv: '',
        type: 'CREDIT_CARD'
      },
      termsAndConditions: 'not_accepted',
      plan: null,
      installments: 12,
      step: 'data',
      userIP: '',
      paymentInfo: null,
    };
  },
  methods: {
    async getPlanById(planId) {
      const plan = await getPlan(planId);
      this.plan = plan;
    },

    async changeStep(step) {
      const isValid = this.formValidation();

      if (!isValid) return;

      this.step = step;

      if (step === 'payment' && this.payment.type === 'PIX') {
        await this.register();
      }
    },

    formValidation() {
      const { account } = this;

      if (this.termsAndConditions === 'not_accepted') {
        this.$toast.open({
          message: 'Você precisa aceitar os termos de uso',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.name) {
        this.$toast.open({
          message: 'Nome obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.email) {
        this.$toast.open({
          message: 'Email obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.phoneNumber) {
        this.$toast.open({
          message: 'Telefone obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.email) {
        this.$toast.open({
          message: 'Email obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.state) {
        this.$toast.open({
          message: 'Estado obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.city) {
        this.$toast.open({
          message: 'Cidade obrigatória',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.neighborhood) {
        this.$toast.open({
          message: 'Bairro obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.street) {
        this.$toast.open({
          message: 'Rua obrigatória',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!account.streetNumber) {
        this.$toast.open({
          message: 'Número obrigatório',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (account.zipcode?.length < 8) {
        this.$toast.open({
          message: 'CEP inválido',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (!password) {
        this.$toast.open({
          message: 'Senha obrigatória',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      if (account.password !== account.confirmPassword) {
        this.$toast.open({
          message: 'Confirmação de senha inválida.',
          type: 'error',
          position: 'top-right'
        });
        return false;
      }

      return true;
    },

    async register() {
      this.loading = true;

      const { account } = this;

      let planId = null;
      let installments = 1;

      switch (this.payment.type) {
        case 'PIX':
          planId = this.plan.pix.id;
          break;
        case 'CREDIT_CARD':
          planId = this.plan.card.id;
          installments = this.installments;
          break;
        case 'SUBSCRIPTION':
          planId = this.plan.id;
          break;
      }

      const data = {
        "account": account,
        "planId": planId,
        "creditCard": {
          "holderName": this.payment.name,
          "number": this.payment.number,
          "expiryMonth": this.payment.expiryMonth,
          "expiryYear": this.payment.expiryYear,
          "ccv": this.payment.ccv
        },
        "creditCardHolderInfo": {
          "name": account.name,
          "email": account.email,
          "cpfCnpj": account.cpf,
          "postalCode": account.zipcode,
          "addressNumber": account.streetNumber,
          "addressComplement": `${account.neighborhood} ${account.street}`,
          "phone": account.phoneNumber,
          "mobilePhone": ""
        },
        "password": account.password,
        "installmentNumber": installments,
        "IP": this.userIP
      };

      await this.$store.dispatch('REGISTER', data).then((response) => {
        this.paymentInfo = response.subscription;

        this.$store.dispatch('LOGOUT').then(() => {
        }).catch((e) => {
          console.info('Logout Error');
          console.error(e);
        });

        if (this.payment.type === "CREDIT_CARD" || this.payment.type === "SUBSCRIPTION") {
          this.$toast.open({
            message: 'Cadastrado com sucesso.',
            type: 'success',
            position: 'top-right'
          });
          this.$router.push('/login');
        }
      }).catch( (err) => {
        console.info('reg err', err.response);
        
        let errorMessage = 'Erro ao se cadastrar. Por favor, verifique seus dados e tente novamente.';

        const error = err?.response?.data?.err || '';

        if (error.includes('O CPF/CNPJ informado é inválido.')) {
          errorMessage = 'Erro ao se cadastrar. O CPF/CNPJ informado é inválido.';
        }

        if (error.includes('EmailAlreadyExistsError')) {
          errorMessage = 'Email já cadastrado. Entre em contato para mais informações.';
        }

        if (error.includes('InvalidEmailError')) {
          errorMessage = 'Erro ao se cadastrar. O email informado é inválido.';
        }

        if (error.includes('UniqueViolationError user_email_unique')) {
          errorMessage = 'Erro ao se cadastrar. Email já cadastrado.';
        }
        
        this.step = 'data';
        this.$toast.open({
          message: errorMessage,
          type: 'error',
          position: 'top-right'
        });
      });
      
      this.loading = false;
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.$toast.open({
        message: 'Código copia e cola copiado.',
        type: 'info',
        position: 'bottom-right',
      });
    },

    formatMoney(value, digits) {
      const d = digits !== undefined ? digits : 2;
      const nf = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: d,
        maximumFractionDigits: d,
      });
      return nf.format(value);
    },
  },
  async created() {
    this.plan = this.$route.params.plan;

    this.userIP = await getIPAddress();

    if (this.$route.query.planId) {
      try {
        this.plan = await getPlan(this.$route.query.planId, { allOptions: true });
      } catch (err) {}
    }

    if (!this.plan) {
      this.$router.push({ name: 'plans' });
    }

    if (this.plan.period === 'monthly') this.payment.type = 'SUBSCRIPTION';
  },
  components: {
    Footer,
    TermsAndConditions,
  }
}
</script>

<style>
@media screen and (max-width: 768px) {
  .mobile-class {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}
.copy-and-paste {
  color: black;
  cursor: pointer;
}
.single-price {
  text-align: center;
  background: #ed7b02;
  transition: .7s;
  margin-top: 20px;
}
.single-price h3 {
  font-size: 25px;
  color: #000;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-top: -80px;
  font-family: poppins;
  color: #fff;
}
.single-price h4 {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
}
.single-price h4 span.sup {
  vertical-align: text-top;
  font-size: 25px;
}
.deal-top {
  position: relative;
  background: #262626 !important;
  font-size: 16px;
  text-transform: uppercase;
  padding: 136px 24px 0;
}
.deal-top::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50px;
  width: 0;
  height: 0;
  border-top: 50px solid #262626;
  border-left: 175px solid transparent;
  border-right: 170px solid transparent;
}
.deal-bottom {
  padding: 56px 16px 0;
}
.deal-bottom ul {
  margin: 0;
  padding: 0;
}
.deal-bottom  ul li {
  font-size: 16px;
  color: black;
  font-weight: 300;
  margin-top: 16px;
  border-top: 1px solid #E4E4E4;
  padding-top: 16px;
  list-style: none;
}
.btn-area a {
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background: #ed7b02;
  padding: 8px 64px;
  margin-top: 32px;
  border-radius: 4px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
}

.text-black {
  color: black;
}


.single-price:hover {
  background: #ed7b02;
}
.single-price:hover .deal-top {
  background: #262626;
}
.single-price:hover .deal-top:after {
  border-top: 50px solid #262626;
}
.single-price:hover .btn-area a {
  background: #262626;
}

/* ignore the code below */
.link-area
{
  position:fixed;
  bottom:20px;
  left:20px;
  padding:15px;
  border-radius:40px;
  background:tomato;
}
.link-area a
{
  text-decoration:none;
  color:#fff;
  font-size:25px;
}

.title {
  border-left: 7px solid #ffc04c;
  padding-left: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
  line-height: 1.4rem;
}

.description {
  margin-left: 1rem;
    color: #ffec7d;
    text-align: left;
    font-weight: 200;
    line-height: 1.25rem;
    margin-bottom: 20px;
}

.plan-title {
    margin-left: 5px;
    color: #ffc04c;
}

.price-text-1 {
    line-height: normal;
    font-size: .75rem;
    text-align: left;
}

.price-text-2 {
    line-height: 40px;
    font-size: 30px;
    text-align: left;
}

.text-black {
  color: #000;
}

.form-heading {
  box-sizing: border-box;
    margin: 10px;
    flex: 3.5 0 0%;
    font-weight: bolder;
    line-height: 24px;
    font-size: 17px;
    text-transform: uppercase;
    color: #333;
}

.payment-select {
    /* background-repeat: no-repeat; */
    /* background-position: right .75rem top .7rem; */
    /* background-size: 14px 8px; */
    padding: 8px 16px 8px 8px;
    /* -webkit-appearance: none; */
    /* -moz-appearance: none; */
    /* appearance: none; */
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5%;
}
</style>
