<template>
  <div class="p-3" v-if="!loading">
    <div class="border rounded border-info border-left-4 mb-3" style="background-color: #FAFB7F; border: 1px solid black !important;">
      <div class="p-2 border-bottom d-flex align-items-center">
        <div>
          <h6 class="mb-2 text-gray-600 font-weight-bold">
            Para adicionar um cabeçalho ou rodapé para o relatório, configure no perfil do usuário clicando <a :href="`/#/users/${sessionUser.id}/edit`">aqui</a>.
          </h6>
        </div>
      </div>
    </div>

    <button class="btn btn-primary" @click="exportToPDF">Exportar PDF</button>
    <b-spinner class="align-middle ml-2" v-if="generatingPDF"></b-spinner><strong v-if="generatingPDF"> Gerando PDF...</strong>

    <div id="genial-report">
      <!-- <div class="page-header">
        <p class="text-center">LOGOTIPO</p>
      </div> -->

      <div class="m-3">
        <div class="header">
          <h1 class="title">Parecer Técnico</h1>
          <h2 class="subtitle">Possibilidades de Aposentadoria</h2>
        </div>
        
        <div class="no-break">
          <p class="bold">Sra. {{ customerName }}</p>
          <p class="bold">Data de nascimento: {{ formatDate(customerBirthDate, 'DD/MM/YYYY') }}</p>
          <p><span class="bold">Idade na data do parecer:</span> {{ ageAtCalculationDate }}</p>
          <p><span class="bold">Idade na data da Reforma da Previdência:</span> {{ ageAtRegime }}</p>
          <p>Ingresso no serviço público EM CARGO EFETIVO em <span class="bold">{{ formatDate(calculationPublicServiceIngressDate, 'DD/MM/YYYY') }}</span> até presente.</p>
        </div>
    
        <div>
          <h3 class="topic-title">I. Vínculos considerados</h3>
    
          <WorkRecordItem
            v-for="record in workRecords"
            v-bind:key="record.id"
            v-bind:item="record"
            v-on:addRemuneration="() => {}"
            v-on:edit="() => {}"
            v-on:remove="() => {}"
            v-on:addition="() => {}"
            v-on:deduction="() => {}"
          />

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field1" />
        </div>
    
        <div>
          <h3 class="topic-title">II. Análise do Tempo de Contribuição</h3>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo de Contribuição Comum</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Vínculo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentContributionTimeWithoutSpecialAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentContributionTimeWithoutSpecialAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Outros vínculos</td>
                    <td class="text-nowrap col-4 text-center">{{ othersContributionTimeWithoutSpecialAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ othersContributionTimeWithoutSpecialAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center bold">Tempo total</td>
                    <td class="text-nowrap col-4 text-center">{{ totalContributionTimeWithoutSpecialAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ totalContributionTimeWithoutSpecialAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
          </div>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo Especial</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Vínculo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentSpecialTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentSpecialTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Outros vínculos</td>
                    <td class="text-nowrap col-4 text-center">{{ othersSpecialTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ othersSpecialTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center bold">Tempo total</td>
                    <td class="text-nowrap col-4 text-center">{{ totalSpecialTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ totalSpecialTimeAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        </div>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo de Contribuição com Conversão</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Vínculo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentContributionTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentContributionTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Outros vínculos</td>
                    <td class="text-nowrap col-4 text-center">{{ othersContributionTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ othersContributionTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center bold">Tempo total</td>
                    <td class="text-nowrap col-4 text-center">{{ totalContributionTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ totalContributionTimeAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        </div>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo de Magistério</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Vínculo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentTeachingTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentTeachingTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Outros vínculos</td>
                    <td class="text-nowrap col-4 text-center">{{ othersTeachingTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ othersTeachingTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center bold">Tempo total</td>
                    <td class="text-nowrap col-4 text-center">{{ totalTeachingTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ totalTeachingTimeAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        </div>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo de Serviço Público</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Vínculo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentPublicServiceTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentPublicServiceTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Outros vínculos</td>
                    <td class="text-nowrap col-4 text-center">{{ othersPublicServiceTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ othersPublicServiceTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center bold">Tempo total</td>
                    <td class="text-nowrap col-4 text-center">{{ totalPublicServiceTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ totalPublicServiceTimeAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        </div>
    
          <div class="no-break">
            <table class="table my-2">
            <thead>
              <tr>
                <th class="text-nowrap table-title">Tempo no Cargo e Carreira</th>
              </tr>
            </thead>
            <tbody>
              <table class="table table-bordered">
                <thead>
                  <tr class="flex">
                    <th class="text-nowrap col-4 text-center"></th>
                    <th class="text-nowrap col-4 text-center">Até a data da reforma</th>
                    <th class="text-nowrap col-4 text-center">Até a data do parecer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Tempo no cargo atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentPositionTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentPositionTimeAtCalculationDate }}</td>
                  </tr>
                  <tr class="flex">
                    <td class="text-nowrap col-4 text-center">Tempo na carreira atual</td>
                    <td class="text-nowrap col-4 text-center">{{ currentCarreerTimeAtRegime }}</td>
                    <td class="text-nowrap col-4 text-center">{{ currentCarreerTimeAtCalculationDate }}</td>
                  </tr>
                </tbody>
              </table>
            </tbody>
          </table>
        </div>

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field2" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">III. Emissão de CTC de outros regimes</h3>
    
          <p class="description">Havendo tempo de contribuição em outros regimes de previdência que não o atual, deverá ser emitida e averbada Certidão de Tempo de Contribuição (CTC).</p>
        
          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field3" />
        </div>
    
        <div>
          <h3 class="topic-title">IV. Possibilidades imediatas de aposentadoria</h3>
    
          <rule v-for="rule in applicableRules" :rule="rule" :key="rule.id" />
    
          <p v-if="!applicableRules.length" class="description">
            Não há regras aplicáveis.
          </p>

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field4" />
        </div>
    
        <div>
          <h3 class="topic-title">V. Possibilidades futuras de aposentadoria</h3>
    
          <rule
            v-for="rule in futureRules"
            :rule="rule"
            :key="rule.id"
            isFuture
          />
    
          <p v-if="!futureRules.length" class="description">
            Não há regras futuras.
          </p>

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field5" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">VI. Abono de permanência</h3>
    
          <p class="description">O abono de permanência é o creditamento do total ou parcial da contribuição previdenciária ao servidor público que atinge as regras para aposentadoria, mas permanece em atividade.</p>
          <p class="description">Portanto, faz jus ao abono de permanência desde XXX, quando implementou os requisitos para se aposentar.</p>

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field6" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">VII. Licenças prêmio não usufruídas</h3>
    
          <p class="description">O pedido de conversão da licença prêmio não usufruída em pecúnia deve ser feito em até 5 anos após a concessão de aposentadoria. </p>
        
          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field7" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">VIII. Isenção de imposto de renda</h3>
    
          <p class="description">Aos portadores de doenças graves, é garantido o direito à isenção de imposto de renda e contribuição previdenciárias retidos diretamente na fonte dos benefícios de aposentadoria e pensão por morte.</p>
          <p class="description">O entendimento atual é de que uma vez que o indivíduo comprove que já esteve acometido da doença grave, nasceria para ele o direito à referida isenção, não sendo necessário comprovar que os sintomas da doença são contemporâneos.</p>
          <p class="description">As doenças que garantem a isenção são as seguintes:</p>
        
          <ul class="description-list">
            <li>AIDS (Síndrome da Imunodeficiência Adquirida)</li>
            <li>Alienação Mental</li>
            <li>Cardiopatia Grave</li>
            <li>Cegueira (inclusive monocular)</li>
            <li>Contaminação por Radiação</li>
            <li>Doença de Paget em estados avançados (Osteíte Deformante)</li>
            <li>Doença de Parkinson</li>
            <li>Esclerose Múltipla</li>
            <li>Espondiloartrose Anquilosante</li>
            <li>Fibrose Cística (Mucoviscidose)</li>
            <li>Hanseníase</li>
            <li>Nefropatia Grave</li>
            <li>Hepatopatia Grave</li>
            <li>Neoplasia Maligna</li>
            <li>Paralisia Irreversível e Incapacitante</li>
            <li>Tuberculose Ativa</li>
          </ul>

          <textarea class="textbox mb-2" placeholder="Notas adicionais..." v-model="extras.field8" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">Observações finais</h3>
    
          <p class="description">Lembre-se que as projeções são feitas sempre levando em consideração as regras vigentes na data do parecer.</p>
          <p class="description">Alterações legislativas e jurisprudenciais podem alterar o resultado de nossas simulações.</p>

          <textarea class="textbox mb-2" placeholder="Observações finais..." v-model="extras.observation" />
        </div>
    
        <div class="no-break">
          <h3 class="topic-title">Plano de ação</h3>

          <textarea class="textbox mb-2" placeholder="Plano de ação..." v-model="extras.action" />
        </div>
    
        <div class="no-break">
          <p class="description">Agradecemos a confiança! </p>
          <p class="description">Um abraço! </p>
    
          <p class="footer"><input type="text" v-model="lawyerName" placeholder="Nome do Advogado" /></p>
          <p class="footer">OAB/<input type="text" v-model="oabValue" placeholder="XX n. XXXXX" /></p>
        </div>
      </div>

      <!-- <div class="page-footer text-center">
        <p>RODAPÉ</p>
      </div> -->
    </div>

    <button class="btn btn-primary" @click="exportToPDF">Exportar PDF</button>
    <b-spinner class="align-middle ml-2" v-if="generatingPDF"></b-spinner><strong v-if="generatingPDF"> Gerando PDF...</strong>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import WorkRecordItem from './work-records/components/WorkRecordItem.vue';
import Rule from '@/components/Rule.vue';
import { getApplicableRules, getFutureRules } from '@/services/customers';
import { getTime } from '../../utils/common';
import axios from 'axios';

export default {
  name: 'GenialReport',
  components: { Rule, WorkRecordItem },

  mounted() {
    this.loading = true;

    this.getApplicableRules();
    this.getFutureRules();
    this.getWorkRecords();
  },

  data() {
    return {
      applicableRules: [],
      futureRules: [],
      customer: null,
      calculation: null,
      loading: false,
      totalLoading: 2,
      currentLoading: 0,
      extras: {
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
        field6: '',
        field7: '',
        field8: '',
        observation: '',
        action: ''
      },
      lawyerName: '',
      oabValue: '',
      generatingPDF: false,
      regimeDate: '',
    }
  },

  computed: {
    ...mapGetters('workRecords', ['workRecords']),
    ...mapGetters(['sessionUser']),

    customerGender() {
      return this.customer ? this.customer.gender : '';
    },

    customerName() {
      return this.customer ? this.customer.name : ''; 
    },

    customerBirthDate() {
      return this.customer ? this.customer.birthDate : '';
    },

    calculationPublicServiceIngressDate() {
      return this.calculation ? this.calculation.publicServiceIngressDate : '';
    },

    calculationDate() {
      return this.calculation ? moment(this.calculation.calculationDate) : moment();
    },

    ageAtCalculationDate() {
      return moment(this.calculationDate).diff(moment(this.customerBirthDate), 'years');
    },

    ageAtRegime() {
      return moment(this.regimeDate).diff(moment(this.customerBirthDate), 'years');
    },

    currentWorkRecords() {
      return this.workRecords.filter(record => record.isCurrentPosition || record.isCurrentCareer);
    },
    
    othersWorkRecords() {
      return this.workRecords.filter(record => !(record.isCurrentPosition || record.isCurrentCareer));
    },


    currentContributionTimeWithoutSpecialAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },

    currentContributionTimeWithoutSpecialAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },

    othersContributionTimeWithoutSpecialAtRegime() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.regimeDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },

    othersContributionTimeWithoutSpecialAtCalculationDate() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.calculationDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },

    totalContributionTimeWithoutSpecialAtRegime() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.regimeDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },

    totalContributionTimeWithoutSpecialAtCalculationDate() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.calculationDate, null, this.customerGender, this.regimeDate, true);
      
      return this.formatContributionText(time);
    },


    currentSpecialTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentSpecialTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersSpecialTimeAtRegime() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.regimeDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersSpecialTimeAtCalculationDate() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.calculationDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalSpecialTimeAtRegime() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.regimeDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalSpecialTimeAtCalculationDate() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.calculationDate, 'isSpecialTime', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },


    currentContributionTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentContributionTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersContributionTimeAtRegime() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.regimeDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersContributionTimeAtCalculationDate() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.calculationDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalContributionTimeAtRegime() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.regimeDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalContributionTimeAtCalculationDate() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.calculationDate, null, this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },


    currentTeachingTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentTeachingTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersTeachingTimeAtRegime() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.regimeDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersTeachingTimeAtCalculationDate() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.calculationDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalTeachingTimeAtRegime() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.regimeDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalTeachingTimeAtCalculationDate() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.calculationDate, 'isTeaching', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },


    currentPublicServiceTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentPublicServiceTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersPublicServiceTimeAtRegime() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.regimeDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    othersPublicServiceTimeAtCalculationDate() {
      const { othersWorkRecords } = this;

      if (!othersWorkRecords || othersWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(othersWorkRecords, this.calculationDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalPublicServiceTimeAtRegime() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.regimeDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    totalPublicServiceTimeAtCalculationDate() {
      const { workRecords } = this;

      if (!workRecords || workRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(workRecords, this.calculationDate, 'isPublicService', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },


    currentPositionTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, 'isCurrentPosition', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentPositionTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, 'isCurrentPosition', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },


    currentCarreerTimeAtRegime() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.regimeDate, 'isCurrentCareer', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },

    currentCarreerTimeAtCalculationDate() {
      const { currentWorkRecords } = this;

      if (!currentWorkRecords || currentWorkRecords.length === 0) {
        this.formatContributionText({ years: 0, months: 0, days: 0 });
      }

      const time = getTime(currentWorkRecords, this.calculationDate, 'isCurrentCareer', this.customerGender, this.regimeDate);
      
      return this.formatContributionText(time);
    },
  },

  methods: {
    ...mapActions('workRecords', ['loadWorkRecords']),

    async getWorkRecords() {
      await this.loadWorkRecords(this.$route.params.calculationId);

      this.finishLoading();
    },

    async getApplicableRules() {
      this.loading = true;

      const { rules, calculation, customer, regimeDate } = await getApplicableRules(this.$route.params.calculationId);
      this.applicableRules = rules;
      this.customer = customer;
      this.calculation = calculation;
      this.regimeDate = regimeDate;

      this.finishLoading();
    },

    async getFutureRules() {
      this.loading = true;

      const { rules, customer, calculation, regimeDate } = await getFutureRules(this.$route.params.calculationId);
      this.futureRules = rules;
      this.customer = customer;
      this.calculation = calculation;
      this.regimeDate = regimeDate;

      this.finishLoading();
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },

    finishLoading() {
      this.currentLoading++;

      if (this.currentLoading >= this.totalLoading) {
        this.loading = false;
      }
    },

    formatContributionText({ days, months, years }) {
      if (!years && !months && !days) return '---';

      let text = '';

      if (years > 0) {
        text += `${years} ano`;

        if (years > 1) {
          text += 's';
        }
      }

      if (months > 0) {
        if (years > 0) {
          if (days > 0) {
            text += ', ';
          } else {
            text += ' e ';
          }
        }

        if (months > 1) {
          text += `${months} meses`;
        } else {
          text += `${months} mês`;
        }
      }

      if (days > 0) {
        if (years > 0 || months > 0) {
          text += ' e ';
        }

        text += `${days} dia`;

        if (days > 1) {
          text += 's';
        }
      }

      text += '.';

      return text;
    },

    async printReport() {
      const div = document.getElementById('genial-report');

      let style = '';

      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        style += node.outerHTML;
      }

      const win = window.open();

      win.document.write(`<style>${style}</style>`);
      win.document.write(div.innerHTML);

      win.document.close();
      win.focus();
      win.print();
      win.close();
    },

    async exportToPDF() {
      this.generatingPDF = true;

      try {
        const params = {
          calculationId: this.$route.params.calculationId,
          field1: this.extras.field1,
          field2: this.extras.field2,
          field3: this.extras.field3,
          field4: this.extras.field4,
          field5: this.extras.field5,
          field6: this.extras.field6,
          field7: this.extras.field7,
          field8: this.extras.field8,
          observation: this.extras.observation,
          action: this.extras.action,
          lawyerName: this.lawyerName, 
          oabValue: this.oabValue
        };

        const response = await axios.get('/reports/genial-report', { params });

        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `Relatório Genial.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (err) {
        console.log("err", err);
      }

      this.generatingPDF = false;
    }
  }
}
</script>

<style scoped>
  @media print 
  {
    @page {
      size: A4;
      margin: 0 0;
    }
    html, body {
      width: 210mm;
      height: 282mm;
      font-size: 11px;
      background: #FFF;
      overflow: visible;
    }
    body {
      padding: 25mm 0;
      print-color-adjust: exact; 
    }
    .page-header {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .page-footer {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .no-break { break-inside: avoid; } 

  .title {
    border-bottom: 1px solid #4bacc6;
    color: #4bacc6;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .subtitle {
    color: #4bacc6;
    font-size: 16px;
    font-style: italic;
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .topic-title {
    background-color: #dbe5f1;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    padding: 32px;
    margin-bottom: 16px;
  }

  .table-title {
    background-color: #dbe5f1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
  }

  .description {
    color: black;
    text-indent: 32px;
  }

  .description-list {
    padding-inline-start: 64px;
  }

  .footer {
    text-align: center;
    font-weight: bold;
    margin: 0;
  }

  .textbox {
    width: 100%;
    height: 128px;
  }
</style>
