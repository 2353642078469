<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0" v-if="record">Editar registro #{{record.id}}</h5>
        <h5 class="mb-0" v-else>Criar registro de trabalho</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            label="Empresa"
            label-for="company-name"
          >
            <b-form-input
              id="company-name"
              v-model="form.companyName"
              placeholder="Informe um nome"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="Estado"
            label-for="state-name"
          >
            <b-form-input
              id="state-name"
              v-model="form.state"
              placeholder="Informe o estado"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="Cidade"
            label-for="city-name"
          >
            <b-form-input
              id="city-name"
              v-model="form.city"
              placeholder="Informe a cidade"
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="Data de ingresso"
            label-for="start-date"
          >
            <InputDatePicker v-model="form.ingressDate" />
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group
            label="Data de Encerramento"
            label-for="end-date"
          >
            <InputDatePicker v-model="form.egressDate" />
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group>
            <b-form-checkbox v-model="form.isPublicService" switch inline class="my-2">
              Serviço público
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group>
            <b-form-checkbox v-model="form.isTeaching" switch inline class="my-2">
              Magistério
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group>
            <b-form-checkbox v-model="form.isCurrentCareer" switch inline class="my-2">
              Carreira Atual
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group>
            <b-form-checkbox v-model="form.isCurrentPosition" switch inline class="my-2">
              Cargo Atual
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group>
            <b-form-checkbox v-model="form.isSpecialTime" switch inline class="my-2">
              Tempo Especial
            </b-form-checkbox>
          </b-form-group>
        </div>

        <div class="col-md-8">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="form.isDisabled" switch inline class="my-2">
                Com Deficiência
              </b-form-checkbox>

              <div v-if="form.isDisabled">
                <b-form-select 
                  v-model="form.disabledLevel" 
                  :options="[{value: null, text: 'Selecione Gravidade', disabled: true }, { value: 'low', text: 'Leve' }, { value: 'medium', text: 'Moderada' }, { value: 'high', text: 'Grave' }]" 
                  size="sm" 
                  class="mt-3"
                  required
                ></b-form-select>
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- <div class="col-md-8">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="form.expiredVacations" switch inline class="my-2">
                Férias vencidas no período
              </b-form-checkbox>

              <div v-if="form.expiredVacation">
                <b-form-input
                  v-model="form.expiredVacations"
                  type="number"
                  placeholder="Informe a quantidade"
                  required
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div> -->

        <!-- <div class="col-md-8">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-checkbox v-model="form.hasExpiredPremiumLicenses" switch inline class="my-2">
                Licença prêmio vencidas
              </b-form-checkbox>

              <div v-if="form.premiumLicense">
                <b-form-input
                  v-model="form.expiredPremiumLicenses"
                  type="number"
                  placeholder="Informe a quantidade"
                  required
                ></b-form-input>
              </div>
            </div>
          </b-form-group>
        </div> -->
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="save"
        :disabled="isSaving"
      >
        Salvar
      </b-button>

      <b-button
        variant="outline-primary"
        @click="cancel()"
      >
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from 'axios';
import InputDatePicker from '@/components/InputDatePicker.vue';
import moment from 'moment';

export default {
  name: 'ModalWorkRecordsForm',

  components: {
    InputDatePicker,
  },

  props: {
    record: {
      type: Object,
    },
    parentId: {
      type: Number,
    },
  },

  data: () => ({
    isLoading: false,
    isSaving: false,
    modalShow: true,
    form: {
      companyName: '',
      state: '',
      city: '',
      ingressDate: '',
      egressDate: '',
      isPublicService: false,
      isTeaching: false,
      expiredVacations: false,
      expiredVacations: 0,
      hasExpiredPremiumLicenses: false,
      expiredPremiumLicenses: 0,
      isCurrentPosition: false,
      isCurrentCareer: false,
      isSpecialTime: false,
      isDisabled: false,
      disabledLevel: null,
    },
  }),

  created() {
    if (this.record) {
      this.form = {
        ...this.record,
        ingressDate: this.record.ingressDate ? moment(this.record.ingressDate).format('DD/MM/YYYY') : '',
        egressDate: this.record.egressDate ? moment(this.record.egressDate).format('DD/MM/YYYY') : '',
      };
    }
  },

  methods: {
    async save() {
      this.isLoading = true;
      this.isSaving = true;

      try {
        await this.saveOrUpdate();

        this.$toast.open({
          message: 'Registro salvo com sucesso',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('save');
        this.$emit('hidden');
      } catch (error) {
        this.$toast.open({
          message: 'Erro ao salvar',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.isSaving = false;
        this.isLoading = false;
      }
    },

    saveOrUpdate() {
      const { form } = this;
      const body = { workRecord: form };

      if (!form.isDisabled) form.disabledLevel = null;

      form.calculationId = this.parentId;

      if (form.id) {
        return axios.put(`/work-records/${form.id}`, body);
      } else {
        return axios.post('/work-records', body);
      }
    },
  }
}
</script>

<style scoped>
</style>
