<template>
  <div id="app">
    <NavBar v-if="isAuthenticated"/>
    <PageHeader />
    <router-view/>
  </div>
</template>

<script>
import NavBar from 'components/NavBar.vue';
import PageHeader from 'components/PageHeader.vue';

export default {
  name: 'app',
  components: {
    NavBar,
    PageHeader,
  },
  computed: {
    isAuthenticated () { return this.$store.getters.isAuthenticated }
  },
  created: function() {
    if (this.$store.getters.isAuthenticated) {
      console.info('Usuário autenticado');
      this.$store.dispatch('SESSION_RESTORE');
    } else {
      console.info('Usuário não autenticado');
    }
  }
}
</script>

<style lang="scss">
</style>
