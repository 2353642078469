<template>
  <div class="calculation-list-wrapper p-3">
    <button class="bg-transparent w-100 text-center border border-dashed p-0 mb-3 text-gray-600" v-on:click="showCalculationForm = true">
      <i class="fa fa-plus mr-2" aria-hidden="true"></i> Inserir cálculo
    </button>

    <CalculationItem v-for="record in calculations" v-bind:key="record.id" v-bind:item="record"
      v-on:addRemuneration="handlerAddRemuneration" v-on:edit="handlerEditCalculation"
      v-on:remove="handlerRemoveCalculation" />

    <ModalCalculationForm v-if="showCalculationForm" v-bind:parentId="parentId" v-bind:record="recordSelected"
      v-on:hidden="handlerCloseModal" v-on:save="fetchCalculations" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CalculationItem from './components/CalculationItem';
import ModalCalculationForm from './components/ModalCalculationForm';
// import ModalRemunerationForm from './components/ModalRemunerationForm';
// import { getUpdateIndex } from '@/services/updateIndex';

export default {
  name: 'Calculations',
  components: {
    CalculationItem,
    ModalCalculationForm,
  },

  data: () => ({
    showCalculationForm: false,
    // recordSelected: null,
  }),

  created() {
    this.loadCalculations(this.$route.params.id)
    // this.fetchUpdateIndex();
  },

  computed: {
    ...mapGetters('calculations', ['calculations']),
    parentId() {
      return this.$route.params.id;
    },
  },

  methods: {
    ...mapActions('calculations', ['loadCalculations']),

    fetchCalculations() {
      this.loadCalculations(this.$route.params.id);
    },

    fetchUpdateIndex() {
      getUpdateIndex()
        .then((response) => {
          this.updateIndex = response.updateIndex;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handlerAddRemuneration(record) {
      this.recordSelected = record;
      this.showRemunerationFormModal = true;
    },

    handlerEditCalculation(record) {
      this.recordSelected = record;
      this.showCalculationForm = true;
    },

    async handlerRemoveCalculation(record) {
      const result = await this.$swal.fire({
        title: 'Tem certeza que deseja remover esse registro?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, pode remover!'
      });

      if (!result.isConfirmed) { return; }

      try {
        await axios.delete(`/calculation-list/${record.id}`);

        this.$swal.fire(
          'Pronto!',
          `Removido com sucesso!`,
          'success'
        )
        await this.fetchCalculations();
      } catch (error) {
        this.$swal.fire(
          'Ops!',
          `Não foi possível remover, tente novamente mais tarde!`,
          'error'
        )
      }
    },

    handlerCloseModal() {
      this.showCalculationForm = false;
      this.recordSelected = null;
    },
  },
}
</script>

<style scoped></style>
