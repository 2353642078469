import store from "store";

const isNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/home');
};

export default isNotAuthenticated;
