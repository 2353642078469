<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    size="lg"
  >
    <template #modal-header="{ close }">
      <div class="d-flex align-items-center flex-fill">
        <h5 class="mb-0">Cancelamento de assinatura</h5>

        <b-button class="ml-auto" size="sm" variant="link" @click="close()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </b-button>
      </div>
    </template>

    <template #default>
      <div class="row">
        <h5 class="p-3">Plano {{ plan.id ? plan.name : 'não encontrado' }} {{ expirationDate ? `- Vigente até ${expirationDate}` : '' }}</h5>

        <div class="col-12 p-3">
          <label for="reason">Motivo do cancelamento:</label>
          <textarea class="textbox mb-2" id="reason" placeholder="Motivo do cancelamento" v-model="form.reason" />
        </div>

        <div class="col-12 px-3">
          <b-form-group label="Gostaria que alguém do nosso time comercial entre em contato para negociar sua permanência?" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="form.support" :aria-describedby="ariaDescribedby" name="support" :value="true">SIM</b-form-radio>
            <b-form-radio v-model="form.support" :aria-describedby="ariaDescribedby" name="support" :value="false">Não, tenho certeza sobre o cancelamento</b-form-radio>
          </b-form-group>
        </div>

        <div class="col-12 px-3" v-if="allowRefund">
          Após cancelamento, o valor pago será reembolsado.
        </div>
      </div>
    </template>

    <template #modal-footer="{ cancel }">
      <b-button
        variant="primary"
        @click="submit"
      >
        Confirmar cancelamento
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { cancelSubscription } from '@/services/subscriptions';
import { mapGetters } from 'vuex';
import { cancelSubscriptionADM, reverseCharge } from '../services/subscriptions';

export default {
  name: 'ModalCancelSubscription',

  components: {

  },

  props: {
    plan: {
      type: Object,
    },
    userId: {
      type: String,
    },
    expirationDate: {
      type: String
    },
    allowRefund: {
      type: Boolean
    }
  },

  data: () => ({
    isLoading: false,
    modalShow: true,
    form: {
      reason: '',
      support: true
    },
  }),

  computed: {
    ...mapGetters(['isManagement']),
  },

  created() {

  },

  methods: {
    async submit() {
      this.isLoading = true;

      try {
        // if (this.allowRefund) {
        //   await reverseCharge();
        // }

        if (this.isManagement) {
          await cancelSubscriptionADM({ ...this.form, userId: this.userId });
        } else {
          await cancelSubscription(this.form);
        }

        this.$toast.open({
          message: 'Assinatura cancelada com sucesso.',
          type: 'success',
          position: 'top-right'
        });

        this.$emit('hidden');
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: 'Erro ao cancelar assinatura.',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.isLoading = false;
      }
    },  
  }
}
</script>

<style scoped>
.textbox {
  width: 100%;
}
</style>
