import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueMoment from 'vue-moment';
import VueIntro from 'vue-introjs';
import VueToast from 'vue-toast-notification';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTheMask from 'vue-the-mask'
import VueGtag from 'vue-gtag';
import money from 'v-money';
import Autocomplete from '@trevoreyre/autocomplete-vue';

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
import './app.scss'
import 'intro.js/introjs.css';
import 'intro.js/themes/introjs-modern.css'
import 'vue-toast-notification/dist/theme-sugar.css';
// import '@trevoreyre/autocomplete-vue/dist/style.css';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const moment = require('moment')
require('moment/locale/pt-br');

Vue.use(VueSweetalert2);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueMoment, { moment });
Vue.use(VueIntro);
Vue.use(VueToast);
Vue.use(VueTheMask);
Vue.use(money, { precision: 4 })
Vue.use(Autocomplete);

Vue.use(VueGtag, {
  config: { id: 'G-X6CVZJY0QN' }
}, router);

Vue.config.productionTip = false

// axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'https://genial-prev.herokuapp.com/api' : '/api';
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/api' : '/api';

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    store.dispatch('LOGOUT');
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
