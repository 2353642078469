import axios from 'axios';

export async function getRegimes(query = {}) {
  const response = await axios.get('/regimes', { params: query });
  return response.data;
}

export async function getRegime(query = {}) {
  const response = await axios.get('/regimes/date', { params: query });
  return response.data;
}
