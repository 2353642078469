import axios from 'axios';

export async function listPayments() {
  const response = await axios.get('/payments');
  return response.data;
}

export async function getPayment(paymentId) {
  const response = await axios.get(`/subscription/paymentInfo/${paymentId}`);
  return response.data;
}
