import Vue from 'vue';
import Vuex from 'vuex';
import users from './modules/users';
import activities from './modules/activities';
import calculations from './modules/calculations';
import workRecords from './modules/work-records';
import auth from './modules/auth';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    activities,
    users,
    auth,
    calculations,
    workRecords,
  },
  strict: debug,
});
